import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDataApi from '../../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert';
import { Container, Row } from 'reactstrap';
import CustomerToDoListContainer from './CustomerToDoListContainer';
import CustomerShieldsStatusChart from './CustomerShieldsStatusChart';
import CustomerMonitoringAlertsChart from './CustomerMonitoringAlertsChart';
import CustomerSpeedDial from './CustomerSpeedDial';
import { dashboardFilters } from '../../../configs/Configs';

const CustomerDashboardPage = () => {
	const { t } = useTranslation();
	const computeProgress = (response) => {
		var now = new Date();
		var nDone = response.filter(shield => (shield.regStatus === 'active' || shield.regStatus === 'approved')).length;
		var nTodos = response.filter(shield => ((shield.regStatus === 'N/A' || shield.regStatus === 'pending') && (shield.snooze === '' || now.getTime() > new Date(shield.snooze).getTime()))).length;
		return Math.trunc(nDone / (nDone + nTodos) * 100);
	};
	const [requestState, sendRequest] = useDataApi();
	const [users, setUsers] = useState(null);
	const [devices, setDevices] = useState(null);
	const [roles, setRoles] = useState(null);
	const [deviceGroups, setDeviceGroups] = useState(null);
	const [policies, setPolicies] = useState(null);
	const [firstCall, setFirstCall] = useState(true);
	const [systemVersion, setSystemVersion] = useState(null);
	
	const getSystemVersion = () => {
		sendRequest('GET', '/versions-api/versions/system', {}, (result) => {
			setSystemVersion(result);
		})
	}

	const getAll = () => {			
		sendRequest('GET', '/customers-api/users', {isRefresh: !firstCall}, (result) => {
			setUsers(result);
		})
		sendRequest('GET', '/customers-api/shields', {isRefresh: true}, (result) => {
			setDevices(result);
		})
		sendRequest('GET', '/customers-api/roles', {isRefresh: true}, (result) => {
			setRoles(result);
		})
		sendRequest('GET', '/customers-api/devicegroups', {isRefresh: true}, (result) => {
			setDeviceGroups(result);
		})
		sendRequest('GET', '/customers-api/policies', {isRefresh: true}, (result) => {
			setPolicies(result);
		})
	}

	useEffect(() => {
		getSystemVersion();
		getAll();
		setFirstCall(false);
		const intervalId = setInterval(() => {			
			getAll();
		}, 15000);
		return (() => {
			clearInterval(intervalId);
		});
	}, [firstCall])
	
	const preparePoliciesData = (policies) => {		
		let stats = [];
		const now = new Date();
		stats.push({ value: dashboardFilters["policies"]["notused"](policies).length, total: policies.length, title: t("label.policies-not-used-since-month"), invert: true, redirect: "/policies?filter=notused" });
		stats.push({ value: dashboardFilters["policies"]["active"](policies).length, total: policies.length, title: t("label.active-policies"), redirect: "/policies?filter=active" });
		return stats;
	}

	const prepareRolesData = (roles) => {
		let stats = [];
		stats.push({ value: dashboardFilters["roles"]["notempty"](roles).length, total: roles.length, title: t("label.no-empty-roles"), redirect: "/roles?filter=notempty" });
		stats.push({ value: dashboardFilters["roles"]["affected"](roles).length, total: roles.length, title: t("label.roles-affected-to-policy"), redirect: "/roles?filter=affected" });
		return stats;
	}

	const prepareDeviceGroupData = (deviceGroups) => {
		let stats = [];
		stats.push({ value: dashboardFilters["devicegroups"]["notempty"](deviceGroups).length, total: deviceGroups.length, title: t("label.no-empty-device-groups"), redirect: "/devicegroups?filter=notempty" });
		stats.push({ value: dashboardFilters["devicegroups"]["affected"](deviceGroups).length, total: deviceGroups.length, title: t("label.device-groups-affected-to-policy"), redirect: "/devicegroups?filter=affected" });
		return stats;
	}

	const prepareUsersData = (users) => {		
		let stats = [];
		const now = new Date();
		stats.push({ value: dashboardFilters["users"]["affected"](users).length, total: users.length, title: t("label.affected-to-roles"), redirect: "/users?filter=affected" });
		stats.push({ value: dashboardFilters["users"]["notconnected"](users).length, total: users.length, title: t("label.users-not-connected-since-month"), invert: true, redirect: "/users?filter=notconnected" });
		stats.push({ value: dashboardFilters["users"]["online"](users).length, total: users.length, title: t("label.users-online"), redirect: "/usersstatus?filter=online" });
		stats.push({ value: dashboardFilters["users"]["connected"](users).length, total: users.length, title: t("label.users-connected"), redirect: "/usersstatus?filter=connected" });
		return stats;
	}

	const prepareDevicesData = (devices) => {		
		let stats = [];
		const now = new Date();
		stats.push({ value: dashboardFilters["shields"]["affected"](devices).length, total: devices.length, title: t("label.affected-to-device-group"), redirect: "/shields?filter=affected" });
		stats.push({ value: dashboardFilters["shields"]["notconnected"](devices).length, total: devices.length, title: t("label.shields-not-connected-since-month"), invert: true, redirect: "/shields?filter=notconnected" });
		stats.push({ value: dashboardFilters["shields"]["online"](devices).length, total: devices.length, title: t("label.online-shields"), redirect: "/shieldsstatus?filter=online" });
		stats.push({ value: dashboardFilters["shields"]["connected"](devices).length, total: devices.length, title: t("label.connected-shields"), redirect: "/shieldsstatus?filter=connected" });
		return stats;
	}

	return (
		<Container>
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
			{users !== null && devices !== null && roles !== null && deviceGroups !== null && policies !== null &&
				<>
					<Row>
						<CustomerToDoListContainer data={devices} progress={computeProgress} />
						<CustomerShieldsStatusChart data={devices} progress={computeProgress} />
						<CustomerMonitoringAlertsChart />
					</Row>
					<Row>
						<CustomerSpeedDial title={t("label.users-stat")} stats={prepareUsersData(users)} />
						<CustomerSpeedDial title={t("label.shields-stat")} stats={prepareDevicesData(devices)} />
					</Row>
					<Row>
						<CustomerSpeedDial title={t("label.policies-stat")} stats={preparePoliciesData(policies)} />
						<CustomerSpeedDial title={t("label.roles-stat")} stats={prepareRolesData(roles)} />
						<CustomerSpeedDial title={t("label.device-groups-stat")} stats={prepareDeviceGroupData(deviceGroups)} />
					</Row>
				</>
			}
			<div><h5 className="bold-text">(c) 2018-2023 - EasySec Solutions Ltd.</h5></div>
			{systemVersion != null &&
			<div><h5 className="bold-text">System Version: {systemVersion.system_version}</h5></div>}
		</Container>
	);
};

export default CustomerDashboardPage;
