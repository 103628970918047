import React, { useEffect, useState, useContext } from "react";
import Alert from 'react-bootstrap/Alert';
import { Formik, Form } from "formik";
import useDataApi from '../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import validate from '../../hooks/useValidation';
import { languages } from '../../configs/Configs';
import AuthContext from '../../contexts/AuthContext';
import QueryString from 'querystring';
import { MyTextField, MySelect, MyCheckBox } from '../others/FormElements';

import Loading from '../others/Loading';

function UpdateCustomerInfoForm() {
	const { t } = useTranslation();
	const history = useHistory();
	const { auth, AuthDataChanged } = useContext(AuthContext);

	const [requestState, sendRequest] = useDataApi();
	const [customerInfo, setCustomerInfo] = useState(null);
	const [initialInfo, setInitialInfo] = useState(null);
	const [editSuperAdmin, setEditSuperAdmin] = useState(false);
	const [existingEmails, setExistingEmails] = useState([auth.data.adminEmail]);
	const themes = [
		{
			value: "dark",
			label: t('label.dark-theme')
		},
		{
			value: "light",
			label: t('label.light-theme')
		},
	]

	useEffect(() => {
		sendRequest('GET', '/customers-api/info', null, (result) => {
			const parsed = QueryString.parse(window.location.search.substring(1));
			var otherAdmins = [];
			if (result.listAdmins.length > 0) {
				otherAdmins = JSON.parse(result.listAdmins);
			}
			if (typeof parsed.id !== 'undefined') {
				if (auth.data.superAdmin && auth.data.adminEmail === parsed.id) {
					setInitialInfo(result);
					setEditSuperAdmin(true);
				} else {
					const found = otherAdmins.find(c => c.adminEmail === parsed.id);
					setInitialInfo(found);
				}
			} else {
				const otherEmails = otherAdmins.map(c => { return c.adminEmail });
				setExistingEmails(existingEmails.concat(otherEmails));
			}
			setCustomerInfo(result);
		})
	}, [])


	return (
		<div>
			{customerInfo && <Formik
				initialValues={initialInfo ?? { customerName: auth.data.customerName, existingEmails: existingEmails.join(), 
				language: "EN", theme: "dark", fullName: "", adminEmail: "", phone:"", address: ""}}
				validateOnChange={true}
				validateOnMount={true}
				validate={validate}
				onSubmit={(data, { setSubmitting }) => {
					setSubmitting(true);
					let toSubmit = { ...customerInfo };
					data.adminEmail = data.adminEmail.toLowerCase();
					const { existingEmails, ...dataToSubmit } = data;
					if (editSuperAdmin) {
						Object.assign(toSubmit, dataToSubmit);
					} else {
						var otherAdmins = [];
						if (customerInfo.listAdmins.length > 0)
							otherAdmins = JSON.parse(customerInfo.listAdmins);
						if (initialInfo) {
							const idx = otherAdmins.findIndex(c => c.adminEmail.toString() === data.adminEmail.toString());
							otherAdmins[idx] = dataToSubmit;
						} else {
							otherAdmins.push(dataToSubmit);
							toSubmit.portalUrl = window.location.hostname;
						}
						toSubmit.listAdmins = JSON.stringify(otherAdmins);
					}
					sendRequest('PUT', '/customers-api/info', toSubmit, (response) => {
						setSubmitting(false);
						if (initialInfo && initialInfo.adminEmail === auth.data.adminEmail) {
							AuthDataChanged({ language: data.language, fullName: data.fullName, theme: data.theme })
						}
						window.location.href = '/me'
					});
					setTimeout(() => {
						setSubmitting(false);
					}, 3000);
				}}
			>
				{({ values, errors, isSubmitting, setFieldValue, setFieldTouched, touched }) => (
					<Form>
						<div style={{ display: 'flex' }}>
							<div className="form__half">
								<MyTextField name="fullName" />
								<MyTextField name="adminEmail" readOnly={initialInfo ? true : false} />	
								{editSuperAdmin && <MyCheckBox name="twoFA" bold />}							
								{!initialInfo && <MyCheckBox name="isolated" bold />}
								{!initialInfo && values.isolated &&
									<div style={{ marginLeft: '30px' }}>
										<MyTextField name="password" inputType="password" />
									</div>}
								<MySelect
									value={languages.find(e => e.value === values["language"])}
									onChange={(e, value) => setFieldValue(e, value.value)}
									onBlur={setFieldTouched}
									error={errors["language"]}
									touched={touched["language"]}
									options={languages}
									name={"language"}
								/>
								<MyTextField name="customerName" readOnly inputType="hidden" />
							</div>
							<div className="form__half">
								<MyTextField name="phone" />
								<MyTextField name="address" />								
								<MySelect
									value={themes.find(e => e.value === values["theme"])}
									onChange={(e, value) => setFieldValue(e, value.value)}
									onBlur={setFieldTouched}
									error={errors["theme"]}
									touched={touched["theme"]}
									options={themes}
									name={"theme"}
								/>
							</div>
						</div>
						<div>
							<button className="btn btn-primary" disabled={isSubmitting || Object.keys(errors).length > 0} type="submit">
								{t('btn.submit')}
							</button>
							<button type="button" className="btn btn-primary" disabled={isSubmitting} onClick={() => { history.goBack() }}>
								{t('btn.cancel')}
							</button>
						</div>
					</Form>
				)}
			</Formik>}
			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</div>
	)
}

export default UpdateCustomerInfoForm;