import React from 'react';
import { PieChart, Pie } from 'recharts';
import Panel from 'shared/components/Panel';

function CustomerSpeedDial ({title, stats}) {
	let pieChartData = stats.map(stat => {
		let row = []; let percent = Math.trunc((stat.value / stat.total) * 100);
		row.push({ value: percent, fill: stat.invert ? '#ff4861' : '#b8e986' });
		row.push({ value: 100 - percent, fill: stat.invert ? '#b8e986' : '#ff4861' });
		return row;
	});
	return (
		<Panel
			lg={6}
			xl={4}
			md={12}
			xs={12}
			title={title}
			subhead=""
		>
			<div className="dashboard">
			{stats.map((stat, index) => 	
				<div className="dashboard__current-users">														
							<div className="dashboard__current-users-chart">
								<PieChart height={150} width={280} onClick={() => {if (stat.redirect) window.location.href=stat.redirect}} cursor={stat.redirect ? "pointer" : "default"}
								>
									<Pie
										data={pieChartData[index]}
										dataKey="value"
										cx={135}
										cy={140}
										startAngle={180}
										endAngle={0}
										innerRadius={100}
										outerRadius={128}
										paddingAngle={0}																													
									/>
								</PieChart>
								<p className="dashboard__current-users-label">{stat.value}/{stat.total}</p>
							</div>
							<div className="dashboard__current-users-info" dir="ltr">
								<p className="dashboard__current-users-day">
									<span>0</span>
								</p>
								<p className="dashboard__current-users-day">
									<span>{stat.total}</span>
								</p>
							</div>
							<div className="dashboard__weekly-stat-info">
								<p>{stat.title}</p>
							</div>																				
				</div>
				)}
			</div>
		</Panel>
	);
};

export default CustomerSpeedDial;
