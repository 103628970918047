import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal';
import useDataApi from '../../hooks/useDataApi'
import {
	Alert, Button, ButtonGroup, ButtonToolbar, UncontrolledTooltip
} from 'reactstrap';
import Loading from '../others/Loading';
import DatePicker from 'react-datepicker';
import { Colors, tableConfig, computeDateLimits, filterPassedTime } from '../../configs/Configs';
import ReactTableBase from '../../shared/components/table/ReactTableBase';

function ShieldsListTable(props) {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [shieldData, setShieldData] = useState([]);
	const [deleteModalData, setDeleteModalData] = useState({
		display: false,
		extid: null
	})
	const [approveModalData, setApproveModalData] = useState({
		display: false,
		extid: null,
		protectedip: null,
	})
	const [snoozeModalData, setSnoozeModalData] = useState({
		display: false,
		shield: null,
		extid: null
	})
	const [snoozeTimeOption, setSnoozeTimeOption] = useState('3 days')
	const [snoozeTime, setSnoozeTime] = useState(new Date())

	const onSnoozeTimeChange = (e) => {
		setSnoozeTimeOption(e.target.value);
	};

	const StatusColor = {
		"N/A": Colors.blue,
		"pending": Colors.orange,
		"approved": Colors.yellow,
		"active": Colors.green
	}
	const approveShieldConfirmed = (e) => {
		setApproveModalData({ display: false })
		sendRequest('PUT', `/customers-api/shields/${approveModalData.extid}/approve`, null, (result) => {
			window.location.reload()
		})
	}
	const deleteShieldConfirmed = (e) => {
		setDeleteModalData({ display: false })
		sendRequest('DELETE', `/customers-api/shields/${deleteModalData.extid}`, null, (result) => {
			window.location.reload()
		})
	}
	const snoozeShieldConfirmed = (e) => {
		setSnoozeModalData({ display: false })
		var expiration = new Date();
		if (snoozeTimeOption === '3 days') {
			expiration.setHours(expiration.getHours() + 72);
		}
		else if (snoozeTimeOption === '7 days') {
			expiration.setHours(expiration.getHours() + 168);
		}
		else {
			expiration = snoozeTime;
		}
		sendRequest('PUT', `/customers-api/shields/${snoozeModalData.shield.extId}/info`, { extId: snoozeModalData.shield.extId, hashedMacAdd: snoozeModalData.shield.hashedMacAdd, snooze: expiration }, (result) => {
			window.location.reload()
		})
	}

	const columnsAll = useMemo(
		() => [
			{
				Header: '#',
				accessor: 'id',
			},
			{
				Header: () => (t('table.shield-device-name')),
				accessor: 'name',
			},
			{
				Header: () => (t('table.shield-protected-ip-device')),
				accessor: 'ip',
			},
			{
				Header: () => (t('table.shield-status')),
				accessor: 'shield',
				id: 'regStatus',
				Cell: ({ value }) => {
					if (value.regStatus === "pending") {
						return (<button className="btn btn-success" onClick={() => {
							setApproveModalData({
								display: true,
								extid: value.extId,
								protectedip: value.ip
							})
						}}>
							<b>{t("table.approve-shield-btn")}</b>
						</button>
						)
					}
					else {
						return (<span style={{ color: StatusColor[value.regStatus] }}>
							<b>{value.regStatus === "N/A" ? "created" : value.regStatus}</b>
						</span>)
					}
				}
			},
			{
				Header: () => (t('table.shield-ip')),
				accessor: 'shieldExtIp',
			},
			{
				Header: () => (t('table.edit')),
				accessor: 'shield',
				id: 'edit',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<button
						className="btn btn-info"
						extid={value.extId}
						onClick={(e) => {
							window.location.href = `/editshield?extId=${value.extId}`
						}}>
						{t("table.edit-btn")}
					</button>),
			},
			{
				Header: () => (t('table.delete')),
				accessor: 'shield',
				id: 'delete',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<button
						className="btn btn-danger"
						extid={value.extId}
						onClick={(e) => {
							setDeleteModalData({
								display: true,
								extid: value.extId
							})
						}}>
						{t("table.delete-btn")}
					</button>)
			}
		],
		[shieldData],
	);

	const columnsFilter = useMemo(
		() => [
			{
				Header: () => (t('table.shield-device-name')),
				accessor: 'name',
			},
			{
				Header: () => (t('table.shield-protected-ip-device')),
				accessor: 'ip',
			},
			{
				Header: () => (t('table.action')),
				accessor: 'shield',
				Cell: ({ value }) => (
					<ButtonToolbar>
						<ButtonGroup className="btn-group--icons" dir="ltr">
							<Button outline id="snooze" onClick={() => {
								setSnoozeModalData({
									display: true,
									shield: value,
									extid: value.extId
								})
							}}><span className="lnr lnr-clock" /></Button>
							<UncontrolledTooltip placement="right" target="snooze">
								{t("label.snooze")}
							</UncontrolledTooltip>
							{value.regStatus === "pending" && <> <Button outline id="approve" onClick={() => {
								setApproveModalData({
									display: true,
									extid: value.extId,
									protectedip: value.ip
								})
							}}><span className="lnr lnr-thumbs-up" /></Button>
							<UncontrolledTooltip placement="right" target="approve">
								{t("table.approve-shield-btn")}
							</UncontrolledTooltip></>}
						</ButtonGroup>
					</ButtonToolbar>)
			}
		],
		[shieldData],
	);

	useEffect(() => {
		tableConfig.placeholder = t('label.search');
		let shieldDetails = [];
		for (var i in props.data) {
			let detail = {};
			detail.id = (parseInt(i) + 1).toString();
			detail.ip = props.data[i].ip;
			detail.regStatus = props.data[i].regStatus;
			detail.extId = props.data[i].extId;
			detail.shieldExtIp = props.data[i].shieldExtIp;
			detail.shield = props.data[i];
			detail.name = props.data[i].name;
			shieldDetails.push(detail);
		}
		setShieldData(shieldDetails);
	}, [props.data])


	return (
		<div>
			<ReactTableBase
				columns={props.filter === 'all' ? columnsAll : columnsFilter}
				data={shieldData}
				tableConfig={tableConfig}
			/>
			<Modal show={approveModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("title.modal-approve-shield-title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<p>{approveModalData.protectedip ? t('sentences.shield-will-be-approved', { extId: approveModalData.extid, protectedIp: approveModalData.protectedip }) :
					t('sentences.shield-will-be-approved-auto', { extId: approveModalData.extid})}</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-success" onClick={approveShieldConfirmed}>{t("btn.confirm-approve-shield")}</button>
					<button type="button" className="btn btn-secondary" onClick={() => setApproveModalData({ display: false })} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			<Modal show={deleteModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("title.modal-delete-shield-title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<p>{t("sentences.element-will-be-deleted", { elementName: deleteModalData.extid })}</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-danger" onClick={deleteShieldConfirmed}>{t("btn.confirm-delete-shield")}</button>
					<button type="button" className="btn btn-secondary" onClick={() => setDeleteModalData({ display: false })} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			<Modal show={snoozeModalData.display}>
				<form onSubmit={snoozeShieldConfirmed}>
					<Modal.Header>
						<h5 className="modal-title">{t("title.modal-snooze-shield") + " " + snoozeModalData.extid} </h5>
					</Modal.Header>
					<Modal.Body>
						<div style={{ textAlign: 'left' }}>
							<label>
								<input
									type="radio"
									value="3 days"
									checked={snoozeTimeOption === '3 days'}
									onChange={onSnoozeTimeChange}
								/>
								<span className="radio-btn__radio-custom" />
								<span>{t("label.three-days")}</span>
							</label>
							<br />
							<label>
								<input
									type="radio"
									value="7 days"
									checked={snoozeTimeOption === '7 days'}
									onChange={onSnoozeTimeChange}
								/>
								<span className="radio-btn__radio-custom" />
								<span>{t("label.seven-days")}</span>
							</label>
							<br />
							<label>
								<input
									type="radio"
									value="Custom"
									checked={snoozeTimeOption === 'Custom'}
									onChange={onSnoozeTimeChange}
								/>
								<span className="radio-btn__radio-custom" />
								<span>{t("label.custom")}</span>
								{snoozeTimeOption === 'Custom' &&
									<div className="date-picker">
										<DatePicker
											timeFormat="HH:mm"
											className="form__form-group-datepicker"
											selected={snoozeTime}
											onChange={(date) => setSnoozeTime(date)}
											minDate={computeDateLimits().min}
											maxDate={computeDateLimits().max}
											filterTime={filterPassedTime}
											showTimeSelect
											dateFormat="MMMM d, yyyy h:mm aa"
											dropDownMode="select"
										/>
									</div>
								}
							</label>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button type="submit" className="btn btn-warning">{t("label.snooze")}</button>
						<button type="button" className="btn btn-secondary" onClick={() => setSnoozeModalData({ display: false })} >{t("btn.modal-close")}</button>
					</Modal.Footer>
				</form>
			</Modal>
			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</div>
	)
}

export default ShieldsListTable
