import React, { useState, useEffect } from 'react'
import UpdateAdminCustomerForm from '../../forms/UpdateAdminCustomerForm';
import useDataApi from '../../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert'
import QueryString from 'query-string';
import Loading from '../../others/Loading';

function UpdateAdminCustomerPage(props) {
    const { t } = useTranslation();
    const [requestState, sendRequest] = useDataApi();
    const [customer, setCustomer] = useState(null)
    const [error, setError] = useState("");
    

    useEffect(() => {
        sendRequest('GET', `/admins-api/integrators/${QueryString.parse(window.location.search).integratorId}/customers/${QueryString.parse(window.location.search).customerId}/info`, null, (response) => {
            try {
                setCustomer(response);    
            } catch (error) {
                setError(t('error.bad-request'))
            }    
        });
    }, [])


    
    return (
        <div>
            {customer &&
            <div className="container">
            <div className="card col-lg-12" style={{marginTop: '50px', paddingTop: '20px'}}>
                <article className="card-body">
                    <h4 className="card-title mb-4 mt-1">{t("title.update-info")}</h4>
                        <UpdateAdminCustomerForm customer={customer}/>
                    </article>
                </div>
            </div>}
            {error && <p>{error}</p>}
            {requestState.isLoading && <Loading/>}
            {requestState.isError &&
                <Alert variant="danger">
                <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
                <p>{t(requestState.ErrorText)}</p>
                </Alert>
            }
        </div>
    )
}

export default UpdateAdminCustomerPage
