import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert';
import AdminShieldsListTable from '../../tables/AdminShieldsListTable'
import QueryString from 'query-string';
import Loading from '../../others/Loading';

function AdminShieldsTablePage(props) {
    const { t } = useTranslation();
    const [requestState, sendRequest] = useDataApi();
    useEffect(()=> {
        sendRequest('GET', `/admins-api/customers/${QueryString.parse(window.location.search).customerId}/shields`)
    }, [])
    return (
        <div className="container">
            <h4>{t("title.shield-table")}</h4>
            <div style={{height: "70px"}}>
                <a href={`/addshield?integratorId=${QueryString.parse(window.location.search).integratorId}&customerId=${QueryString.parse(window.location.search).customerId}`} className="btn btn-primary float-right">{t("btn.add-shield")}</a>
            </div>
            
            {requestState.isLoading && <Loading/>}
            {requestState.isError &&
                <Alert variant="danger">
                <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
                <p>{t(requestState.ErrorText)}</p>
                </Alert>
            }
            { requestState.response && (
                requestState.response.length
                ? <AdminShieldsListTable data={{data: requestState.response}}/>
                : <p>{t('label.no-shields-yet')}</p>
            )}
        </div>
    )  
}

export default AdminShieldsTablePage;