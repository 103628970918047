import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseUrls } from '../../../configs/Configs'
import AuthContext from '../../../contexts/AuthContext';

function UserDashboardPage() {
    const { t } = useTranslation()
    const { auth } = useContext(AuthContext);
    return (
        <div>
            <h1>{t('label.welcome-to-new-portal')}</h1>
            <br/>
            <h1>{t('label.title-download-agent')}</h1>
            <h3>{t('label.subtitle-download-agent')}</h3>
            <a className="btn btn-info" rel="noopener noreferrer" target="_blank" href={BaseUrls.gateway + `/documents-api/User_Guide.pdf`}>{t('btn.read-documentation')}</a>
            <div class="border-top my-3"></div>			
            <a className="btn btn-primary" style={{marginLeft: '10px'}} rel="noopener noreferrer" target="_blank" href={BaseUrls.gateway + `/documents-api/agentMsi/EasySecAgent.msi`}>{t('btn.download-agent-win64')}</a>		
        </div>
    )
}

export default UserDashboardPage
