import React from 'react'
import useDataApi from '../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import QueryString from 'query-string';
import Loading from '../others/Loading';
import { Formik, Form } from "formik";
import validate from '../../hooks/useValidation';
import { MyTextField } from '../others/FormElements';
import { Card, CardBody, Col } from 'reactstrap';

function ChangePasswordPage(props) {
	const initialValues = {
		identifier: QueryString.parse(window.location.search).identifier,
		type: QueryString.parse(window.location.search).type,
		username: "",
		password: "",
		newPassword: "",
		confirmNewPassword: "",
	}
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();

	return (
		<div className="container theme-dark ltr-support">
			<Col xs={24} md={24} lg={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h3 className="bold-text">{t("title.renew-password")}</h3>
						</div>
						<Formik
							initialValues={initialValues}
							validateOnChange={true}
							validateOnMount={true}
							validate={validate}
							onSubmit={(data, { setSubmitting }) => {
								setSubmitting(true);
								sendRequest('POST', '/auth/changepwd', data, (response) => {
									setSubmitting(false);
									window.location.href = '/logout'
								});
								setTimeout(() => {
									setSubmitting(false);
								}, 3000);
							}}
						>
							{({ values, errors, isSubmitting }) => (
								<Form>
									<MyTextField name="identifier" readOnly={true} />
									<MyTextField name="username"/>									
									<MyTextField name="password" inputType="password" />
									<MyTextField name="newPassword" inputType="password" />
									<MyTextField name="confirmNewPassword" inputType="password" />									
									<MyTextField name="type" inputType="hidden" />	
									<div>
										{requestState.isLoading && <Loading />}
										{requestState.isError &&
											<Alert variant="danger">
												<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
												<p>{t(requestState.ErrorText)}</p>
											</Alert>
										}
										<div>
											<button className="btn btn-primary" disabled={isSubmitting || Object.keys(errors).length > 0} type="submit">
												{t('btn.submit')}
											</button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</CardBody>
				</Card>
			</Col>
		</div>
	)
}

export default ChangePasswordPage
