

const validate = (values) => {
    const errors = {};
    for(var i in values){
    	if ((/^([ ]+)|([ ]+)$/.test(values[i]))){
    		errors[i] = "error.heading-trailing-spaces";
    	}
        let validationResult = validation(i, values[i], values)
        if(validationResult){
            errors[i] = validationResult
        }
    }
    return errors;
}
const validation = (name, value, values) => {
    switch (name) {
        // Regular Texts
        case 'customerName':
        case 'username':
        case 'name':        
        case "integratorId":
        case "language":        
        case "phone":
        case "address":        
        case "extId":              
            if (!value) {
                return "error.required";
            }
            if(value.length > 255){
                return "error.max-255"
            }
            break;



        // Passwords
        case "password":
        case "newPassword":
            if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d !"#\$%&'\(\)\*\+,\-\./:;<=>\?@\[\\\]\^_`\{\|\}~]{9,128}$/.test(value))) {
            	return "error.password-dont-meet-requirements";
            }
            break;
        case "confirmPassword":            
            if(values['password'] !== value){
                return "error.password-dont-match";
            }            
            break;
        case 'confirmNewPassword':            
            if(values['newPassword'] !== value){
                return "error.password-dont-match";
            }            
            break;

        // Emails
        case "email":
        case "adminEmail":
            if (!value) {
                return "error.required";
            }
            if(!(/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))){
                return "error.invalid-email"
            }
            if (values["existingEmails"]) {
            	const emails = values["existingEmails"].split(",");
            	if (emails.includes(value))
            		return "error.existing-email"
            }
            break;



        // Ips
        case "shieldPublicIp":
            if(!values["publicConnectionAllowed"]){
                break;
            } else if(!(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(value))){
                return "error.invalid-ip";
            }
            break;
        case "ip":
            if (values["isAutoIP"] && !value) {
                break;
            }
            if(!(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(value))){
                return "error.invalid-ip";
            }
            break;



        // Ports
        case "shieldPublicPort":
            if(!values["publicConnectionAllowed"]){
                break;
            } else if(!(/^$|^([ ]*)([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-3][0-9]|6553[0-5])([ ]*)$/.test(value))){
                return "error.invalid-port"
            }
            break;
        case 'publicPort':
        case 'privatePort':
            if (!value) {
                return "error.required";
            }
            if(!(/^$|^([ ]*)([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-3][0-9]|6553[0-5])([ ]*)$/.test(value))){
                return "error.invalid-port"
            }
            break;

        // Mac address
        case "macDevice":
            if (!value) {
                return "error.required";
            }
            if(!(/^(([0-9A-F]{2}[:]){5})([0-9A-F]{2})$|^([0-9A-F]{12})$/.test(value))){
                return "error.invalid-mac"
            }
            break;
        

        // Checkbox must accept
        case "acceptConditions":
            if (!value) {
                return "error.must-accept-conditions";
            }
            break;

        default:
            return null;
    }
    return null;
}
export default validate
