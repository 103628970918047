import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { useTranslation } from 'react-i18next';

function SidebarContent({
	sidebarCollapse, theme
}) {
	const { t } = useTranslation();

	SidebarContent.propTypes = {
		sidebarCollapse: PropTypes.bool,
	};

	SidebarContent.defaultProps = {
		sidebarCollapse: false,
	};
	return (
		<div className="sidebar__content">
			<ul className="sidebar__block">
				<SidebarLink
					title={t('navbar.dashboard')}
					icon="home"
					route="/"	
					color={theme === 'theme-dark' ? "#fee347" : "#70bbfd"}
					fontSize="16px"	
					theme={theme}		
				/>
			</ul>
			<ul className="sidebar__block">
				<SidebarCategory title={t('navbar.navigation')} icon="diamond" sidebarCollapse={sidebarCollapse} theme={theme}>
					<SidebarLink title={t("navbar.shields")} route="/shields" theme={theme}/>
					<SidebarLink title={t("navbar.users")} route="/users" theme={theme}/ >
					<SidebarLink title={t("navbar.roles")} route="/roles" theme={theme}/>
					<SidebarLink title={t("navbar.devicegroups")} route="/devicegroups" theme={theme}/>
					<SidebarLink title={t("navbar.policies")} route="/policies" theme={theme}/>					
				</SidebarCategory>
			</ul>
			<ul className="sidebar__block">
				<SidebarCategory title={t('title.objects-creation')} icon="diamond" sidebarCollapse={sidebarCollapse} theme={theme}>
					<SidebarLink title={t("title.create-shield")} route="/addshield" theme={theme}/>
					<SidebarLink title={t("title.create-user")} route="/adduser" theme={theme}/ >
					<SidebarLink title={t("title.create-occas-user")} route="/addoccasuser" theme={theme}/ >
					<SidebarLink title={t("title.create-role")} route="/addrole" theme={theme}/>
					<SidebarLink title={t("title.create-devicegroup")} route="/adddevicegroup" theme={theme}/>
					<SidebarLink title={t("title.create-policy")} route="/addpolicy" theme={theme}/>
					<SidebarLink title={t("title.create-device-type")} route="/adddevicetype" theme={theme}/>
					<SidebarLink title={t("title.map-subnet")} route="/mapsubnet" theme={theme}/>							
				</SidebarCategory>
			</ul>			
			<ul className="sidebar__block">
				<SidebarCategory title={t('navbar.title-monitoring')} icon="diamond" sidebarCollapse={sidebarCollapse} theme={theme}>
					<SidebarLink title={t("navbar.shields-status")} route="/shieldsstatus" theme={theme}/>
					<SidebarLink title={t("navbar.users-status")} route="/usersstatus" theme={theme}/>	
					<SidebarLink title={t("navbar.recent-activities")} route="/activities" theme={theme}/>
					<SidebarLink title={t("navbar.alerts")} route="/alerts" theme={theme}/>	
					<SidebarLink title={t("navbar.logs")} route="/logs" theme={theme}/>					
				</SidebarCategory>
			</ul>	
		</div>
	);
};

export default SidebarContent;
