import React, { useEffect, useState, useContext } from "react";
import Alert from 'react-bootstrap/Alert';
import { Formik, Form } from "formik";
import useDataApi from '../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import validate from '../../hooks/useValidation';
import { languages } from '../../configs/Configs';
import AuthContext from '../../contexts/AuthContext';
import { MyTextField, MySelect } from '../others/FormElements';
import Loading from '../others/Loading';

function UpdateIntegratorInfoForm() {
    const { t } = useTranslation();
    const { AuthDataChanged } = useContext(AuthContext);

    const [requestState, sendRequest] = useDataApi();
    const [initialInfo, setInitialInfo] = useState(null)
    useEffect(() => {
        sendRequest('GET', '/integrators-api/info', null, (result) => {
            setInitialInfo(result)
        })
    }, [])

    if(initialInfo) {return (
        <div>
                <Formik
                    initialValues={initialInfo}
                    validateOnMount={true}
					validateOnChange={true}
                    validate={validate}
                    onSubmit={(data, { setSubmitting }) => {
                        setSubmitting(true);
                        sendRequest('PUT', '/integrators-api/info', data, (response) => {
                            setSubmitting(false);
                            AuthDataChanged({language: data.language, fullName: data.fullName})
                            window.location.href = '/'
                        });
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 3000);
                    }}
                >
                    {({ values, errors, isSubmitting, setFieldValue, setFieldTouched, touched }) => (
                        <Form>
                            <MyTextField name="integratorId" readOnly bold />
                            <MyTextField name="fullName" />
                            <MyTextField name="adminEmail" />
                            <MyTextField name="phone" />
                            <MyTextField name="address" />
                            <MySelect
                                value={languages.find(e => e.value === values["language"])}
                                onChange={(e, value) => setFieldValue(e, value.value)}
                                onBlur={setFieldTouched}
                                error={errors["language"]}
                                touched={touched["language"]}
                                options={languages}
                                name={"language"}
                            />
                            <div>
                                <button className="btn btn-primary" disabled={isSubmitting} type="submit">
                                    {t('btn.submit')}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
                {requestState.isLoading && <Loading/>}
                {requestState.isError &&
                    <Alert variant="danger">
                        <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
                        <p>{t(requestState.ErrorText)}</p>
                    </Alert>
                }
        </div>
    ) } else {
        return (
            <div>
                {requestState.isLoading && <Loading/>}
                {requestState.isError &&
                    <Alert variant="danger">
                        <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
                        <p>{t(requestState.ErrorText)}</p>
                    </Alert>
                }
            </div>
        )
    }
}

export default UpdateIntegratorInfoForm