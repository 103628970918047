import React, { useState, useEffect } from 'react'
import UpdateUserForm from '../../forms/UpdateUserForm';
import QueryString from 'querystring';
import useDataApi from '../../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert'
import Loading from '../../others/Loading';

function UpdateCustomerUserPage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [user, setUser] = useState(null)
	const [error, setError] = useState("");


	useEffect(() => {
		const parsed = QueryString.parse(window.location.search);
		sendRequest('GET', `/customers-api/users/${parsed.userId}/info`, null, (response) => {
			try {
				setUser(response);
			} catch (error) {
				setError(t('error.bad-request'))
			}
		});
	}, [])



	return (
		<div>
			{user &&
				<div className="container">
					<div className="card col-lg-10">
						<article className="card-body">
							<UpdateUserForm user={user} />
						</article></div></div>}
			{error && <p>{error}</p>}
			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</div>
	)
}

export default UpdateCustomerUserPage
