import { policyActions, roleActions, deviceGroupActions, userActions, deviceActions } from '../../configs/Configs';

const initialState = {
  actions: [policyActions, roleActions, deviceGroupActions, userActions, deviceActions]
}

const createActionsReducer = (state = initialState, action) => {
  return { ...state, actions: action.items };
};

export default createActionsReducer;
