import React from 'react'

import AdminNavbar from '../../navigations/AdminNavbar';

import AdminDashboardPage from './AdminDashboardPage';
import UpdateAdminInfoPage from './UpdateAdminInfoPage'
import UpdateAdminPasswordPage from './UpdateAdminPasswordPage';
import IntegratorsTablePage from './IntegratorsTablePage';
import CreateIntegratorPage from './CreateIntegratorPage';
import UpdateIntegratorPage from './UpdateIntegratorPage';
import ModuleVersionsPage from './ModuleVersionsPage';
import AdminCustomersTablePage from './AdminCustomersTablePage';
import CreateAdminCustomerPage from './CreateAdminCustomerPage';
import UpdateAdminCustomerPage from './UpdateAdminCustomerPage';
import AdminShieldsTablePage from './AdminShieldsTablePage';
import UpdateAdminShieldPage from './UpdateAdminShieldPage';
import CreateAdminShieldPage from './CreateAdminShieldPage';
import TranslationPage from './TranslationPage';
import AdminMonitoringActivitiesPage from './AdminMonitoringActivitiesPage';
import AdminMonitoringAlertsPage from './AdminMonitoringAlertsPage';
import AutoLogoutTimer from '../../others/AutoLogoutTimer';

export const AdminPages = {
    AdminDashboardPage,
    UpdateAdminInfoPage,
    UpdateAdminPasswordPage,
    IntegratorsTablePage,
    CreateIntegratorPage,
    UpdateIntegratorPage,
    ModuleVersionsPage,
    AdminCustomersTablePage,
    CreateAdminCustomerPage,
    UpdateAdminCustomerPage,
    AdminShieldsTablePage,
    UpdateAdminShieldPage,
    CreateAdminShieldPage,
    TranslationPage,
    AdminMonitoringActivitiesPage,
    AdminMonitoringAlertsPage,

}

function AdminLayout({component: Component, noNavBar, ...rest}) {
    return (
        <div>
            {(!noNavBar) && <AdminNavbar/>}
            <div className="container theme-dark ltr-support" style={{marginTop: '50px'}}>
                <AutoLogoutTimer ComposedClass={<Component {...rest}/>}/>
            </div>
        </div>
    )
}

export default AdminLayout
