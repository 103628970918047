import React, { useState } from "react";
import { Document, Page } from "react-pdf";

function PDFFile({fileUrl}) {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <div>
        <nav style={{position: "relative"}}>
          <button className="btn btn-link" type="button" onClick={()=>{
            if(currentPage > 1){
              setCurrentPage(currentPage -1);
            }
          }}>Prev</button>

           {currentPage}/{numPages}

          <button className="btn btn-link" type="button" onClick={()=>{
            if(currentPage < numPages){
              setCurrentPage(currentPage +1);
            }
          }}>Next</button>
        </nav>
        <a className="btn btn-primary float-right " style={{marginLeft: '10px'}} rel="noopener noreferrer" target="_blank" href={fileUrl}>Download</a>
        <div key={`pdf-${fileUrl}`}>
          <Document
            file={fileUrl}
            onLoadSuccess={({numPages}) => {
              setNumPages(numPages);
            }}
          >
            <Page pageNumber={currentPage}/>
          </Document>
        </div>
        
    </div>
  )
}

export default PDFFile