import React from 'react'
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next'

const NotFoundPage = () => {
    const { t } = useTranslation();
    return (
        <Container style={{textAlign: 'center', position: 'sticky', display:'inline-block'}}>
            <Image alt="EasySec" src="/assets/logo.png" style={{maxWidth: '80%', marginTop: '3%'}}/>
            <h1 style={{margin: '5%'}}>
            <b>{t("error.title")}</b>
            </h1>
            <h5>{t('error.subtitle-1')}</h5>
            <h6>{t('error.subtitle-2')}</h6>
        </Container>
    )
}

export default NotFoundPage
