import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProps } from 'shared/prop-types/ReducerProps';
import ReactTableDnDBody from './ReactTableDnDBody';

const ReactTableDefaultBody = ({ page, getTableBodyProps, prepareRow, onClickCellHandler, renderRowSubComponent }) => (
	<tbody className="table table--bordered" {...getTableBodyProps()}>
		{page.map((row, indexRow) => {
			prepareRow(row);
			return (
				<>
				<tr {...row.getRowProps()}>
					{row.cells.map((cell, indexCell) => {
						if (typeof onClickCellHandler === 'undefined') return (
							<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
						); else return (
							<td {...cell.getCellProps()} onClick={() => onClickCellHandler(row, indexCell)}>{cell.render('Cell')}</td>)
					})}
				</tr>
				<tr class="subcomponent">{row.isExpanded ? renderRowSubComponent(row) : null}</tr>
				</>
      )
    })}
	</tbody>
);

ReactTableDefaultBody.propTypes = {
	page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	getTableBodyProps: PropTypes.func.isRequired,
	prepareRow: PropTypes.func.isRequired,
};

const ReactTableBody = ({
	page, getTableBodyProps, prepareRow, withDragAndDrop, updateDraggableData, theme, onClickCellHandler, renderRowSubComponent
}) => (
		<Fragment>
			{withDragAndDrop
				? (
					<ReactTableDnDBody
						page={page}
						getTableBodyProps={getTableBodyProps}
						prepareRow={prepareRow}
						updateDraggableData={updateDraggableData}
						theme={theme}
					/>
				) : (
					<ReactTableDefaultBody
						page={page}
						getTableBodyProps={getTableBodyProps}
						prepareRow={prepareRow}
						onClickCellHandler={onClickCellHandler}
						renderRowSubComponent={renderRowSubComponent}
					/>
				)}
		</Fragment>
	);

ReactTableBody.propTypes = {
	page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	getTableBodyProps: PropTypes.func.isRequired,
	prepareRow: PropTypes.func.isRequired,
	updateDraggableData: PropTypes.func.isRequired,
	withDragAndDrop: PropTypes.bool.isRequired,
	theme: ThemeProps.isRequired,
};

export default connect(state => ({
	theme: state.theme,
}))(ReactTableBody);
