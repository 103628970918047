import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal';
import useDataApi from '../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert';
import Loading from '../others/Loading';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { tableConfig, policyActions, roleActions, deviceGroupActions, userActions, deviceActions, DefaultColumnFilter } from '../../configs/Configs';
import { useHistory, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import {
	Button, ButtonGroup, ButtonToolbar, Card, CardBody, UncontrolledTooltip
} from 'reactstrap';

function NavigationListTable({ users, devices, roles, devicegroups, policies, showBy, dispatch }) {
	const { t } = useTranslation();
	const history = useHistory();
	const [requestState, sendRequest] = useDataApi();
	const policy = t("title.policy-table");
	const role = t("title.role-table");
	const deviceGroup = t("title.devicegroup-table");
	const user = t("title.user-table");
	const device = t("title.shield-table");

	const getInfo = (id, name) => {
		let info = {};
		info.id = id;
		info.name = name
		return info;
	}

	const getDeviceIdsFromDgIds = (list) => {
		const mySet = new Set()
		list.forEach(function(dgId) {
			const deviceIds = devicegroups.filter(dg => dgId === dg.deviceGroupId)[0].devices;
			deviceIds.forEach(function(deviceId) {
				mySet.add(deviceId)
			})
		})
		return Array.from(mySet);
	}

	const getUserIdsFromRoleIds = (list) => {
		const mySet = new Set()
		list.forEach(function(roleId) {
			const userIds = roles.filter(role => roleId === role.roleId)[0].users;
			userIds.forEach(function(userId) {
				mySet.add(userId)
			})
		})
		return Array.from(mySet);
	}

	const getPolicyNames = (list) => { if (typeof list !== 'undefined') return policies.filter(policy => { return list.includes(policy.policyId) }).map(policy => { return getInfo(policy.policyId, policy.name) }); else return [] };
	const getRoleNames = (list) => { if (typeof list !== 'undefined') return (roles.filter(role => { return list.includes(role.roleId) }).map(role => { return getInfo(role.roleId, role.name) })); else return [] };
	const getDgNames = (list) => { if (typeof list !== 'undefined') return (devicegroups.filter(dg => { return list.includes(dg.deviceGroupId) }).map(dg => { return getInfo(dg.deviceGroupId, dg.name) })); else return [] };
	const getUserNames = (list) => { if (typeof list !== 'undefined') return (users.filter(user => { return list.includes(user.userId) }).map(user => { return getInfo(user.userId, user.fullName) })); else return [] };
	const getDeviceNames = (list) => { if (typeof list !== 'undefined') return (devices.filter(device => { return list.includes(device.hashedMacAdd) }).map(device => { return getInfo(device.hashedMacAdd, device.name) })); else return [] };
	const getByPolicy = (firstId) => {
		let result = policies.map(policy => {
			let row = {}; row.first = policy.name; row.second = getRoleNames([policy.roleId]); row.third = getDgNames([policy.deviceGroupId]);
			row.fourth = getUserNames(roles.filter(role => policy.roleId === role.roleId)[0].users);
			row.fifth = getDeviceNames(devicegroups.filter(dg => policy.deviceGroupId === dg.deviceGroupId)[0].devices); row.action = { id: policy.policyId, name: policy.name, entity: policy };
			row.meta = JSON.stringify(policy);
			return row;
		});
		if (typeof firstId !== 'undefined') {
			const first = result.filter(item => item.action.entity.policyId === firstId)[0];
			result = result.filter(item => item.action.entity.policyId !== firstId);
			result.unshift(first);
		}
		return result;
	}
	const getByRole = (firstId) => {
		let result = roles.map(role => {
			let row = {}; row.first = role.name; row.second = getUserNames(role.users); row.third = getPolicyNames(role.policies);
			row.fourth = getDgNames(role.deviceGroups); row.fifth = getDeviceNames(getDeviceIdsFromDgIds(role.deviceGroups)); row.action = { id: role.roleId, name: role.name, entity: role };
			row.meta = JSON.stringify(role);
			return row;
		});
		if (typeof firstId !== 'undefined') {
			const first = result.filter(item => item.action.entity.roleId === firstId)[0];
			result = result.filter(item => item.action.entity.roleId !== firstId);
			result.unshift(first);
		}
		return result;
	}
	const getByDeviceGroup = (firstId) => {
		let result = devicegroups.map(dg => {
			let row = {}; row.first = dg.name; row.second = getDeviceNames(dg.devices); row.third = getPolicyNames(dg.policies);
			row.fourth = getRoleNames(dg.roles); row.fifth = getUserNames(getUserIdsFromRoleIds(dg.roles)); row.action = { id: dg.deviceGroupId, name: dg.name, entity: dg };
			row.meta = JSON.stringify(dg);
			return row;
		});
		if (typeof firstId !== 'undefined') {
			const first = result.filter(item => item.action.entity.deviceGroupId === firstId)[0];
			result = result.filter(item => item.action.entity.deviceGroupId !== firstId);
			result.unshift(first);
		}
		return result;
	}
	const getByUser = (firstId) => {
		let result = users.map(user => {
			let row = {}; row.first = user.fullName; row.second = getPolicyNames(user.policies); row.third = getRoleNames(user.roles);
			row.fourth = getDgNames(user.deviceGroups); row.fifth = getDeviceNames(getDeviceIdsFromDgIds(user.deviceGroups)); row.action = { id: user.userId, name: user.fullName, entity: user };
			row.meta = JSON.stringify(user);
			return row;
		});
		if (typeof firstId !== 'undefined') {
			const first = result.filter(item => item.action.entity.userId === firstId)[0];
			result = result.filter(item => item.action.entity.userId !== firstId);
			result.unshift(first);
		}
		return result;
	}
	const getByDevice = (firstId) => {
		let result = devices.map(device => {
			let row = {}; row.first = device.name; row.second = getPolicyNames(device.policies); row.third = getRoleNames(device.roles);
			row.fourth = getDgNames(device.deviceGroups); row.fifth = getUserNames(getUserIdsFromRoleIds(device.roles)); row.action = { id: device.hashedMacAdd, name: device.name, entity: device };
			row.meta = JSON.stringify(device);
			return row;
		});
		if (typeof firstId !== 'undefined') {
			const first = result.filter(item => item.action.entity.hashedMacAdd === firstId)[0];
			result = result.filter(item => item.action.entity.hashedMacAdd !== firstId);
			result.unshift(first);
		}
		return result;
	}

	const indexToName = ['first', 'second', 'third', 'fourth', 'fifth'];
	const showByToLabels = { policies: policy, roles: role, devicegroups: deviceGroup, users: user, shields: device };
	const [labels, setLabels] = useState([policy, role, deviceGroup, user, device]);
	const [rows, setRows] = useState([]);
	const [detailsLabels, setDetailsLabels] = useState([]);
	const [detailsRows, setDetailsRows] = useState([]);
	const [showList, setShowList] = useState(false);
	const [showMetaData, setShowMetaData] = useState(false);
	const [showUserMetaData, setShowUserMetaData] = useState(false);
	const [showDeviceMetaData, setShowDeviceMetaData] = useState(false);
	const [actions, setActions] = useState([policyActions, roleActions, deviceGroupActions, userActions, deviceActions]);
	const [deleteModalData, setDeleteModalData] = useState({
		display: false,
		id: null,
		name: null
	});
	const [resetModalData, setResetModalData] = useState({
		display: false,
		email: null,
		fullName: null,
		userId: null
	});
	const [successModalData, setSuccessModalData] = useState({
		display: false,
		fullName: null,
		email: null
	});

	const onClickHeader = (index) => {
		if (index < 5) {
			history.push(actions[index].browse);
		}
	}

	const showByEntity = (type, firstId) => {
		if (type === policy) {
			setLabels([policy, role, deviceGroup, user, device]);
			const newActions = [policyActions, roleActions, deviceGroupActions, userActions, deviceActions];
			setActions(newActions);
			dispatch({ type: '', items: newActions });
			setRows(getByPolicy(firstId));
		}
		else if (type === role) {
			setLabels([role, user, policy, deviceGroup, device]);
			const newActions = [roleActions, userActions, policyActions, deviceGroupActions, deviceActions];
			setActions(newActions);
			dispatch({ type: '', items: newActions });
			setRows(getByRole(firstId));
		}
		else if (type === deviceGroup) {
			setLabels([deviceGroup, device, policy, role, user]);
			const newActions = [deviceGroupActions, deviceActions, policyActions, roleActions, userActions];
			setActions(newActions);
			dispatch({ type: '', items: newActions });
			setRows(getByDeviceGroup(firstId));
		}
		else if (type === user) {
			setLabels([user, policy, role, deviceGroup, device]);
			const newActions = [userActions, policyActions, roleActions, deviceGroupActions, deviceActions];
			setActions(newActions);
			dispatch({ type: '', items: newActions });
			setRows(getByUser(firstId));
		}
		else if (type === device) {
			setLabels([device, policy, role, deviceGroup, user]);
			const newActions = [deviceActions, policyActions, roleActions, deviceGroupActions, userActions];
			setActions(newActions);
			dispatch({ type: '', items: newActions });
			setRows(getByDevice(firstId));
		}
	}
	
	const innerShowDetails = (value, indexCell) => {
		setShowList(false); setShowUserMetaData(false); setShowDeviceMetaData(false); setShowMetaData(false);
		if (indexCell === 5) {
			setDetailsRows([]);
			return;
		}
		if (indexCell > 0) {
			setDetailsLabels([labels[indexCell]]);
			setDetailsRows([value[indexToName[indexCell]]]);
			setShowList(true);
		}
		else {
			setDetailsRows([value]);
			if (labels[0] === user) {
				setShowUserMetaData(true)
			}
			else if (labels[0] === device) {
				setShowDeviceMetaData(true)
			}
			else {
				setShowMetaData(true);
			}
		}

	}

	const showDetails = (row, indexCell) => {
		if (row && row.values) {
			innerShowDetails(row.values)
		}
	}

	const onClickDetails = (row, indexCell) => {
		showByEntity(detailsLabels[0], row.id);
	}

	const ellipsisText = (infos) => {
		let fullText = "";
		if (typeof infos !== 'undefined') {
			fullText = infos.map(info => info.name).join(", ");
		}
		/*if (fullText.length < 47)
			return fullText;
		else
			return fullText.substring(0, 47) + '...';*/
		return fullText;
	}


	const buildActions = (value, isUser) => (
		<ButtonToolbar>
			<ButtonGroup className="btn-group--icons" dir="ltr">
				<Button id="edit" outline onClick={() => { history.push(actions[0].create + '?id=' + value.id) }}><span className="lnr lnr-pencil" /></Button>
				<Button id="delete" outline onClick={() => setDeleteModalData({
					display: true,
					id: value.id,
					name: value.name,
				})}><span className="lnr lnr-trash" /></Button>
				{isUser && <Button id="reset" outline onClick={() => setResetModalData({
					display: true,
					email: value.entity.email,
					fullName: value.entity.fullName,
					userId: value.id
				})}><span className="lnr lnr-lock" /></Button>}
				<UncontrolledTooltip placement="bottom" target="edit">{t('table.edit')}</UncontrolledTooltip>
				<UncontrolledTooltip placement="bottom" target="delete">{t('table.delete')}</UncontrolledTooltip>
				{isUser && <UncontrolledTooltip placement="bottom" target="reset">{t('table.reset-password-btn')}</UncontrolledTooltip>}
			</ButtonGroup>
		</ButtonToolbar>)

	const deleteConfirmed = (e) => {
		setDeleteModalData({ display: false })
		sendRequest('DELETE', `/customers-api/${actions[0].delete}/${deleteModalData.id}`, null, (result) => {
			window.location.reload()
		})
	}

	const resetPasswordUserConfirmed = (e, email, fullName) => {
		setResetModalData({ display: false })
		sendRequest('PUT', `/customers-api/users/${resetModalData.userId}/password/reset`, { portalUrl: window.location.hostname }, (result) => {
			setSuccessModalData({
				display: true,
				email: email,
				fullName: fullName
			})
		})
	}

	const columns = useMemo(
		() => [
			{
				Header: () => (labels[0]),
				accessor: 'first',
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (labels[1]),
				id: 'second',
				accessor: (originalRow) => (ellipsisText(originalRow.second)),
				disableSortBy: true,
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (labels[2]),
				id: 'third',
				accessor: (originalRow) => (ellipsisText(originalRow.third)),
				disableSortBy: true,
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (labels[3]),
				id: 'fourth',
				accessor: (originalRow) => (ellipsisText(originalRow.fourth)),
				disableSortBy: true,
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (labels[4]),
				id: 'fifth',
				accessor: (originalRow) => (ellipsisText(originalRow.fifth)),
				disableSortBy: true,
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (t("table.action")),
				accessor: 'action',
				disableSortBy: true,
				Cell: ({ value }) => (buildActions(value, labels[0] === user)),
			},
			{
				Header: () => (t("table.action")),
				accessor: 'meta',
				disableSortBy: true,				
			}
		],
		[labels],
	);

	const columnsForList = useMemo(
		() => [
			{
				Header: () => (detailsLabels[0]),
				accessor: 'name',
			},
		],
		[detailsLabels, labels],
	);

	const columnsForMetaData = useMemo(
		() => [
			{
				Header: () => (t('label.name')),
				accessor: 'action.entity.name',
			},
			{
				Header: () => (t('label.description')),
				accessor: 'action.entity.description',
			},
			{
				Header: () => (t("table.action")),
				accessor: 'action',
				disableSortBy: true,
				Cell: ({ value }) => (buildActions(value)),
			}
		],
		[actions],
	);

	const columnsForUserMetaData = useMemo(
		() => [
			{
				Header: () => (t('table.user-full-name')),
				accessor: 'action.entity.fullName',
			},
			{
				Header: () => (t('table.user-email')),
				accessor: 'action.entity.email',
			},
			{
				Header: () => (t("table.action")),
				accessor: 'action',
				disableSortBy: true,
				Cell: ({ value }) => (buildActions(value, true)),
			}
		],
		[actions],
	);

	const columnsForDeviceMetaData = useMemo(
		() => [
			{
				Header: () => (t('table.shield-device-name')),
				accessor: 'action.entity.name',
			},
			{
				Header: () => (t('table.shield-protected-ip-device')),
				accessor: 'action.entity.ip',
			},
			{
				Header: () => (t('table.shield-ip')),
				accessor: 'action.entity.shieldExtIp',
			},
			{
				Header: () => (t("table.action")),
				accessor: 'action',
				disableSortBy: true,
				Cell: ({ value }) => (buildActions(value)),
			}
		],
		[actions],
	);

	useEffect(() => {
		const type = showBy.substring(1);
		showByEntity(showByToLabels[type]);
	}, [showBy])

	useEffect(() => {
		if (rows.length > 0) {
			innerShowDetails(rows[0], 0);
		}
	}, [rows])

	let actualTableConfig = { ...tableConfig, onClickHeaderHandler: onClickHeader, onClickCellHandler: showDetails, hiddenColumns: ['meta'] };
	let actualDetailsTableConfig = { ...tableConfig, onClickCellHandler: onClickDetails };
	return (
		<>

			<Card style={{ border: '2px solid white' }}>
				<CardBody>
					<div className="card__title">
						<h5 className="bold-text">{labels[0]}</h5>
					</div>
					<ReactTableBase
						columns={columns}
						data={rows}
						tableConfig={actualTableConfig}
					/>
				</CardBody>
			</Card>
			<br /><br />
			<Card style={{ border: '2px solid white' }}>
				<CardBody>
					<div className="card__title">
						<h5 className="bold-text">{t('label.details')}</h5>
					</div>
					{showList && detailsRows.length > 0 &&
						<ReactTableBase
							columns={columnsForList}
							data={detailsRows}
							tableConfig={actualDetailsTableConfig}
						/>
					}
					{showMetaData && detailsRows.length > 0 &&
						<ReactTableBase
							columns={columnsForMetaData}
							data={detailsRows}
							tableConfig={tableConfig}
						/>
					}
					{showUserMetaData && detailsRows.length > 0 &&
						<ReactTableBase
							columns={columnsForUserMetaData}
							data={detailsRows}
							tableConfig={tableConfig}
						/>
					}
					{showDeviceMetaData && detailsRows.length > 0 &&
						<ReactTableBase
							columns={columnsForDeviceMetaData}
							data={detailsRows}
							tableConfig={tableConfig}
						/>
					}
				</CardBody>
			</Card>
			<Modal show={deleteModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("table.delete")}</h5>
				</Modal.Header>
				<Modal.Body>
					<p>{t("sentences.element-will-be-deleted")}: {deleteModalData.name}</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-danger" onClick={deleteConfirmed}>{t("btn.confirm-delete-user")}</button>
					<button type="button" className="btn btn-secondary" onClick={() => setDeleteModalData({ display: false })} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			<Modal show={resetModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("title.modal-reset-user-title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<p>{t("sentences.password-will-be-reset", { fullName: resetModalData.fullName, email: resetModalData.email })}</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-warning" onClick={(e) => { resetPasswordUserConfirmed(e, resetModalData.email, resetModalData.fullName) }}>{t("btn.confirm-reset-password")}</button>
					<button type="button" className="btn btn-secondary" onClick={() => setResetModalData({ display: false })} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			<Modal show={successModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("title.modal-success-reset-password-user-title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<p>{t("sentences.password-successfuly-reset", { fullName: successModalData.fullName, email: successModalData.email })}</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-secondary" onClick={() => window.location.reload()} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</>
	)
}

export default withRouter(connect(state => ({
	createActions: state.createActions,
}))(NavigationListTable));
