import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useDataApi from '../../hooks/useDataApi'
import Alert from 'react-bootstrap/Alert'
import {
	Colors, printDate, tableConfig, MaxShieldStatusAge,
	detailsTableConfig, deviceActions, computeDateLimits, filterPassedTime
} from '../../configs/Configs'
import { useHistory } from "react-router-dom";
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { Card, CardBody, Button, ButtonGroup, ButtonToolbar, UncontrolledTooltip } from 'reactstrap';
import SshIcon from 'mdi-react/SshIcon';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import ShieldIcons from '../others/ShieldIcons';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import moment from 'moment';

function ShieldStatusListTable({ devices, users }) {
	const { t } = useTranslation();
	const history = useHistory();
	const [shieldsStatus, setShieldsStatus] = useState([]);
	const [devicesStatus, setDevicesStatus] = useState([]);
	const [requestState, sendRequest] = useDataApi();
	const [shieldActionModalData, setShieldActionModalData] = useState({
		display: false,
		shield: null,
		extId: null,
		action: "reset",
		clock: false
	})
	const [actionTimeOption, setActionTimeOption] = useState('now')
	const [actionTime, setActionTime] = useState(new Date())
	const [displayHistory, setDisplayHistory] = useState(false);
	const [dataHistory, setDataHistory] = useState([]);
	const [rowIndexHistory, setRowIndexHistory] = useState(-1);
	const [deviceNameHistory, setDeviceNameHistory] = useState("");
	const [firstCall, setFirstCall] = useState(true);

	const computeHistoricDisplay = row => {
		if (row.index === rowIndexHistory) {
			setDisplayHistory(!displayHistory);
		} else {
			setRowIndexHistory(row.index);
			setDataHistory(devicesStatus[row.original.shield.extId]);
			setDeviceNameHistory(row.original.shield.name);
			setDisplayHistory(true);
		}
	}

	const onActionTimeChange = (e) => {
		setActionTimeOption(e.target.value);
	};

	const oppositeAction = {
		enforced: "bypass",
		bypass: "enforcePolicy"
	}

	const formatDateForJson = function(key, value) {
		if (this[key] instanceof Date) {
			return moment.utc(this[key]).format('YYYY-MM-DD HH:mm:ss');
		}
		return value;
	}

	const actionShieldConfirmed = (e) => {
		let actions = {};
		if (shieldActionModalData.shield.actions) {
			actions = JSON.parse(shieldActionModalData.shield.actions);
		}
		if (shieldActionModalData.action === "reset") {
			var { reset, ...newActions } = actions;
			if (!shieldActionModalData.clock) {
				newActions.reset = (actionTimeOption === "now" ? "now" : actionTime);
			}
			shieldActionModalData.shield.actions = JSON.stringify(newActions, formatDateForJson);
		}
		else {
			var { bypass, enforcePolicy, ...newActions } = actions;
			if (!shieldActionModalData.clock) {
				newActions[shieldActionModalData.action] = (actionTimeOption === "now" ? "now" : actionTime);
			}
			shieldActionModalData.shield.actions = JSON.stringify(newActions, formatDateForJson);
		}
		const apiRequest = `/customers-api/shields/${shieldActionModalData.extId}/info`;
		sendRequest('PUT', apiRequest, shieldActionModalData.shield, (response) => {
			setShieldActionModalData({ display: false });
		});
	}

	const shouldDisplayClock = (type, actions) => {
		let objActions = {};
		if (actions) {
			objActions = JSON.parse(actions);
		}
		return (objActions[type] ? true : false)
	}

	const buildTooltip = (type, actions) => {
		let objActions = {};
		if (actions) {
			objActions = JSON.parse(actions);
		}
		return (objActions[type] ? t("label." + type + "_prog", { date: (objActions[type] === "now" ? t("words.now") : printDate(objActions[type])) }) : t("label." + type))
	}

	const getUserName = (email) => {
		const found = users.filter(user => user.email === email);
		if (found.length > 0)
			return found[0].fullName;
		else
			return email;
	}

	const buildConnectionsTooltip = (activeConn) => {
		let temp = {};
		activeConn.forEach(function(conn) {
			if (temp[conn.userId]) {
				temp[conn.userId]++
			} else {
				temp[conn.userId] = 1;
			}
		})
		var tooltip = "";
		Object.keys(temp).forEach(function(user) {
			const fullName = getUserName(user);
			tooltip += fullName + (temp[user] > 1 ? "(" + fullName + ")" : "") + "\n";
		});
		return tooltip;
	}

	const buildSynchLabel = (backendDate, shieldDate) => {
		const ISOBackendDate = moment.utc(backendDate, 'YYYY-MM-DD HH:mm:ss').toISOString();
		if (shieldDate && (new Date(shieldDate) - new Date(ISOBackendDate) > -10000))
			return t("label.synch");
		else
			return t("label.outOfSynch");
	}

	const offlineShieldStatus = {
		activeConnectionsNum: t('words.no-connections'),
		activeConnections: [],
		deviceStatus: t('words.no-info'),
		deviceStatusSince: '',
		shieldStatus: t('words.offline')
	}


	const getShieldStatusColor = (shieldStatus) => {
		if (!shieldStatus) {
			return "";
		}
		var status = shieldStatus.toLowerCase();
		if (status === t('words.offline').toLowerCase() || status === t('words.no-info').toLowerCase() || status === t('words.status-absent').toLowerCase()) {
			return Colors.red
		} else if (status === t('words.status-ready').toLowerCase()) {
			return Colors.green
		} else {
			return "";
		}
	}
	const getDeviceStatusColor = (deviceStatus) => {
		if (!deviceStatus) {
			return "";
		}
		var status = deviceStatus.toLowerCase();
		if (status === t('words.offline').toLowerCase() || status === t('words.no-info').toLowerCase() || status === t('words.status-absent').toLowerCase()) {
			return Colors.red
		} else if (status === t('words.status-present').toLowerCase()) {
			return Colors.green
		} else {
			return "";
		}
	}

	const deviceStatusHistoryColumns = useMemo(
		() => [
			{
				// Make an expander cell
				Header: () => null, // No header
				id: 'expander', // It needs an ID
				Cell: ({ row }) => (row.original.activeConnections.length > 0 ? (
					// Use Cell to render an expander for each row.
					// We can use the getToggleRowExpandedProps prop-getter
					// to build the expander.
					<span {...row.getToggleRowExpandedProps()}>
						{row.isExpanded ? '👇' : '👉'}
					</span>
				) : null)
			},
			{
				Header: () => (t('words.shield-status')),
				accessor: 'shieldStatus',
				Cell: ({ value }) => (<p style={{ color: getShieldStatusColor(value) }}>{value}</p>)
			},
			{
				Header: () => (t('words.device-status')),
				accessor: 'deviceStatus',
				Cell: ({ value }) => (<p style={{ color: getDeviceStatusColor(value) }}>{value}</p>)
			},
			{
				Header: () => (t('words.active-connections')),
				accessor: 'activeConnections',
				disableSortBy: true,
				Cell: ({ value, row }) =>
					(<p>{value && value.length > 0 ? t("sentences.view-connections", { count: value.length }) : t('words.no-connections')}</p>)
			},
			{
				Header: () => (t('label.device-status-from')),
				accessor: 'from',
			},
			{
				Header: () => (t('label.device-status-to')),
				accessor: 'to',
			}
		],
		[],
	);

	const columns = useMemo(
		() => [
			{
				// Make an expander cell
				Header: () => null, // No header
				id: 'expander', // It needs an ID
				Cell: ({ row }) => (row.original.activeConnections.length > 0 ? (
					// Use Cell to render an expander for each row.
					// We can use the getToggleRowExpandedProps prop-getter
					// to build the expander.
					<span {...row.getToggleRowExpandedProps()}>
						{row.isExpanded ? '👇' : '👉'}
					</span>
				) : null)
			},
			{
				Header: () => (t('words.device-name')),
				accessor: (originalRow, rowIndex) => originalRow.shield.name,
				id: 'name',
			},
			{
				Header: () => (t('table.shield-version')),
				accessor: (originalRow, rowIndex) => originalRow,
				id: 'version',
				Cell: ({ value }) => (value ? value['version'] : '-')
			},
			{
				Header: () => (t('table.shield-ip')),
				accessor: (originalRow, rowIndex) => originalRow.shield.shieldExtIp,
				id: 'shieldExtIp',
			},
			{
				Header: () => (t('words.shield-status')),
				accessor: (originalRow, rowIndex) => originalRow,
				id: 'shield-status',
				disableSortBy: true,
				Cell: ({ value }) => (value.shield.regStatus !== 'active'
					? <p style={{ color: Colors.red }}>{t("label.not-active-shield")}</p>
					:
					<><p style={{ color: getShieldStatusColor(value['shieldStatus']) }}><b>{value ? value['shieldStatus'] + " - " + buildSynchLabel(value.shield.lastUpdatedPolicy, value.lastUpdatedPolicy) : '-'}</b></p>
						<small>{value ? value['shieldStatusSince'] : '-'}</small></>
				),
			},
			{
				Header: () => (t('words.active-connections')),
				accessor: (originalRow, rowIndex) => originalRow,
				id: 'active',
				disableSortBy: true,
				Cell: ({ value, row }) =>
					(<><p id={value.shield.extId + "_conn"}>{value && value.activeConnections && value.activeConnections.length > 0 ? t("sentences.view-connections", { count: value['activeConnectionsNum'] }) : t('words.no-connections')}</p>
						{value && value.activeConnections && value.activeConnections.length > 0 && <UncontrolledTooltip placement="bottom" target={value.shield.extId + "_conn"}>{buildConnectionsTooltip(value.activeConnections)}</UncontrolledTooltip>}</>
					)
			},
			{
				Header: () => (t('words.shield-actions')),
				accessor: (originalRow, rowIndex) => originalRow,
				id: 'shield-actions',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<ButtonToolbar>
						<ButtonGroup className="btn-group--icons" dir="ltr">
							<Button id={"reset_" + value.shield.extId} outline onClick={() => { setShieldActionModalData({ display: true, extId: value.shield.extId, shield: value.shield, action: "reset", clock: value.resetClock }) }}><ShieldIcons type="reset" clock={value.resetClock} /></Button>
							<Button id={"bypass_" + value.shield.extId} outline onClick={() => { setShieldActionModalData({ display: true, extId: value.shield.extId, shield: value.shield, action: oppositeAction[value.shieldMode], clock: value.actionClock }) }}><ShieldIcons type={value.shieldMode} clock={value.actionClock} /></Button>
							<UncontrolledTooltip placement="bottom" target={"reset_" + value.shield.extId}>{buildTooltip("reset", value.shield.actions)}</UncontrolledTooltip>
							<UncontrolledTooltip placement="bottom" target={"bypass_" + value.shield.extId}>{buildTooltip(oppositeAction[value.shieldMode], value.shield.actions)}</UncontrolledTooltip>
						</ButtonGroup>
					</ButtonToolbar>)
			},
			{
				Header: () => (t('words.ip')),
				accessor: (originalRow, rowIndex) => originalRow.shield.ip,
				id: 'ip',
			},
			{
				Header: () => (t('words.device-status')),
				accessor: (originalRow, rowIndex) => originalRow,
				id: 'device-status',
				disableSortBy: true,
				Cell: ({ value }) => <><p style={{ color: getDeviceStatusColor(value['deviceStatus']) }}><b>{value ? value['deviceStatus'] : '-'}</b></p>
					<small>{value ? value['deviceStatusSince'] : '-'}</small></>

			},
			{
				Header: () => (t('label.action')),
				accessor: (originalRow, rowIndex) => originalRow,
				id: 'details',
				disableSortBy: true,
				Cell: ({ value, row }) =>
					(<ButtonToolbar>
						<ButtonGroup className="btn-group--icons" dir="ltr">
							<Button id="history" outline onClick={() => {
								computeHistoricDisplay(row); setTimeout(() => {
									window.scrollTo(0, document.body.scrollHeight);
								}, 100);
							}}><span className="lnr lnr-history" /></Button>
							<Button id="edit" outline onClick={() => { history.push(deviceActions.create + '?id=' + value.shield.hashedMacAdd) }}><span className="lnr lnr-pencil" /></Button>
							<Button id="ssh" outline onClick={() => { window.location.href = "thinguard://ez7khost:" + value.shield.ip + "/ez7kuser:" + value.shield.sshUser + "/ez7kport:" + value.shield.sshPort }}><SshIcon /></Button>
							<Button id="ping" outline onClick={() => { window.location.href = "thinguard://ez7khost:" + value.shield.ip }}><span className="lnr lnr-heart-pulse" /></Button>
							<UncontrolledTooltip placement="bottom" target="history">{t('label.history')}</UncontrolledTooltip>
							<UncontrolledTooltip placement="bottom" target="edit">{t('table.edit')}</UncontrolledTooltip>
							<UncontrolledTooltip placement="bottom" target="ssh">{t('tooltip.ssh')}</UncontrolledTooltip>
							<UncontrolledTooltip placement="bottom" target="ping">{t('label.ping-command')}</UncontrolledTooltip>
						</ButtonGroup>
					</ButtonToolbar>)
			}
		],
		[devicesStatus, displayHistory, rowIndexHistory],
	);

	const columnsConnection = useMemo(
		() => [
			{
				Header: () => (t('table.user-name')),
				accessor: (originalRow, rowIndex) => getUserName(originalRow.userId),
				id: 'userName',
			},
			{
				Header: () => (t('label.ip')),
				accessor: 'agentIp',
			}
		],
		[],
	);

	const renderConnectionsSubComponent = (row) =>
		(
			<ReactTableBase
				columns={columnsConnection}
				data={row.original.activeConnections}
				tableConfig={detailsTableConfig} />
		)

	tableConfig.placeholder = t('label.search');
	const shieldStatusConfig = { ...tableConfig, renderRowSubComponent: renderConnectionsSubComponent };

	const getShieldsStatus = () => {
		// Make request to get the latest shield status log
		sendRequest('POST', `/customers-api/monitoring/shield_status`, { isRefresh: !firstCall }
			, (result) => {
				console.log(result);
				let recentStatus = [];
				devices.forEach(function(shield, index) {
					if (result && result.hits && result.hits.total.value) {
						let entry = result.hits.hits.filter(hit => shield.extId === hit._source.senderId);
						if (entry.length > 0) {
							let shieldLog = entry[0]._source;
							let shieldMode = "enforced"; //shieldLog['additionalInformation']['shieldMode'];
							let status;

							if (new Date() - new Date(shieldLog["@timestamp"]) > MaxShieldStatusAge) {
								status = {
									...offlineShieldStatus,
									version: shieldLog['additionalInformation']['system_version'] + '/' + shieldLog['additionalInformation']['component_version'],
									shieldStatusSince: t('words.offline') + ' ' + t('words.since') + ' ' + printDate(shieldLog["@timestamp"]),
									shieldMode: shieldMode,
									actionClock: shouldDisplayClock(oppositeAction[shieldMode], shield.actions),
									resetClock: shouldDisplayClock("reset", shield.actions),
									shield: shield,
									activeConnections: []
								}
							} else {
								status = {
									version: shieldLog['additionalInformation']['system_version'] + '/' + shieldLog['additionalInformation']['component_version'],
									activeConnectionsNum: shieldLog['additionalInformation']["usersList"].length ? shieldLog['additionalInformation']["usersList"].length : t('words.no-connections'),
									activeConnections: shieldLog['additionalInformation']["usersList"],
									deviceStatus: t(`words.status-${shieldLog['additionalInformation']["deviceStatus"]}`),
									deviceStatusSince: t('words.since') + ' ' + printDate(shieldLog['additionalInformation']['deviceStatusSince']),
									shieldStatus: t(`words.status-${shieldLog['additionalInformation']['status']}`),
									shieldStatusSince: t(`words.status-${shieldLog['additionalInformation']['status']}`) + ' ' + t('words.since') + ' ' + printDate(shieldLog['additionalInformation']['statusSince']),
									shieldMode: shieldMode,
									actionClock: shouldDisplayClock(oppositeAction[shieldMode], shield.actions),
									resetClock: shouldDisplayClock("reset", shield.actions),
									shield: shield,
									lastUpdatedPolicy: shieldLog['additionalInformation']['lastUpdatedPolicy']
								}
							}
							recentStatus[index] = status;
						}
					}
				})

				setShieldsStatus(recentStatus)
			});

		sendRequest('POST', `/customers-api/monitoring/device_status`, { isRefresh: !firstCall }, (result) => {
			console.log(result);
			if (result && result.hits && result.hits.total.value) {
				let updatedDevicesStatusList = [...devicesStatus];
				devices.forEach(function(shield) {
					let entry = result.hits.hits.filter(hit => shield.extId === hit._source.senderId);
					if (entry.length > 0) {
						let updatedDeviceStatus = [];
						let senderId = shield.extId;
						for (var i in entry) {
							let deviceStatusInfo = entry[i]._source;
							updatedDeviceStatus.push({
								shieldStatus: t(`words.status-${deviceStatusInfo['additionalInformation']['status']}`),
								deviceStatus: t(`words.status-${deviceStatusInfo.deviceStatus}`),
								from: printDate(deviceStatusInfo.statusStart),
								to: (i === "0" ? t("words.now") : printDate(deviceStatusInfo.statusEnd)),
								name: shield.name,
								activeConnectionsNum: deviceStatusInfo['additionalInformation']["usersList"].length ? deviceStatusInfo['additionalInformation']["usersList"].length : t('words.no-connections'),
								activeConnections: deviceStatusInfo['additionalInformation']["usersList"],
							})
						}
						updatedDevicesStatusList[senderId] = updatedDeviceStatus;
					}
				})
				setDevicesStatus(updatedDevicesStatusList)
			}
		});
	}

	useEffect(() => {
		getShieldsStatus();
		const intervalId = setInterval(() => {
			setFirstCall(false);
			getShieldsStatus();
		}, 15000);
		return (() => {
			clearInterval(intervalId);
		});
	}, [devices])

	return (
		<>
			<div>
				<div className="card__title">
					<h5 className="bold-text">{t("navbar.shields-status")}</h5>
				</div>
				<ReactTableBase
					columns={columns}
					data={shieldsStatus}
					tableConfig={shieldStatusConfig}
				/>
			</div>
			<br /><br />
			<Card style={{ border: '2px solid white' }}>
				{displayHistory && dataHistory && dataHistory.length > 0 &&
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">{t("label.device-status-history", { name: deviceNameHistory })}</h5>
						</div>
						<ReactTableBase
							columns={deviceStatusHistoryColumns}
							data={dataHistory}
							tableConfig={shieldStatusConfig}
						/>
					</CardBody>
				}
			</Card>
			{shieldActionModalData.action && <Modal show={shieldActionModalData.display}>
				<form onSubmit={actionShieldConfirmed}>
					<Modal.Header>
						{!shieldActionModalData.clock ? <h5 className="modal-title">{t(`title.modal-${shieldActionModalData.action}-shield`, { shield: shieldActionModalData.extId })}</h5> :
							<h5 className="modal-title">{t(`title.modal-${shieldActionModalData.action}-cancel-shield`, { shield: shieldActionModalData.extId })}</h5>}
					</Modal.Header>
					<Modal.Body>
						{!shieldActionModalData.clock &&
							<div>
								<RadioGroup
									name="controlled-radio-buttons-group"
								>
									<FormControlLabel control={<Radio value="now" color="primary" checked={actionTimeOption === 'now'} onChange={onActionTimeChange} />} label={t("label.now")} />
									<FormControlLabel control={<Radio value="custom" color="primary" checked={actionTimeOption === 'custom'} disabled="true" onChange={onActionTimeChange} />} label={t("label.custom")} />
								</RadioGroup>
								{actionTimeOption === 'custom' &&
									<div className="date-picker">
										<DatePicker
											timeFormat="HH:mm"
											className="form__form-group-datepicker"
											selected={actionTime}
											onChange={(date) => setActionTime(date)}
											minDate={computeDateLimits().min}
											maxDate={computeDateLimits().max}
											filterTime={filterPassedTime}
											showTimeSelect
											dateFormat="MMMM d, yyyy h:mm aa"
											dropDownMode="select"
										/>
									</div>
								}
							</div>}
					</Modal.Body>
					<Modal.Footer>
						<button type="submit" className="btn btn-warning">{t("btn.modal-ok")}</button>
						<button type="button" className="btn btn-secondary" onClick={() => setShieldActionModalData({ display: false })} >{t("btn.modal-close")}</button>
					</Modal.Footer>
				</form>
			</Modal>}

			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</>
	)
}

export default ShieldStatusListTable

