import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useDataApi from '../../hooks/useDataApi'
import { Formik, Form } from "formik";
import Alert from 'react-bootstrap/Alert';
import validate from '../../hooks/useValidation';
import Loading from '../others/Loading';
import { MySelect } from '../others/FormElements';
import { Card, CardBody, Button, ButtonGroup, ButtonToolbar, UncontrolledTooltip } from 'reactstrap';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import ReactTableCell from '../../shared/components/table/components/ReactTableCell';
import { detailsTableConfig } from '../../configs/Configs';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import Modal from 'react-bootstrap/Modal';

function CreatePolicyForm({ roles, devicegroups, policies, policy }) {

	const getListPolicies = () => {
		if (policy) {
			policy.role = roles.filter(role => role.roleId === policy.roleId)[0].name;
			policy.deviceGroup = devicegroups.filter(dg => dg.deviceGroupId === policy.deviceGroupId)[0].name;
			return [policy];
		}
		
		policies.forEach(function(policy) {
			policy.role = roles.filter(role => role.roleId === policy.roleId)[0].name;
			policy.deviceGroup = devicegroups.filter(dg => dg.deviceGroupId === policy.deviceGroupId)[0].name;
		});
		
		return policies;		
	}

	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [createdPolicies, setCreatedPolicies] = useState(() => getListPolicies());
	const [deleteModalData, setDeleteModalData] = useState({
		display: false,
		id: null,
		name: null
	});
	const [policyName, setPolicyName] = useState("");

	const getRoleOption = (role) => {
		if (role.roleId) {
			let option = {};
			option.value = role.roleId;
			option.label = role.name;
			return option;
		} else {
			return null
		}
	}

	const getDeviceGroupOption = (dg) => {
		if (dg.deviceGroupId) {
			let option = {};
			option.value = dg.deviceGroupId;
			option.label = dg.name;
			return option;
		} else {
			return null
		}
	}

	const roleOptions = roles.map((role) => { return getRoleOption(role) });
	const dgOptions = devicegroups.map((dg) => { return getDeviceGroupOption(dg) })

	const policiesColumns = useMemo(
		() => [
			{
				Header: () => (t('label.name')),
				accessor: 'name',
			},
			{
				Header: () => (t('label.roleId')),
				accessor: 'role',
				Cell: ReactTableCell,
			},
			{
				Header: () => (t('label.deviceGroupId')),
				accessor: 'deviceGroup',
				Cell: ReactTableCell,
			},
			{
				Header: () => (t('label.action')),
				accessor: (originalRow, rowIndex) => originalRow,
				id: 'details',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<ButtonToolbar>
						<ButtonGroup className="btn-group--icons" dir="ltr">
							<Button id="delete" outline onClick={() => setDeleteModalData({
								display: true,
								id: value.policyId,
								name: value.name,
							})}><span className="lnr lnr-trash" /></Button>
							<UncontrolledTooltip placement="bottom" target="delete">{t('table.delete')}</UncontrolledTooltip>
						</ButtonGroup>
					</ButtonToolbar>)
			}
		],
		[],
	);

	const updateEditableData = (rowIndex, columnId, value) => {
		setCreatedPolicies(old => old.map((item, index) => {
			if (index === rowIndex) {
				return {
					...old[rowIndex],
					[columnId]: value,
				};
			}
			return item;
		}));
		const apiRequest = `/customers-api/policies/${createdPolicies[rowIndex].policyId}`;
		var newPolicy = { ...createdPolicies[rowIndex] };
		newPolicy.name = value;
		sendRequest('PUT', apiRequest, newPolicy);
	};

	const myConfig = { ...detailsTableConfig, isEditable: true, isResizable: false };

	const deleteConfirmed = (e) => {
		setDeleteModalData({ display: false })
		sendRequest('DELETE', `/customers-api/policies/${deleteModalData.id}`, null, (result) => {
			let updatedPolicies = [...createdPolicies];
			updatedPolicies = updatedPolicies.filter(policy => deleteModalData.id !== policy.policyId);
			setCreatedPolicies(updatedPolicies);
		})
	}

	const updatePolicyName = (value, isRole) => {
		var rolePart = "", dgPart = "";
		const parts = policyName.split("__");
		if (parts.length == 2) {
			rolePart = parts[0];
			dgPart = parts[1];
		}
		setPolicyName(isRole ? value.substring(0, 20) + "__" + dgPart : rolePart + "__" + value.substring(0, 20));
	}

	return (
		<>
			{!policy &&
				<Formik
					initialValues={policy ?? {
						roleId: "",
						deviceGroupId: ""
					}}
					validateOnChange={true}
					validateOnMount={true}
					validate={validate}
					onSubmit={(data, { setSubmitting }) => {
						setSubmitting(true);
						const roleName = roles.filter(role => role.roleId === data.roleId)[0].name;
						const dgName = devicegroups.filter(dg => dg.deviceGroupId === data.deviceGroupId)[0].name;
						let newPolicy = { ...data, roleName: roleName, dgName: dgName };
						newPolicy.name = policyName;
						const apiRequest = '/customers-api/policies';
						sendRequest('POST', apiRequest, newPolicy, (response) => {
							setSubmitting(false);
							newPolicy.role = roleName;
							newPolicy.deviceGroup = dgName;
							newPolicy.policyId = response;
							let updatedPolicies = [...createdPolicies];
							updatedPolicies.push(newPolicy);
							setCreatedPolicies(updatedPolicies);
						});
						setTimeout(() => {
							setSubmitting(false);
						}, 3000);
					}}
				>
					{({ values, errors, isSubmitting, setFieldValue, setFieldTouched, touched }) => (
						<Form>
							<Card style={{ border: '2px solid white' }}>
								<CardBody>
									<div className="card__title">
										<h5 className="bold-text">{t('title.policy-attributes')}</h5>
									</div>
									<div style={{ display: 'flex' }}>
										<table width="100%">
											<tr>
												<td width="45%" position="relative">
													<MySelect
														value={roleOptions.find(e => e.value === values["roleId"])}
														onChange={(e, value) => { setFieldValue(e, value.value); updatePolicyName(value.label, true) }}
														onBlur={setFieldTouched}
														error={errors["roleId"]}
														touched={touched["roleId"]}
														options={roleOptions}
														name={"roleId"}
													/>
												</td>
												<td width="45%" position="relative">
													<div>
														<MySelect
															value={dgOptions.find(e => e.value === values["deviceGroupId"])}
															onChange={(e, value) => { setFieldValue(e, value.value); updatePolicyName(value.label, false) }}
															onBlur={setFieldTouched}
															error={errors["deviceGroupId"]}
															touched={touched["deviceGroupId"]}
															options={dgOptions}
															name={"deviceGroupId"}
														/>
													</div>
												</td>
												<td width="10%" position="relative">
													<div role="toolbar" class="btn-toolbar">
														<div dir="ltr" role="group" class="btn-group--icons btn-group">
															<button
																title={t('label.add-policy')}
																className="btn btn-primary"
																style={{ position: 'absolute', display: 'flex' }}
																disabled={!values.roleId || !values.deviceGroupId || isSubmitting || Object.keys(errors).length > 0 || 
																			createdPolicies.filter(policy => policy.roleId === values.roleId && policy.deviceGroupId === values.deviceGroupId).length > 0}>
																<PlusCircleOutlineIcon />
															</button>
														</div></div>
												</td>
											</tr>
										</table>
									</div>
								</CardBody>
							</Card>
						</Form>
					)
					}
				</Formik >}
			<br /><br />
			<Card style={{ border: '2px solid white' }}>
				<CardBody>
					<div className="card__title">
						<h5 className="bold-text">{t('title.existing-policies')}</h5>
					</div>
					<ReactTableBase
						columns={policiesColumns}
						data={createdPolicies}
						tableConfig={myConfig}
						updateEditableData={updateEditableData}
					/>
				</CardBody>
			</Card>
			<Modal show={deleteModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("table.delete")}</h5>
				</Modal.Header>
				<Modal.Body>
					<p>{t("sentences.element-will-be-deleted")}: {deleteModalData.name}</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-danger" onClick={deleteConfirmed}>{t("btn.confirm-delete-user")}</button>
					<button type="button" className="btn btn-secondary" onClick={() => setDeleteModalData({ display: false })} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>

			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</>
	)
}

export default CreatePolicyForm
