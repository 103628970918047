import React from 'react'
import { Redirect } from 'react-router-dom';

import CustomerLayout, {CustomerPages} from '../components/layouts/customerLayouts/CustomerLayout';
import IntegratorLayout, {IntegratorPages} from '../components/layouts/integratorLayouts/IntegratorLayout';
import UserLayout, {UserPages} from '../components/layouts/userLayouts/UserLayout';
import AdminLayout, {AdminPages} from '../components/layouts/adminLayouts/AdminLayout';

import NotFoundPage from '../components/layouts/NotFoundPage';
import LoginPage from '../components/layouts/LoginPage';
import TwoFAPage from '../components/layouts/TwoFAPage';

import NewPasswordPage from '../components/layouts/NewPasswordPage';
import ChangePasswordPage from '../components/layouts/ChangePasswordPage';

function useRenders(auth, AuthLogout) {
    const Renders = {
        IntegratorsTablePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.IntegratorsTablePage} />;
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            } 
        },
        CreateIntegratorPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.CreateIntegratorPage} />;
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            } 
        },
        UpdateIntegratorPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.UpdateIntegratorPage} />;
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            } 
        },
        ModuleVersionsPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.ModuleVersionsPage} />;
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            } 
        },
        TranslationsPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.TranslationPage} />;
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            } 
        },
        DashboardPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CustomerDashboardPage} />
                case "INTEGRATOR":
                    return <IntegratorLayout {...props} component={IntegratorPages.IntegratorDashboardPage} />
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.AdminDashboardPage} />
                case "USERTOKEN":
                    return <UserLayout {...props} component={UserPages.UserDashboardPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        MyInfoPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.UpdateCustomerInfoPage} />
                case "INTEGRATOR":
                    return <IntegratorLayout {...props} component={IntegratorPages.UpdateIntegratorInfoPage} />
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.UpdateAdminInfoPage} />
                case "USER":
                    return <UserLayout {...props} component={UserPages.UpdateUserInfoPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        ResetPasswordPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.UpdateCustomerPasswordPage} />
                case "INTEGRATOR":
                    return <IntegratorLayout {...props} component={IntegratorPages.UpdateIntegratorPasswordPage} />
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.UpdateAdminPasswordPage} />
                case "USER":
                    return <UserLayout {...props} component={UserPages.UpdateUserPasswordPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CustomerShieldsTablePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.AdminShieldsTablePage} />;
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CustomerShieldsTablePage} />;
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            } 
        },
        CreateCustomerShieldPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.CreateAdminShieldPage} />;
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CreateCustomerShieldPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        UpdateCustomerShieldPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.UpdateAdminShieldPage} />;
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.UpdateCustomerShieldPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CustomerShieldsStatusTablePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CustomerShieldsStatusTablePage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
         CustomerUsersStatusTablePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CustomerUsersStatusTablePage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CustomerUsersTablePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CustomerUsersTablePage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CreateCustomerRoleTablePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CreateCustomerRoleTablePage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CreateCustomerDeviceGroupTablePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CreateCustomerDeviceGroupTablePage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CreateCustomerPolicyTablePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CreateCustomerPolicyTablePage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CreateCustomerDeviceTypePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CreateCustomerDeviceTypePage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        MapCustomerSubnetTablePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.MapCustomerSubnetTablePage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        UpdateCustomerPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.UpdateCustomerPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CustomerNavigationTablePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CustomerNavigationTablePage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CreateCustomerUserPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CreateCustomerUserPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CreateCustomerOccasUserPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CreateCustomerOccasUserPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        UpdateCustomerPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.UpdateCustomerInfoForm} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CustomerUsersStatusTablePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CustomerUsersStatusTablePage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CustomerMonitoringActivitiesPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CustomerMonitoringActivitiesPage} />
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.AdminMonitoringActivitiesPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CustomerMonitoringAlertsPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CustomerMonitoringAlertsPage} />
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.AdminMonitoringAlertsPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CustomerMonitoringLogsPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={CustomerPages.CustomerMonitoringLogsPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        IntegratorCustomersTablePage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.AdminCustomersTablePage} />;
                case "INTEGRATOR":
                    return <IntegratorLayout {...props} component={IntegratorPages.IntegratorCustomersTablePage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        CreateIntegratorCustomerPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.CreateAdminCustomerPage} />;
                case "INTEGRATOR":
                    return <IntegratorLayout {...props} component={IntegratorPages.CreateIntegratorCustomerPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        UpdateIntegratorCustomerPage(props){
            if(!auth.isLogged){
                return <Redirect to="/login"/>
            }
            switch (auth.userType) {
                case "ADMIN":
                    return <AdminLayout {...props} component={AdminPages.UpdateAdminCustomerPage} />;
                case "INTEGRATOR":
                    return <IntegratorLayout {...props} component={IntegratorPages.UpdateIntegratorCustomerPage} />
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        },
        LoginPage(props){
            if(!auth.isLogged){
                return <LoginPage/>
            } else {
                return <Redirect to="/" />
            } 
        },
        TwoFAPage(props){
            if(!auth.isLogged){
            	var now = new Date();
            	const expiryTimestamp  = now.setSeconds(now.getSeconds() + 300);
                return <TwoFAPage expiryTimestamp ={expiryTimestamp }/>
            } else {
                return <Redirect to="/" />
            } 
        },
        Logout(props){
            AuthLogout();
            return <Redirect to="/"/>
        },
        NotFoundPage(props){
            if(!auth.isLogged){
                return <NotFoundPage/>
            }
            switch (auth.userType) {
                case "CUSTOMER":
                    return <CustomerLayout {...props} component={NotFoundPage} />
                case "INTEGRATOR":
                    return <IntegratorLayout {...props} component={NotFoundPage} />
                case "ADMIN":
                    return <AdminLayout {...props} component={NotFoundPage} />
                case "USER":
                    return <UserLayout {...props} component={NotFoundPage} />
                default:
                    return <Redirect to="/logout"/>
            }
        },
        UndefinedLink(props){
            return <Redirect to={`/notfound?from=${props.match.url}`}/>
        },
        FromMailPage(props){
            /*if(auth.isLogged){
                AuthLogout();
            }*/
            
            const mailType = props.match.params.mailType;
            
            switch(mailType){
                case 'admin-activated':
                case 'admin-reset-password':
                case 'integrator-activated':
                case 'integrator-reset-password':
                case 'customer-activated':
                case 'customer-reset-password':
                case 'user-activated':
                case 'user-reset-password':
                    return <NewPasswordPage {...props} type={mailType}/>
                case 'user-change-password':
                	return <ChangePasswordPage {...props}/>
                default:
                    return <Redirect to={`/notfound?from=${props.match.url}`}/>
            }
        }
    }
    return Renders;
}

export default useRenders
