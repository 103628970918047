import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useDataApi from '../../hooks/useDataApi'
import { Formik, Form } from "formik";
import Alert from 'react-bootstrap/Alert';
import validate from '../../hooks/useValidation';
import Loading from '../others/Loading';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { detailsTableConfig, DefaultColumnFilter, getSubnetMapping, showSuccessMessage } from '../../configs/Configs'
import { MyTextField } from '../others/FormElements';
import { Button, Card, CardBody, UncontrolledTooltip} from 'reactstrap';
import { useHistory } from "react-router-dom";
import PlusIcon from 'mdi-react/PlusIcon';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import Chip from "@material-ui/core/Chip";

function CreateDeviceGroupForm({ devices, deviceGroup, mapping }) {
	
	const buildContent = (device) => {
		return ((device.deviceType ? t('label.deviceType') + ": " + device.deviceType + "\r\n" : "") +
		(device.displaySubnet ? t('label.subnet') + ": " + device.displaySubnet + "\r\n" : "") +
		(device.modelId ? t('label.modelId') + ": " + device.modelId + "\r\n" : "") +
		(device.vendorId ? t('label.vendorId') + ": " + device.vendorId  : ""))
	}

	const getInitOrigData = (devices, deviceGroup) => {
		devices.forEach(function(device) {
			device.displaySubnet = getSubnetMapping(mapping, device.deviceSubnet, device.shieldPublicIp);
		})
		if (deviceGroup && deviceGroup.devices) {
			return devices.filter(device => !deviceGroup.devices.includes(device.hashedMacAdd));
		} else {
			return devices;
		}
	}

	const getInitDestData = (devices, deviceGroup) => {
		if (deviceGroup && deviceGroup.devices) {
			return devices.filter(device => deviceGroup.devices.includes(device.hashedMacAdd));
		} else {
			return [];
		}
	}

	const { t } = useTranslation();
	const history = useHistory();
	const [requestState, sendRequest] = useDataApi();
	const [origData, setOrigData] = useState(() => getInitOrigData(devices, deviceGroup));
	const [destData, setDestData] = useState(() => getInitDestData(devices, deviceGroup));

	const onClickButton = (direction, hashMac, rows) => {
		let copyDataSource = (direction === 0 ? [...origData] : [...destData]);
		let copyDataDest = (direction === 0 ? [...destData] : [...origData]);
		var selectedData;

		if (hashMac !== "all") {
			selectedData = copyDataSource.filter(obj => obj.hashedMacAdd === hashMac);
			copyDataSource = copyDataSource.filter(obj => obj.hashedMacAdd !== hashMac);
		}
		else {
			selectedData = rows.map(row => row.values);
			var selectedHashMac = selectedData.map(data => data.hashedMacAdd);
			copyDataSource = copyDataSource.filter(obj => !selectedHashMac.includes(obj.hashedMacAdd));
		}

		copyDataDest.push.apply(copyDataDest, selectedData);

		if (direction === 0) {
			setOrigData(copyDataSource);
			setDestData(copyDataDest);			
		}
		else {
			setDestData(copyDataSource);
			setOrigData(copyDataDest);			
		}
	};


	const sourceColumns = useMemo(
		() => [
			{
				Header: ({ column: { filteredRows } }) => (<Button size="sm"
					onClick={() => {
						onClickButton(0, "all", filteredRows)
					}}><PlusCircleOutlineIcon />
				</Button>),
				accessor: 'hashedMacAdd',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<Button size="sm"
						onClick={() => {
							onClickButton(0, value)
						}}><PlusIcon />
					</Button>)
			},
			{
				Header: () => (t('table.shield-device-name')),
				accessor: 'name',
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (t('label.deviceType')),
				accessor: 'deviceType',
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (t('label.subnet')),
				accessor: 'displaySubnet',
				Filter: DefaultColumnFilter,				
			},
			{
				Header: () => (t('label.modelId')),
				accessor: 'modelId',
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (t('label.vendorId')),
				accessor: 'vendorId',
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (t('table.shield-protected-ip-device')),
				accessor: 'ip',
			},
			{
				Header: () => (t('table.shield-ip')),
				accessor: 'shieldExtIp',
			}
		],
		[origData, destData],
	);

	return (

		<Formik
			initialValues={deviceGroup ?? {
				name: "",
				description: ""
			}}
			validateOnChange={true}
			validateOnMount={true}
			validate={validate}
			onSubmit={(data, { setSubmitting, resetForm }) => {
				setSubmitting(true);
				let newDeviceGroup = { ...data, devices: destData.map(row => row.hashedMacAdd), devicesNames: destData.map(row => row.name), oldName: (deviceGroup ? deviceGroup.name : data.name) };
				const apiRequest = (deviceGroup ? `/customers-api/devicegroups/${deviceGroup.deviceGroupId}` : '/customers-api/devicegroups');
				sendRequest((deviceGroup ? 'PUT' : 'POST'), apiRequest, newDeviceGroup, (response) => {
					setSubmitting(false);
					if (deviceGroup) {
						history.goBack();
					} else {
						showSuccessMessage(t('label.device-group-created'));
						resetForm();
						setOrigData(devices);
						setDestData([]);
					}
				});
				setTimeout(() => {
					setSubmitting(false);
				}, 3000);
			}}
		>
			{({ values, errors, isSubmitting }) => (
				<Form>
					<div style={{ display: 'flex' }}>
						<div className="form__half">
							<MyTextField name="name" />
						</div>
						<div className="form__half">
							<MyTextField name="description" />
						</div>
					</div>
					<br />
					<Card style={{ border: '2px solid white' }}>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">{t('label.added-devices')}</h5>
							</div>
							
							{destData.map((item, index) => (
								<>
								<Chip									
									id={"device"+index}
									label={item.name}
									clickable
									color="primary"
									onClick={() => null}
									onDelete={() => onClickButton(1, item.hashedMacAdd)}
									style={{
										padding: 16,
										margin: 16
									}}
								/>
								<UncontrolledTooltip placement="bottom" target={"device"+index}>{buildContent(item)}</UncontrolledTooltip>							
								</>
							))}
							<br /><br /><br />						

							<div className="card__title">
								<h5 className="bold-text">{t('label.avail-devices')}</h5>
							</div>
							<ReactTableBase
								columns={sourceColumns}
								data={origData}
								tableConfig={detailsTableConfig}
							/>
						</CardBody>
					</Card>
					<br />
					<div>
						{requestState.isLoading && <Loading />}
						{requestState.isError &&
							<Alert variant="danger">
								<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
								<p>{t(requestState.ErrorText)}</p>
							</Alert>
						}
						<div>
							<button className="btn btn-primary" disabled={isSubmitting || !values.name.length || Object.keys(errors).length > 0}>
								{t('btn.submit')}
							</button>
							<button type="button" className="btn btn-primary" disabled={isSubmitting} onClick={() => { history.goBack() }}>
								{t('btn.cancel')}
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default CreateDeviceGroupForm
