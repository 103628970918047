import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import QueryString from 'query-string';
import Loading from '../others/Loading';
import useDataApi from '../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function AdminTranslationTable() {
    const [keyList, setKeyList] = useState(null);
    const [categories, setCategories] = useState(null);
    const [languages, setLanguages] = useState(null)
    const [displayLanguages, setDisplayLanguages] = useState(null)
    const [selectedCategory, setSelectedCategories] = useState(null)
    const [requestState, sendRequest] = useDataApi();
    const [languagesModal, setLanguagesModal] = useState({display: false});
    const [caregoriesModal, setCategoriesModal] = useState({display: false});
    const [publishModal, setPublishModal] = useState({display: false});

    const [newCat, setNewCat] = useState({
        name: "",
        key: ""
    })
    const [newLang, setNewLang] = useState({
        name: "",
        key: ""
    })
    const [newKey, setNewKey] = useState({
        key: "",
        category: null
    })
    const [newKeyModal, setNewKeyModal] = useState({display:false});

    useEffect(()=>{
        let category = QueryString.parse(window.location.search).category;
        let wordsUrl = category ? `/translator-api/manage/api/words?categoryId=${category}` : '/translator-api/manage/api/words'
        sendRequest('GET', wordsUrl, null, (response)=>{
            setKeyList(response)
        })
    }, [])
    useEffect(()=>{
        sendRequest('GET', '/translator-api/manage/api/categories', null, (response)=>{
            var list = []
            for (var i in response){
                list.push({
                    label: response[i].catName,
                    value: response[i].catKey,
                    id: response[i].id
                })
            }
            setCategories(list)
            let selected = QueryString.parse(window.location.search).category
            setSelectedCategories(list.find(e=> e.value === selected));
        })
    }, [])
    useEffect(()=>{
        sendRequest('GET', '/translator-api/manage/api/languages', null, (response)=>{
            var list = [];
            for (var i in response){
                list.push({
                    label: response[i].langName,
                    value: response[i].langKey,
                    id: response[i].id
                })
            }
            if(!list.length){
                list.push({
                    label: 'English (Default)',
                    value: 'EN',
                    id: 'EN'
                })
            }
            setLanguages(list)

            let query = QueryString.parse(window.location.search).languages;
            if(!query || query === ''){
                setDisplayLanguages([list.find(e => e.value === 'EN')])
            } else {
                let display = [];
                query = query.split(',');
                for(let q in query){
                    display.push(list.find(e => e.value === query[q]))
                }
                setDisplayLanguages(display)
            }
        })
    }, [])

    return (
        <div className="container">
            
            <Row>
                <Col>
                    <label htmlFor="categoryselection">Select Category</label>
                        <Select
                            name="categoryselection"
                            options={categories}
                            value={selectedCategory}
                            onChange={
                                (selected)=>{
                                    let languages= QueryString.parse(window.location.search).languages;
                                    window.location.href = window.location.href.split('?')[0]
                                    + '?category='
                                    + selected.value
                                    + ((languages && languages !== "") ? ('&languages=' + languages) : '');
                                }
                            }
                        />
                        <button type="button" className="btn btn-info btn-sm" onClick={()=>{
                            let languages= QueryString.parse(window.location.search).languages;
                            window.location.href = window.location.href.split('?')[0]
                            + ((languages && languages !== "") ? ('?languages=' + languages) : '');

                        }}>all caterories</button>
                </Col>
                <Col>
                <label htmlFor="languagesselection">Select Languages</label>
                    <Select 
                        name="languagesselection"
                        options={languages}
                        value={displayLanguages}
                        isMulti={true}
                        onChange={
                            (selected)=>{
                                let category= QueryString.parse(window.location.search).category;
                                if(selected && selected.length){
                                    setDisplayLanguages(selected)
                                    let stringlanguages = "";
                                    for(var i in selected){
                                        stringlanguages += (parseInt(i)!==0 ? ',' : '') + selected[i].value ;
                                    }
                                    window.location.href = window.location.href.split('?')[0] + '?languages=' + stringlanguages + ((category && category !== "") ? ('&category=' + category) : '');
                                } else {
                                    window.location.href = window.location.href.split('?')[0] + ((category && category !== "") ? ('?category=' + category) : '');
                                }
                            }
                        }
                    />
                </Col>
            </Row>
            <Row style={{margin: '20px'}}>
                <Col>
                    <button type="button" className="btn btn-primary" onClick={()=>{setNewKeyModal({display: true})}}>New Key</button>
                </Col>
                <Col>
                    <button type="button" className="btn btn-primary" style={{marginRight: '5px'}} onClick={()=>{setCategoriesModal({display: true})}}>Categories</button>
                </Col>
                <Col>
                    <button type="button" className="btn btn-primary" onClick={()=>{setLanguagesModal({display: true})}}>Languages</button>
                </Col>
                <Col>
                    <button type="button" className="btn btn-warning" onClick={()=>{setPublishModal({display: true})}}>Publish Dictionary</button>
                </Col>
            </Row>
        <table className="table">
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Category</th>
                <th scope="col">Key</th>
                <th scope="col">Translations</th>
                <th scope="col">Save Translation</th>
                <th scope="col">Delete Key</th>
            </tr>
            </thead>
            <tbody>
            {keyList && keyList.map((key, index) => 
            <tr key={key.id}>
                <th scope="row">{index + 1}</th>
                <td>{categories && categories.find(c => c.value === key.categoryId).label}</td>
                <td style={{width: '150px', wordWrap: 'break-word'}}><b>{key.id}</b></td>
                <td style={{width: '300px'}}>
                    {
                        displayLanguages && displayLanguages.map((language)=>{
                            return <div className="form-group" key={`translation-${key}-${language.value}`}>
                                <label
                                    className="col-md-4 control-label"
                                    htmlFor={`input-${key}-${language.value}`}>
                                    {language.label}
                                </label>  
                                <textarea
                                    className="form-control input-md"
                                    name={`input-${key}-${language.value}`}
                                    value={keyList[index][language.value]}
                                    onChange={
                                        (e)=> {
                                            let updated = [...keyList];
                                            updated[index][language.value] = e.target.value;
                                            setKeyList(updated)
                                    }}
                                />
                            </div>
                        })
                    }
                </td>
                <td>
                    <button
                        className="btn btn-success"
                        onClick={(e) =>{
                            sendRequest('PUT', `/translator-api/manage/api/words/${keyList[index].id}`, keyList[index], (response)=> {
                                window.location.reload()
                            })
                        }}>
                            Save Changes
                    </button>
                </td>
                <td>
                    <button
                        className="btn btn-danger"
                        onClick={(e) =>{
                            sendRequest('DELETE', `/translator-api/manage/api/words/${keyList[index].id}`, null, (response)=> {
                                window.location.reload()
                            })
                        }}>
                            Delete
                    </button>
                </td>
            </tr>
            )}
            </tbody>
        </table>
        {requestState.isLoading && <Loading/>}
        {requestState.isError &&
            <Alert variant="danger">
            <Alert.Heading>Error Occured</Alert.Heading>
            <p>{requestState.ErrorText}</p>
            </Alert>
        }

        <Modal show={publishModal.display}>
            <Modal.Header>
                <h5 className="modal-title">Publish</h5>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to publish the entire dicrtionary?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger" onClick={() => {
                    sendRequest('POST', '/translator-api/manage/publish', null, (response)=> {
                        window.location.reload()
                    })
                }} >Publish Now</button>
                <button type="button" className="btn btn-secondary" onClick={() => setPublishModal({display: false})} >Close</button>
            </Modal.Footer>
        </Modal>
        <Modal size="lg" show={caregoriesModal.display}>
            <Modal.Header closeButton onClick={()=>{setCategoriesModal({display: false})}}>
            <h5 className="modal-title">Categories</h5>
            </Modal.Header>
            <Modal.Body>
            <table className="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Category Name</th>
                    <th scope="col">Category Value</th>
                    <th scope="col">Delete Category</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">#</th>
                        <td>
                            <input onChange={e => setNewCat({...newCat, name: e.target.value})} value={newCat.name} className="form-control"/>
                        </td>
                        <td>
                            <input onChange={e => setNewCat({...newCat, key: e.target.value})} value={newCat.key} className="form-control"/>
                        </td>
                        <td>
                            <button
                                className="btn btn-success"
                                onClick={(e) =>{
                                    sendRequest('POST', '/translator-api/manage/api/categories', {
                                        catName: newCat.name,
                                        catKey: newCat.key,
                                        id: newCat.key
                                    }, (response)=> {
                                        window.location.reload()
                                    })
                                }}>
                                    Add
                            </button>
                        </td>
                    </tr>
                    {categories && categories.map((cat, index)=> 
                        <tr key={`cat-${index}`}>
                            <th scope="row">{index + 1}</th>
                            <td style={{maxWidth: '150px', wordWrap: 'break-word'}}>{cat.label}</td>
                            <td>{cat.value}</td>
                            <td>
                                <button
                                    className="btn btn-danger"
                                    onClick={(e) =>{
                                        sendRequest('DELETE', `/translator-api/manage/api/categories/${cat.id}`, null, (response)=> {
                                            window.location.reload()
                                        })
                                    }}>
                                        Delete
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => setCategoriesModal({display: false})} >Close</button>
            </Modal.Footer>
        </Modal>

        <Modal size="lg" show={languagesModal.display}>
            <Modal.Header closeButton onClick={()=>{setLanguagesModal({display: false})}}>
            <h5 className="modal-title">Languages</h5>
            </Modal.Header>
            <Modal.Body>
            <table className="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Language Name</th>
                    <th scope="col">Language Key</th>
                    <th scope="col">Delete Language</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">#</th>
                        <td>
                            <input onChange={e => setNewLang({...newLang, name: e.target.value})} value={newLang.name} className="form-control"/>
                        </td>
                        <td>
                            <input onChange={e => setNewLang({...newLang, key: e.target.value})} value={newLang.key} className="form-control"/>
                        </td>
                        <td>
                            <button
                                className="btn btn-success"
                                onClick={(e) =>{
                                    sendRequest('POST', '/translator-api/manage/api/languages', {
                                        langName: newLang.name,
                                        langKey: newLang.key,
                                        id: newLang.key
                                    }, (response)=> {
                                        window.location.reload()
                                    })
                                }}>
                                    Add
                            </button>
                        </td>
                    </tr>
                    {languages && languages.map((lang, index)=> 
                        <tr key={`lang-${index}`}>
                            <th scope="row">{index + 1}</th>
                            <td style={{maxWidth: '150px', wordWrap: 'break-word'}}>{lang.label}</td>
                            <td>{lang.value}</td>
                            <td>
                                <button
                                    className="btn btn-danger"
                                    onClick={(e) =>{
                                        sendRequest('DELETE', `/translator-api/manage/api/languages/${lang.id}`, null, (response)=> {
                                            window.location.reload()
                                        })
                                    }}>
                                        Delete
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => setLanguagesModal({display: false})} >Close</button>
            </Modal.Footer>
        </Modal>

        <Modal size="lg" show={newKeyModal.display}>
            <Modal.Header closeButton onClick={()=>{setNewKeyModal({display: false})}}>
            <h5 className="modal-title">New Key</h5>
            </Modal.Header>
            <Modal.Body>
            <p>New Key modal body</p>
            <input onChange={e => setNewKey({...newKey, key: e.target.value})} value={newKey.key} placeholder="New Key..." className="form-control"/>
            <Select 
                options={categories}
                value={newKey.category}
                onChange={
                    (selected)=>{
                        setNewKey({
                            ...newKey,
                            category: selected
                        })
                    }
                }
            />
            <button type="button" className="btn btn-success" onClick={()=>{
                    sendRequest('POST', `/translator-api/manage/api/words`, {
                        id: `${newKey.category.id}.${newKey.key}`,
                        key: newKey.key,
                        categoryId: newKey.category.id,
                    }, (response)=> {
                        window.location.reload()
                    })
            }}>Add Key</button>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => setNewKeyModal({display: false})} >Close</button>
            </Modal.Footer>
        </Modal>
      </div>
    )
}

export default AdminTranslationTable
