import React from "react";
import Alert from 'react-bootstrap/Alert';
import { Formik, Form } from "formik";
import useDataApi from '../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import validate from '../../hooks/useValidation';
import Loading from '../others/Loading';
import { MyTextField } from '../others/FormElements';

function UpdateIntegratorPasswordForm() {
  const { t } = useTranslation();
  const [requestState, sendRequest] = useDataApi();

  return (
    <Formik
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      }}
	  validateOnChange={true}
      validateOnMount={true}
      validate={validate}
      onSubmit={(data, { setSubmitting }) => {
        setSubmitting(true);
        sendRequest('PUT', '/integrators-api/password/change', data, (response) => {
          setSubmitting(false);
          window.location.href = '/'
        });
        setTimeout(() => {
          setSubmitting(false);
        }, 3000);
      }}
    >
      {({ values, errors, isSubmitting }) => (
        <Form>
          <MyTextField name="currentPassword" inputType="password" bold />
          <MyTextField name="newPassword" inputType="password" />
          <MyTextField name="confirmNewPassword" inputType="password" />
          <div>
          {requestState.isLoading && <Loading/>}
          {requestState.isError &&
            <Alert variant="danger">
              <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
              <p>{t(requestState.ErrorText)}</p>
            </Alert>
          }
            <button className="btn btn-primary" disabled={isSubmitting} type="submit">
              {t('btn.submit')}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default UpdateIntegratorPasswordForm