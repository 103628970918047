import React, { useState, useEffect } from 'react'
import UpdateAdminShieldForm from '../../forms/UpdateAdminShieldForm';
import QueryString from 'query-string';
import useDataApi from '../../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import Loading from '../../others/Loading';

function UpdateAdminShieldPage(props) {
    const { t } = useTranslation();
    const [requestState, sendRequest] = useDataApi();
    const [shield, setShield] = useState(null)
    

    useEffect(() => {
        sendRequest('GET', `/admins-api/customers/${QueryString.parse(window.location.search).customerId}/shields/${QueryString.parse(window.location.search).extId}/info`, null, (response) => {
            setShield(response);
        });
    }, [])


    
    return (
        <div>
            {shield && <UpdateAdminShieldForm {...props} shield={shield}/>}
            {requestState.isLoading && <Loading/>}
            {requestState.isError &&
                <Alert variant="danger">
                <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
                <p>{t(requestState.ErrorText)}</p>
                </Alert>
            }
        </div>
    )
}

export default UpdateAdminShieldPage
