import { createContext, useState } from 'react'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import i18n from '../i18n'
import { CookieMaxAge } from '../configs/Configs';
const notLoggedState = {
    isLogged : false,
}

export function useAuthContext() {
    const [cookies, setCookie, removeCookie] = useCookies(['AUTHENTICATED_USER']);

    const getInitialAuthState = () => {
        // Check if user is authenticated
        const authenticated = cookies['AUTHENTICATED_USER'];
        if(!authenticated){
            return notLoggedState;
        }
        return {
            isLogged: true,
            ...authenticated
        }
    }
    const [auth, setAuth] = useState(getInitialAuthState());

    const AuthLogin = (results) => {
        // Update cookie
        setCookie('AUTHENTICATED_USER', results, {maxAge:  CookieMaxAge(results.userType)})

        // Update the language cookie if needed
        let cookieLang = cookies['i18next']
        if(!cookieLang || results.data.language !== cookieLang){
            setCookie('i18next', results.data.language)
        }

        // Update auth state
        setAuth({
            isLogged: true,
            ...results
        })

        return true;
    }
    const AuthLogout = () => {
        console.log('AuthLogout called')
        axios.get(window.location.origin + '/api/auth/logout')
        removeCookie('AUTHENTICATED_USER')
        window.sessionStorage.clear();
        setAuth(notLoggedState)
    }
    const AuthDataChanged = (newData) => {
        const authenticated = cookies['AUTHENTICATED_USER'];
        if(!authenticated){
            return;
        }
        if(newData.language && newData.language !== authenticated.data.language){
            authenticated.data.language = newData.language;
            i18n.changeLanguage(newData.language)
        }
        if(newData.fullName && newData.fullName !== authenticated.data.fullName){
            authenticated.data.fullName = newData.fullName;
        }
        if(newData.theme && newData.theme !== authenticated.data.theme){
            authenticated.data.theme = newData.theme;
        }
        
        setCookie('AUTHENTICATED_USER', authenticated, {maxAge:  CookieMaxAge(authenticated.userType)});
    }
    return { AuthContext, auth , AuthLogin, AuthLogout, AuthDataChanged }
}

const AuthContext = createContext();
export default AuthContext;
