import {
	useReducer, useContext,
} from 'react';
import axios from 'axios';
import AuthContext from '../contexts/AuthContext';
import { BaseUrls } from '../configs/Configs'
const dataFetchReducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_INIT':
			return {
				...state,
				isLoading: true,
				isError: false
			};
		case 'FETCH_SUCCESS':
			return {
				...state,
				isLoading: false,
				isError: false,
				response: action.payload,
			};
		case 'FETCH_FAILURE':
			return {
				...state,
				isLoading: false,
				isError: true,
				ErrorText: action.ErrorText,
				ErrorCode: action.ErrorCode
			};
		default:
			return state;
	}
};

const useDataApi = () => {
	const { AuthLogout } = useContext(AuthContext);	
	const [requestState, dispatch] = useReducer(dataFetchReducer, {
		isLoading: false,
		isError: false,
		ErrorText: "",
		ErrorCode: 0,
		response: null,
	});

	const sendRequest = async (_method, _url, _body, next) => {
		dispatch({ type: 'FETCH_INIT' });
		axios({
			method: _method,
			url: BaseUrls.gateway + _url,
			data: _body,
			withCredentials: true,
			headers: (_body && _body.isRefresh ? { isRefresh: true } : {})
		}).then((results) => {
			if (results.headers['sessionexpirydate']) {
				sessionStorage.setItem('sessionExpiryDate', results.headers['sessionexpirydate']);
			}
			dispatch({ type: 'FETCH_SUCCESS', payload: results.data });
			if (next) {
				next(results.data)
			}
		}).catch((error) => {
			console.log('error', error);
			if (error.response) {
				console.log('error.response', error.response)
				switch (error.response.status) {
					case 400:
						// TODO put all of them to the dictionary
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.bad-request", ErrorCode: 400 });
						break;
					case 401:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.not-authorized", ErrorCode: 401 });
						AuthLogout();
						break;
					case 403:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.not-authorized", ErrorCode: 403 });
						break;
					case 404:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.not-found", ErrorCode: 404 });
						break;
					case 408:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.timeout-reached", ErrorCode: 408 });
						break;
					case 409:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.already-exists", ErrorCode: 409 });
						break;
					case 422:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.invalid-form", ErrorCode: 422 });
						break;
					case 424:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.servers-unreachable", ErrorCode: 424 });
						break;
					case 429:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.max-login-tries", ErrorCode: 429 });
						break;
					case 441:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.repeat-password", ErrorCode: 441 });
						break;
					case 442:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.idle-user", ErrorCode: 442 });
						break;
					case 452:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.double-login-error", ErrorCode: 452 });
						setTimeout(() => {
							window.location.href = '/logout';
						}, 3000);
						break;
					case 500:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.internal-error", ErrorCode: 500 });
						break;					
					default:
						dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.default-error", ErrorCode: error.response.status });
				}
			} else {
				dispatch({ type: 'FETCH_FAILURE', ErrorText: "error.internet-connection", ErrorCode: 0 });
			}
		});
	}
	return [requestState, sendRequest];
};

export default useDataApi;
