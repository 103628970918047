import React, { useEffect, useState, useContext, useMemo } from "react";
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { useTranslation } from "react-i18next";
import { Card, CardBody, Button, ButtonGroup, ButtonToolbar, UncontrolledTooltip } from 'reactstrap';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import AuthContext from '../../../contexts/AuthContext';
import { tableConfig } from '../../../configs/Configs';
import useDataApi from '../../../hooks/useDataApi';
import { useHistory } from "react-router-dom";
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';

function UpdateCustomerInfoPage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const { auth } = useContext(AuthContext);
	const history = useHistory();
	const [admins, setAdmins] = useState(null);
	const [customerInfo, setCustomerInfo] = useState(null);
	const [otherAdmins, setOtherAdmins] = useState([]);
	const [deleteModalData, setDeleteModalData] = useState({
		display: false,
		item: null
	});
	const [resetModalData, setResetModalData] = useState({
		display: false,
		fullName: null,
		email: null
	});
	const [successModalData, setSuccessModalData] = useState({
		display: false,
		fullName: null,
		email: null
	});

	const adminsColumns = useMemo(
		() => [
			{
				Header: () => (t('tooltip.fullName')),
				accessor: 'fullName',
			},
			{
				Header: () => (t('tooltip.adminEmail')),
				accessor: 'adminEmail',
			},
			{
				Header: () => (t('tooltip.phone')),
				accessor: 'phone',
			},
			{
				Header: () => (t('tooltip.address')),
				accessor: 'address',
			},
			{
				Header: () => (t('label.action')),
				accessor: (originalRow, rowIndex) => originalRow,
				id: 'details',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<ButtonToolbar>
						<ButtonGroup className="btn-group--icons" dir="ltr">
							<Button id="edit" outline onClick={() => { history.push('updatecustomer' + '?id=' + value.adminEmail) }}><span className="lnr lnr-pencil" /></Button>
							{(auth.data.superAdmin && auth.data.adminEmail !== value.adminEmail) && <Button id="delete" outline onClick={() => setDeleteModalData({
								display: true,
								item: value.adminEmail
							})}><span className="lnr lnr-trash" /></Button>}
							{(auth.data.superAdmin && auth.data.adminEmail !== value.adminEmail) && <Button id="reset" outline onClick={() => setResetModalData({
								display: true,
								fullName: value.fullName,
								email: value.adminEmail
								
							})}><span className="lnr lnr-lock" /></Button>}
							<Button id="logs" outline onClick={() => { history.push('logs' + (auth.data.adminEmail !== value.adminEmail ? '?id=' + value.adminEmail : "")) }}><span className="lnr lnr-history" /></Button>
							<UncontrolledTooltip placement="bottom" target="edit">{t('table.edit')}</UncontrolledTooltip>
							{(auth.data.superAdmin && auth.data.adminEmail !== value.adminEmail) && <UncontrolledTooltip placement="bottom" target="delete">{t('table.delete')}</UncontrolledTooltip>}
							{(auth.data.superAdmin && auth.data.adminEmail !== value.adminEmail) && <UncontrolledTooltip placement="bottom" target="reset">{t('table.reset-password-btn')}</UncontrolledTooltip>}
							<UncontrolledTooltip placement="bottom" target="logs">{t('table.logs')}</UncontrolledTooltip>
						</ButtonGroup>
					</ButtonToolbar>)
			}
		],
		[],
	);
	
	const deleteConfirmed = () => {
		setDeleteModalData({ display: false });
		const idx = otherAdmins.findIndex(c => c.adminEmail === deleteModalData.item);
		var toSubmit = {...customerInfo};
		var temp = [...otherAdmins];
		temp.splice(idx,1);
		toSubmit.listAdmins = JSON.stringify(temp);
		sendRequest('PUT', '/customers-api/info', toSubmit, (response) => {
			window.location.reload()
		})
	}
	
	const resetPasswordUserConfirmed = (e, email, fullName) => {
		setDeleteModalData({ display: false })
		sendRequest('PUT', '/customers-api/password/reset', { portalUrl: window.location.hostname, adminEmail: email, fullName: fullName }, (result) => {
			setSuccessModalData({
				display: true,
				email: email,
				fullName: fullName
			})
		})
	}

	useEffect(() => {
		sendRequest('GET', '/customers-api/info', null, (result) => {
			var tempAdmins= [], totalAdmins = [];			
			if (result.listAdmins.length > 0) {
				tempAdmins = tempAdmins.concat(JSON.parse(result.listAdmins));
			}
			if (auth.data.superAdmin) {
				totalAdmins.push(result);
				totalAdmins = totalAdmins.concat(tempAdmins);				
			} else {
				totalAdmins = [tempAdmins.find(c => c.adminEmail === auth.data.adminEmail)];
			}
			setAdmins(totalAdmins);
			setCustomerInfo(result);
			setOtherAdmins(tempAdmins);
		})
	}, [])


	return (
		<div className="container">
			{admins !== null && <Card style={{ border: '2px solid white' }}>
				<CardBody>
					<div className="card__title">
						<h5 className="bold-text">{t('title.customer-info')}</h5>
					</div>

					{auth.data.superAdmin && <div><button
						title={t('label.add-admin')}
						className="btn btn-primary"
						style={{ float: 'right', display: 'flex' }}
						onClick={() => { history.push('updatecustomer') }}
					>
						<PlusCircleOutlineIcon />
					</button>
						<br /><br /><br /></div>}
					<ReactTableBase
						columns={adminsColumns}
						data={admins}
						tableConfig={tableConfig}
					/>
				</CardBody>
			</Card>}
			<Modal show={deleteModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("table.delete")}</h5>
				</Modal.Header>
				<Modal.Body>
					<p>{t("sentences.element-will-be-deleted")}: {deleteModalData.item}</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-danger" onClick={deleteConfirmed}>{t("btn.confirm-delete-user")}</button>
					<button type="button" className="btn btn-secondary" onClick={() => setDeleteModalData({ display: false })} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			<Modal show={resetModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("title.modal-reset-user-title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<p>{t("sentences.password-will-be-reset", { fullName: resetModalData.fullName, email: resetModalData.email })}</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-warning" onClick={(e) => { resetPasswordUserConfirmed(e, resetModalData.email, resetModalData.fullName) }}>{t("btn.confirm-reset-password")}</button>
					<button type="button" className="btn btn-secondary" onClick={() => setResetModalData({ display: false })} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			<Modal show={successModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("title.modal-success-reset-password-user-title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<p>{t("sentences.password-successfuly-reset", { fullName: successModalData.fullName, email: successModalData.email })}</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-secondary" onClick={() => window.location.reload()} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</div>
	)
}
export default UpdateCustomerInfoPage
