import React from 'react'

import UserNavbar from '../../navigations/UserNavbar';

import UserDashboardPage from './UserDashboardPage';
import UpdateUserInfoPage from './UpdateUserInfoPage'
import UpdateUserPasswordPage from './UpdateUserPasswordPage';
import AutoLogoutTimer from '../../others/AutoLogoutTimer';

export const UserPages = {
    UserDashboardPage,
    UpdateUserInfoPage,
    UpdateUserPasswordPage,
}

function UserLayout({component: Component, noNavBar, ...rest}) {
    return (
        <div>
            {(!noNavBar) && <UserNavbar/>}
            <div className="container theme-dark ltr-support" style={{marginTop: '50px'}}>
                <AutoLogoutTimer ComposedClass={<Component {...rest}/>}/>
            </div>
        </div>
    )
}

export default UserLayout
