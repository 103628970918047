import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert'
import UserStatusListTable from '../../tables/UserStatusListTable'
import { Card, CardBody, Col } from 'reactstrap';
import QueryString from 'querystring';
import { dashboardFilters } from '../../../configs/Configs';

function CustomerUsersStatusTablePage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [firstCall, setFirstCall] = useState(true);
	const [users, setUsers] = useState(null);
	const parsed = QueryString.parse(window.location.search.substring(1));
	
	const getUsers = () => {	
		sendRequest('GET', '/customers-api/users', {isRefresh: !firstCall}, (result) => 
		{			
			if (typeof parsed.filter !== 'undefined') {
				setUsers(dashboardFilters["users"][parsed.filter](result));
			} else {
				setUsers(result);
			}					
		})
	}
	
	useEffect(() => {
		getUsers();
		const intervalId = setInterval(() => {
			setFirstCall(false);
			getUsers();
		}, 15000);
		return (() => {
			clearInterval(intervalId);
		});
	}, [firstCall])
	return (
		<div className="container">
			
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
			{ users && (
				users.length > 0
					? <Col xs={24} md={24} lg={12}>
						<Card>
							<CardBody>
								<UserStatusListTable data={users} />
							</CardBody>
						</Card>
					</Col>
					: <p>{t('label.no-users-yet')}</p>
			)}
		</div>
	)
}

export default CustomerUsersStatusTablePage;

