import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert'
import IntegratorCustomersListTable from '../../tables/IntegratorCustomersListTable'
import Loading from '../../others/Loading';

function IntegratorCustomersTablePage() {
    const { t } = useTranslation();
    const [requestState, sendRequest] = useDataApi();
    useEffect(()=> {
        sendRequest('GET', '/integrators-api/customers')
    }, [])
    return (
        <div className="container">
            <h4>{t("title.customers-table")}</h4>
            <div style={{height: "70px"}}>
                <a href="/addcustomer" className="btn btn-primary float-right">{t("btn.add-customer")}</a>
            </div>
            
            
            {requestState.isLoading && <Loading/>}
            {requestState.isError &&
                <Alert variant="danger">
                <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
                <p>{t(requestState.ErrorText)}</p>
                </Alert>
            }
            { requestState.response && (
                requestState.response.length
                ? <IntegratorCustomersListTable data={{data: requestState.response}}/>
                : <p>{t('label.no-customers-yet')}</p>
            )}
        </div>
    )  
}

export default IntegratorCustomersTablePage;

