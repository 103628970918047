import React, { useState, useContext, useEffect } from 'react'
import AuthContext from '../../contexts/AuthContext';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import useDataApi from '../../hooks/useDataApi';

const AutoLogoutTimer = (props) => {
	const { ComposedClass } = props
	const [modalIsOpen, setIsModalOpen] = useState(false);
	const [timeId, setTimeID] = useState(null);
	const { AuthLogout } = useContext(AuthContext);
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();

	const handleOnIdle = () => {
		console.log("User is Idle: " + new Date());
		setIsModalOpen(true);
		setTimeID(setTimeout(logOut, 30000));
	};

	const logOut = () => {
		setIsModalOpen(false);
		clearTimeout(timeId);
		AuthLogout();
	};

	const stayActive = () => {
		setIsModalOpen(false);
		clearTimeout(timeId);
		// Send an API to keep the session alive
		sendRequest('GET', '/customers-api/mapping', {}, () => {
			checkExpiration();
		});
	};
	
	const checkExpiration = () => {
		const currentDate = new Date();
		const expiry = sessionStorage.getItem('sessionExpiryDate');	
		if (expiry) {
			if (expiry - currentDate.getTime() < 100000) {
				handleOnIdle();	
			} else {
				setTimeout(checkExpiration, expiry - currentDate.getTime() - 100000);
			}
		} else {
			setTimeout(checkExpiration, 60000);
		}
	}
	
	useEffect(() => {
		checkExpiration();		
	}, [])

	return (
		<>
			<div>
				<Modal show={modalIsOpen}>
					<Modal.Header>
						<h5 className="modal-title">{t("title.user-idle")}</h5>
					</Modal.Header>
					<Modal.Body>
						<p>{t("title.user-warning-logout")}</p>
					</Modal.Body>
					<Modal.Footer>
						<div>
							<button className="btn btn-secondary" onClick={logOut}>{t("title.user-log-out")}</button>
							<button className="btn btn-success" onClick={stayActive}>{t("title.user-keep-signedin")}</button>
						</div>
					</Modal.Footer>
				</Modal>
			</div>
			{ComposedClass}</>
	)
}

export default AutoLogoutTimer;