import React from 'react'
import AdminTranslationTable from '../../tables/AdminTranslationTable';

function TranslationPage() {
    return (
        <div>
            <AdminTranslationTable/>
        </div>
    )
}

export default TranslationPage
