import React, { useState } from "react";
import { Container, Col, Row, Card, CardBody } from 'reactstrap';
import RegularLoginForm from "../forms/RegularLoginForm";
import UserLoginForm from "../forms/UserLoginForm";
import { useTranslation } from "react-i18next";

function LoginPage(props) {
	const { t } = useTranslation();
	const [isUserLogin, setIsUserLogin] = useState(false)
	const switchUserLogin = () => {
		setIsUserLogin(!isUserLogin)
	}
	return (
		<Container>
			<Col xs={24} md={24} lg={12}>
				<Card>
					<CardBody>
						<div className="card col-sm-6" style={{ marginTop: '50px', paddingTop: '20px', marginLeft: '350px', backgroundColor: 'white' }}>
							<a href="/" style={{ textAlign: 'center' }}><img alt="EasySec Solutions" src="/assets/logo.jpg" style={{ width: '75%', margin: 'auto' }} /></a>
							<article className="card-body">
								<h4 className="card-title mb-4 mt-1">{t("title.sign-in")}</h4>								
								<RegularLoginForm {...props} />
							</article>
						</div>
					</CardBody>
				</Card>
			</Col>
		</Container>
	)
}

export default LoginPage
