import React, { useState, useEffect } from 'react'
import CreateUserForm from '../../forms/CreateUserForm';
import QueryString from 'querystring';
import useDataApi from '../../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert'
import Loading from '../../others/Loading';
import { Card, CardBody, Col } from 'reactstrap';

function CreateCustomerUserPage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [roles, setRoles] = useState(null)
	const [user, setUser] = useState(null)
	const [error, setError] = useState("");

	useEffect(() => {
		sendRequest('GET', '/customers-api/roles', {}, (result) => {
			setRoles(result);
		});
		const parsed = QueryString.parse(window.location.search.substring(1));
		if (typeof parsed.id !== 'undefined') {
			sendRequest('GET', '/customers-api/users', null, (response) => {
				try {
					setUser(response.filter(user => user.userId === parsed.id)[0]);
				} catch (error) {
					setError(t('error.bad-request'))
				}
			});
		}
	}, [])

	return (
		<div className="container">
			{(window.location.search.length === 0 || user) && roles && <Col xs={24} md={24} lg={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h3 className="bold-text">{user ? t('title.update-user') : t('title.create-user')}</h3>
						</div>
						<CreateUserForm roles={roles} user={user} />
					</CardBody>
				</Card>
			</Col>}
			{error && <p>{error}</p>}
			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</div>
	)
}

export default CreateCustomerUserPage
