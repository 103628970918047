import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import useDataApi from '../../hooks/useDataApi';
import { Formik, Form } from "formik";
import Alert from 'react-bootstrap/Alert';
import validate from '../../hooks/useValidation';
import Loading from '../others/Loading';
import { MyTextField, MyCheckBox, MySelect, MyCreatableSelect} from '../others/FormElements';
import { filterPassedTime, showSuccessMessage } from '../../configs/Configs';
import { useHistory } from "react-router-dom";
import { Card, CardBody } from 'reactstrap';
import DatePicker from 'react-datepicker';

function CreateOccasUserForm({ devices, users }) {

	const getDeviceOption = (device) => {
		if (device.hashedMacAdd) {
			let option = {};
			option.value = device.hashedMacAdd;
			option.label = device.name + " (" + device.macDevice + ")";
			return option;
		} else {
			return null
		}
	}

	const getUserOption = (user) => {
		if (user.userId) {
			let option = {};
			option.value = user.userId;
			option.label = user.fullName + " (" + user.email + ")";;
			return option;
		} else {
			return null
		}
	}

	const { t } = useTranslation();
	const history = useHistory();
	const [requestState, sendRequest] = useDataApi();
	const [startValidTime, setStartValidTime] = useState(new Date())
	const [endValidTime, setEndValidTime] = useState(new Date())
	const [showEmailPanel, setShowEmailPanel] = useState(false)

	const deviceOptions = devices.map((device) => { return getDeviceOption(device) });
	const userOptions = users.map((user) => { return getUserOption(user) });

	const showHideEmailPanel = (value) => {
		if (value.length > 0) {
			setShowEmailPanel(true);
		}
	}

	const createRoleDgPolicy = (fullName, userId, deviceId, baseName, setSubmitting, resetForm) => {
		const apiRoleRequest = '/customers-api/roles';
		let newRole = { name: baseName + "_Role", users: [userId], usersNames: [fullName], oldName: baseName + "_Role" };
		var roleId = "", dgId = "";
		sendRequest('POST', apiRoleRequest, newRole, (response) => {
			roleId = response;
			const apiDgRequest = '/customers-api/devicegroups';
			let newDg = { name: baseName + "_DeviceGroup", devices: [deviceId], devicesNames: [devices.filter(device => device.hashedMacAdd === deviceId)[0].name], oldName: baseName + "_DeviceGroup" };
			sendRequest('POST', apiDgRequest, newDg, (response) => {
				dgId = response;
				const apiPolicyRequest = '/customers-api/policies';
				let newPolicy = { name: baseName + "_Policy", roleId: roleId, deviceGroupId: dgId, roleName: baseName + "_Role", dgName: baseName + "_DeviceGroup", startTime: startValidTime, endTime: endValidTime };
				sendRequest('POST', apiPolicyRequest, newPolicy, (response) => {
					setSubmitting(false);
					showSuccessMessage(t('label.occas-user-created'));
					resetForm();
				});
			});
		});
	}

	return (
		<Formik
			initialValues={{ fullName: "", email: "" }}
			validateOnChange={true}
			validateOnMount={true}
			validate={showEmailPanel ? validate : ()=>{}}
			onSubmit={(data, { setSubmitting, resetForm }) => {
				setSubmitting(true);
				if (showEmailPanel) {
					data.email = data.email.toLowerCase();
					const baseName = data.email.split("@")[0];
					const { deviceId, ...newUser } = data;
					const apiRequest = `/customers-api/users/${window.location.hostname}`;				
					sendRequest('POST', apiRequest, newUser, (response) => {
						createRoleDgPolicy(data.fullName, response, data.deviceId, baseName, setSubmitting, resetForm);
					});
				} else {
					const userEntry = users.filter(user => user.userId === data.fullName)[0];
					const baseName = userEntry.email.split("@")[0];
					createRoleDgPolicy(userEntry.fullName, data.fullName, data.deviceId, baseName, setSubmitting, resetForm);
				}
				setTimeout(() => {
					setSubmitting(false);
				}, 3000);
			}}
		>
			{({ values, errors, isSubmitting, setFieldValue, setFieldTouched, touched }) => (
				<Form>
					<div style={{ display: 'flex' }}>
						<div className="form__half">
							<MyCreatableSelect
								value={userOptions.find(e => e.value === values["fullName"])}
								onChange={(e, value) => { setFieldValue(e, value.value) }}
								onInputChange={(value) => { showHideEmailPanel(value) }}
								onBlur={setFieldTouched}
								error={errors["fullName"]}
								touched={touched["fullName"]}
								options={userOptions}
								name={"fullName"}
							/>
						</div>
						{showEmailPanel && <div className="form__half">
							<MyTextField name="email" />
							<MyCheckBox name="isolated" isDisabled={"disabled"} bold />
							{values.isolated &&
								<div style={{ marginLeft: '30px' }}>
									<MyTextField name="password" inputType="password" />
								</div>}
						</div>}
					</div>
					<Card style={{ border: '2px solid white' }}>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">{t('title.occas-user-attributes')}</h5>
							</div>
							<div style={{ display: 'flex' }}>
								<table width="100%">
									<tr>
										<td width="33%" position="relative">
											<MySelect
												value={deviceOptions.find(e => e.value === values["deviceId"])}
												onChange={(e, value) => { setFieldValue(e, value.value) }}
												onBlur={setFieldTouched}
												error={errors["deviceId"]}
												touched={touched["deviceId"]}
												options={deviceOptions}
												name={"deviceId"}
											/>
										</td>
										<td width="33%" position="relative" style={{ textAlign: 'center' }}>
											<span><h5 className="bold-text" style={{ display: 'block', textAlign: 'center', margin: 0 }}>{t("label.start-valid-time")}</h5></span>
											<DatePicker
												timeFormat="HH:mm"
												className="form__form-group-datepicker"
												selected={startValidTime}
												onChange={(date) => setStartValidTime(date)}
												minDate={new Date()}
												maxDate={endValidTime}
												filterTime={filterPassedTime}
												showTimeSelect
												dateFormat="MMMM d, yyyy h:mm aa"
												dropDownMode="select"
											/>
										</td>
										<td width="33%" position="relative" style={{ textAlign: 'center' }}>
											<span><h5 className="bold-text" style={{ display: 'block', textAlign: 'center', margin: 0 }}>{t("label.end-valid-time")}</h5></span>
											<DatePicker
												timeFormat="HH:mm"
												className="form__form-group-datepicker"
												selected={endValidTime}
												onChange={(date) => setEndValidTime(date)}
												minDate={startValidTime}
												filterTime={filterPassedTime}
												showTimeSelect
												dateFormat="MMMM d, yyyy h:mm aa"
												dropDownMode="select"
											/>
										</td>
									</tr>
								</table>
							</div>
						</CardBody>
					</Card>
					<br /><br />
					<div>
						{requestState.isLoading && <Loading />}
						{requestState.isError &&
							<Alert variant="danger">
								<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
								<p>{t(requestState.ErrorText)}</p>
							</Alert>
						}
						<p>{errors[0]}</p>
						<button className="btn btn-primary" disabled={isSubmitting || !values.deviceId || Object.keys(errors).length > 0}>
							{t('btn.submit')}
						</button>
						<button type="button" className="btn btn-primary" disabled={isSubmitting} onClick={() => { history.goBack() }}>
							{t('btn.cancel')}
						</button>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default CreateOccasUserForm