import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

function SidebarLink ({
  title, icon, newLink, route, color, fontSize, theme
})  {
  var finalColor = color ? color : (theme === 'theme-dark' ? 'white' : 'black');
  return <NavLink
    to={route}    
    activeClassName="sidebar__link-active"
  >
    <li className="sidebar__link">
      {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
      <p className="sidebar__link-title" style={{color: finalColor, fontSize: fontSize}}>
        {title}
        {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
      </p>
    </li>
  </NavLink>
};

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  fontSize: '14px'
};

export default SidebarLink;
