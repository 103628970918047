import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col } from 'reactstrap';
import Collapse from '../../../shared/components/Collapse';
import ShieldsListTable from '../../tables/ShieldsListTable';
import { Progress } from 'reactstrap';

const CustomerToDoListContainer = (props) => {
	const { t } = useTranslation();
	const [progress, setProgress] = useState(0);
	const [toConnectData, setToConnectData] = useState([]);
	const [toApproveData, setToApproveData] = useState([]);
	
	
	const filterResponse = (response, filter) => {
		if (filter === 'all') {
			return response;
		} else if (filter === 'not connected') {
			var now = new Date();
			return response.filter(shield => ((shield.regStatus === 'N/A' || shield.regStatus === 'approved') && (shield.snooze === '' || now.getTime() > new Date(shield.snooze).getTime())));
		}
		else {
			var now = new Date();
			return response.filter(shield => (shield.regStatus === filter && (shield.snooze === '' || now.getTime() > new Date(shield.snooze).getTime())));
		}
	};

	const getData = () => {
		setProgress(props.progress(props.data));
		setToConnectData(filterResponse(props.data, 'not connected'));
		setToApproveData(filterResponse(props.data, 'pending'));
	};

	useEffect(() => {
		getData();		
	}, [props.data])

	return (
		<Col xs={12} md={12} lg={4}>
			{ progress < 100 &&
				<Card>
					<CardBody>
						<div className="card__title">
							<h3 className="bold-text">{t("title.complete-your-installation")}</h3>
						</div>
						<Collapse title={toConnectData.length + t("title.shields-to-connect")}>
							<ShieldsListTable data={toConnectData} filter='not connected' />
						</Collapse>
						<Collapse title={toApproveData.length + t("title.shields-to-approve")}>
							<ShieldsListTable data={toApproveData} filter='pending' />
						</Collapse>
						<div className="progress-wrap progress-wrap--middle">
							<Progress value={progress}>{progress}%</Progress>
						</div>
					</CardBody>
				</Card>
			}
			{progress === 100 && <h3 className="bold-text">{t("title.installation-complete")}</h3>}
		</Col>

	)
}

export default CustomerToDoListContainer;
