import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useDataApi from '../../hooks/useDataApi'
import { Formik, Form } from "formik";
import Alert from 'react-bootstrap/Alert';
import validate from '../../hooks/useValidation';
import Loading from '../others/Loading';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { tableConfig, showSuccessMessage } from '../../configs/Configs'
import { MyTextField } from '../others/FormElements';
import { Button } from 'reactstrap';
import { useHistory } from "react-router-dom";
import PlusIcon from 'mdi-react/PlusIcon';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import Chip from "@material-ui/core/Chip";

function CreateRoleForm({ users, role }) {
	
	const getInitOrigData = (users, role) => {
		if (role && role.users) {
			return users.filter(user => !role.users.includes(user.userId));
		} else {
			return users;
		}
	}

	const getInitDestData = (users, role) => {
		if (role && role.users) {
			return users.filter(user => role.users.includes(user.userId));
		} else {
			return [];
		}
	}

	const { t } = useTranslation();
	const history = useHistory();
	const [requestState, sendRequest] = useDataApi();
	const [origData, setOrigData] = useState(() => getInitOrigData(users, role));
	const [destData, setDestData] = useState(() => getInitDestData(users, role));

	const onClickButton = (direction, userId, rows) => {
		let copyDataSource = (direction === 0 ? [...origData] : [...destData]);
		let copyDataDest = (direction === 0 ? [...destData] : [...origData]);
		var selectedData;

		if (userId !== "all") {
			selectedData = copyDataSource.filter(obj => obj.userId === userId);
			copyDataSource = copyDataSource.filter(obj => obj.userId !== userId);
		}
		else {
			selectedData = rows.map(row => row.values);
			var selectedUsers = selectedData.map(data => data.userId);
			copyDataSource = copyDataSource.filter(obj => !selectedUsers.includes(obj.userId));
		}
		copyDataDest.push.apply(copyDataDest, selectedData);

		if (direction === 0) {
			setOrigData(copyDataSource);
			setDestData(copyDataDest);			
		}
		else {
			setDestData(copyDataSource);
			setOrigData(copyDataDest);			
		}
	};

	const sourceColumns = useMemo(
		() => [
			{
				Header: ({ rows }) => (<Button size="sm"
					onClick={() => {
						onClickButton(0, "all", rows)
					}}><PlusCircleOutlineIcon />
				</Button>),
				accessor: 'userId',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<Button size="sm"
						onClick={() => {
							onClickButton(0, value)
						}}><PlusIcon />
					</Button>)
			},
			{
				Header: () => (t('table.user-full-name')),
				accessor: 'fullName',
			},
			{
				Header: () => (t('table.user-email')),
				accessor: 'email',
			},
		],
		[origData, destData],
	);


	return (

		<Formik
			initialValues={role ?? { name: "", description: "" }}
			validateOnChange={true}
			validateOnMount={true}
			validate={validate}
			onSubmit={(data, { setSubmitting, resetForm }) => {
				setSubmitting(true);
				let newRole = { ...data, users: destData.map(row => row.userId), usersNames: destData.map(row => row.fullName), oldName: (role ? role.name : data.name) };
				const apiRequest = (role ? `/customers-api/roles/${role.roleId}` : '/customers-api/roles');
				sendRequest((role ? 'PUT' : 'POST'), apiRequest, newRole, (response) => {
					setSubmitting(false);
					if (role) {
						history.goBack()
					} else {
						showSuccessMessage(t('label.role-created'));
						resetForm();
						setOrigData(users);
						setDestData([]);
					}
				});
				setTimeout(() => {
					setSubmitting(false);
				}, 3000);
			}}
		>
			{({ values, errors, isSubmitting }) => (
				<Form>
					<div style={{ display: 'flex' }}>
						<div className="form__half">
							<MyTextField name="name" />
						</div>
						<div className="form__half">
							<MyTextField name="description" />
						</div>
					</div>
					<br />
					<Card style={{ border: '2px solid white' }}>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">{t('label.added-users')}</h5>
							</div>
							
							{destData.map((item, index) => (
								<>
								<Chip									
									id={"role"+index}
									label={item.fullName}
									clickable
									color="primary"
									onClick={() => null}
									onDelete={() => onClickButton(1, item.userId)}
									style={{
										padding: 16,
										margin: 16
									}}
								/>
								<UncontrolledTooltip placement="bottom" target={"role"+index}>{item.email}</UncontrolledTooltip>
								</>
							))}
							<br /><br />

							<div className="card__title">
								<h5 className="bold-text">{t('label.avail-users')}</h5>
							</div>

							<ReactTableBase
								columns={sourceColumns}
								data={origData}
								tableConfig={tableConfig}
							/>
						</CardBody>
					</Card>
					<br />
					<div>
						{requestState.isLoading && <Loading />}
						{requestState.isError &&
							<Alert variant="danger">
								<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
								<p>{t(requestState.ErrorText)}</p>
							</Alert>
						}
						<div>
							<button className="btn btn-primary" disabled={isSubmitting || Object.keys(errors).length > 0}>
								{t('btn.submit')}
							</button>
							<button type="button" className="btn btn-primary" disabled={isSubmitting} onClick={() => { history.goBack() }}>
								{t('btn.cancel')}
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default CreateRoleForm
