import React from 'react';
import { useTranslation } from 'react-i18next'
import CustomerMonitoringLogsTable from '../../tables/CustomerMonitoringLogsTable'
import { Card, CardBody, Col } from 'reactstrap';

function CustomerMonitoringLogsPage() {
	const { t } = useTranslation();
	
	
	
	return (
		<div className="container">			
			<Col xs={24} md={24} lg={12}>
				<Card>
					<CardBody>
						<CustomerMonitoringLogsTable />
					</CardBody>
				</Card>
			</Col>
		</div>
	)
}

export default CustomerMonitoringLogsPage;