import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal';
import useDataApi from '../../hooks/useDataApi'
import Alert from 'react-bootstrap/Alert';
import { IntegratorStatusOptions } from '../../configs/Configs';
import Select from 'react-select';
import Loading from '../others/Loading';

function IntegratorsListTable(props) {
    const { t } = useTranslation();
    const [requestState, sendRequest] = useDataApi();
    const [deleteModalData, setDeleteModalData] = useState({
        display : false,
        integratorId : null,
        fullName: null
    })
    const [resetModalData, setResetModalData] = useState({
        display : false,
        adminEmail : null,
        fullName: null,
        integratorId: null
    })
    const [successModalData, setSuccessModalData] = useState({
        display : false,
        fullName: null
    })
    const [changeStatusModalData, setChangeStatusModalData] = useState({
        display: false,
        integratorId: null,
        oldStatus: null,
        newStatusValue: null,
        newStatusLabel: null
    })

    const changeIntegratorStatusConfirmed = (integratorId, newStatusValue) => {
        setChangeStatusModalData({display: false})
        sendRequest('PUT', `/admins-api/integrators/${integratorId}/status`, {status: newStatusValue, portalUrl: window.location.hostname}, (result)=> {
            window.location.reload();
        })
    }

    const deleteIntegratorConfirmed = (e) => {
        setDeleteModalData({display: false})
        sendRequest('DELETE', `/admins-api/integrators/${deleteModalData.integratorId}`, null, (result)=> {
            window.location.reload()
        })
    }
    const resetPasswordIntegratorConfirmed = (e, adminEmail, fullName) => {
        setDeleteModalData({display: false})
        sendRequest('PUT', `/admins-api/integrators/${resetModalData.integratorId}/password/reset`, {portalUrl: window.location.hostname}, (result)=> {
            setSuccessModalData({
                display: true,
                adminEmail: adminEmail,
                fullName:fullName
            })
        })
    }
    return (
        <div>
        <table className="table">
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col"><b>{t("table.integrator-integratorId")}</b></th>
                <th scope="col">{t("table.integrator-fullName")}</th>
                <th scope="col">{t("table.integrator-status")}</th>
                <th scope="col">{t("table.customers")}</th>
                <th scope="col">{t("table.integrator-reset-password")}</th>
                <th scope="col">{t("table.edit")}</th>
                <th scope="col">{t("table.delete")}</th>
            </tr>
            </thead>
            <tbody>
            {props.data.data.map((integrator, index) => 
            <tr key={integrator.integratorId}>
                <th scope="row">{index + 1}</th>
                <td>{integrator.integratorId}</td>
                <td>{integrator.fullName}</td>
                <td>
                <Select
                    onChange={(value) => {
                        if(value.value !== integrator.status){
                            setChangeStatusModalData(
                                {
                                    display: true,
                                    integratorId: integrator.integratorId,
                                    oldStatus: IntegratorStatusOptions.find(e => e.value === integrator.status).label,
                                    newStatusValue: value.value,
                                    newStatusLabel: value.label
                            })
                        }
                    }}
                    options={IntegratorStatusOptions}
                    value={IntegratorStatusOptions.find(e => e.value === integrator.status)}
                />
                </td>
                <td>
                    <button
                        className="btn btn-dark"
                        onClick={(e) =>{
                            window.location.href = `/customers?integratorId=${integrator.integratorId}`
                        }}>
                            {t("table.view-customers-btn")}
                    </button>
                </td>
                <td>
                    <button className="btn btn-warning" onClick={(e) =>{
                            setResetModalData({
                                display: true,
                                adminEmail: integrator.adminEmail,
                                fullName: integrator.fullName,
                                integratorId: integrator.integratorId
                            })
                        }}>
                        <b>{t("table.reset-password-btn")}</b>
                    </button>
                </td>
                <td>
                    <button
                        className="btn btn-info"
                        onClick={(e) =>{
                            window.location.href = `/editintegrator?integratorId=${integrator.integratorId}`
                        }}>
                            {t("table.edit-btn")}
                    </button>
                </td>
                <td>
                    <button
                        className="btn btn-danger"
                        integratorid={integrator.integratorId}
                        fullname={integrator.fullName}
                        onClick={(e) =>{
                            setDeleteModalData({
                                display: true,
                                integratorId: integrator.integratorId,
                                fullName: integrator.fullName,
                            })
                        }}>
                            {t("table.delete-btn")}
                    </button>
                </td>
            </tr>
            )}
            </tbody>
        </table>
        <Modal show={resetModalData.display}>
            <Modal.Header>
            <h5 className="modal-title">{t("title.modal-reset-integrator-title")}</h5>
            </Modal.Header>
            <Modal.Body>
                <p>{t("sentences.password-will-be-reset", {fullName: resetModalData.fullName, email: resetModalData.adminEmail})}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-warning" onClick={(e) => {resetPasswordIntegratorConfirmed(e, resetModalData.adminEmail, resetModalData.fullName)}}>{t("btn.confirm-reset-password")}</button>
                <button type="button" className="btn btn-secondary" onClick={() => setResetModalData({display: false})} >{t("btn.modal-close")}</button>
            </Modal.Footer>
        </Modal>

        <Modal show={deleteModalData.display}>
            <Modal.Header>
            <h5 className="modal-title">{t("title.modal-delete-integrator-title")}</h5>
            </Modal.Header>
            <Modal.Body>
                <p>{t("sentences.element-will-be-deleted", {elementName: deleteModalData.fullName})}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger" onClick={deleteIntegratorConfirmed}>{t("btn.confirm-delete-integrator")}</button>
                <button type="button" className="btn btn-secondary" onClick={() => setDeleteModalData({display: false})} >{t("btn.modal-close")}</button>
            </Modal.Footer>
        </Modal>
        <Modal show={successModalData.display}>
            <Modal.Header>
            <h5 className="modal-title">{t("title.modal-success-reset-password-integrator-title")}</h5>
            </Modal.Header>
            <Modal.Body>
                <p>{t("sentences.password-successfuly-reset", {fullName: successModalData.fullName, email: successModalData.adminEmail})}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => window.location.reload()} >{t("btn.modal-close")}</button>
            </Modal.Footer>
        </Modal>

        <Modal show={changeStatusModalData.display}>
            <Modal.Header>
            <h5 className="modal-title">{t("title.modal-change-status-integrator-title")}</h5>
            </Modal.Header>
            <Modal.Body>
                <p>{t("sentences.status-will-change", {fullName: changeStatusModalData.integratorId, oldStatus: changeStatusModalData.oldStatus, newStatus: changeStatusModalData.newStatusLabel})}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger" onClick={(e) => changeIntegratorStatusConfirmed(changeStatusModalData.integratorId, changeStatusModalData.newStatusValue)}>{t("btn.confirm-change-status-integrator")}</button>
                <button type="button" className="btn btn-secondary" onClick={() => setChangeStatusModalData({display: false})} >{t("btn.modal-close")}</button>
            </Modal.Footer>
        </Modal>
        {requestState.isLoading && <Loading/>}
        {requestState.isError &&
            <Alert variant="danger">
            <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
            <p>{t(requestState.ErrorText)}</p>
            </Alert>
        }
      </div>
    )
}

export default IntegratorsListTable
