import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert'
import NavigationListTable from '../../tables/NavigationListTable'
import { Card, CardBody, Col } from 'reactstrap';
import Loading from '../../others/Loading';
import QueryString from 'querystring';
import { dashboardFilters } from '../../../configs/Configs';

function CustomerNavigationTablePage(props) {	
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [users, setUsers] = useState(null);
	const [devices, setDevices] = useState(null);
	const [roles, setRoles] = useState(null);
	const [deviceGroups, setDeviceGroups] = useState(null);
	const [policies, setPolicies] = useState(null);
	const parsed = QueryString.parse(window.location.search.substring(1));
	
	useEffect(() => {
		sendRequest('GET', '/customers-api/users', {}, (result) => 
		{			
			if (props.location.pathname === "/users" && typeof parsed.filter !== 'undefined') {
				setUsers(dashboardFilters["users"][parsed.filter](result));
			} else {
				setUsers(result);
			}					
		})
	}, [])
	useEffect(() => {
		sendRequest('GET', '/customers-api/shields', {}, (result) => 
		{
			if (props.location.pathname === "/shields" && typeof parsed.filter !== 'undefined') {
				setDevices(dashboardFilters["shields"][parsed.filter](result));
			} else {
				setDevices(result);
			}		
		})
	}, [])
	useEffect(() => {
		sendRequest('GET', '/customers-api/roles', {}, (result) => 
		{
			if (props.location.pathname === "/roles" && typeof parsed.filter !== 'undefined') {
				setRoles(dashboardFilters["roles"][parsed.filter](result));
			} else {
				setRoles(result);
			}			
		})
	}, [])
	useEffect(() => {
		sendRequest('GET', '/customers-api/devicegroups', {}, (result) => 
		{
			if (props.location.pathname === "/devicegroups" && typeof parsed.filter !== 'undefined') {
				setDeviceGroups(dashboardFilters["devicegroups"][parsed.filter](result));
			} else {
				setDeviceGroups(result);
			}			
		})
	}, [])
	useEffect(() => {
		sendRequest('GET', '/customers-api/policies', {}, (result) => 
		{			
			if (props.location.pathname === "/policies" && typeof parsed.filter !== 'undefined') {
				setPolicies(dashboardFilters["policies"][parsed.filter](result));
			} else {
				setPolicies(result);
			}		
		})
	}, [])
	return (
		<div className="container">
						
			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
			{users !== null  && devices !== null && roles !== null && deviceGroups !== null && policies !== null &&				
					<Col xs={24} md={24} lg={12}>
						<Card>
							<CardBody>
								<NavigationListTable users={users} devices={devices} roles={roles} devicegroups={deviceGroups} policies={policies} showBy={props.location.pathname}/>
							</CardBody>
						</Card>
					</Col>
		    }            
		</div>
	)
}

export default CustomerNavigationTablePage;

