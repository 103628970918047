import React, { useState, useEffect } from 'react'
import CreateOccasUserForm from '../../forms/CreateOccasUserForm';
import useDataApi from '../../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert'
import Loading from '../../others/Loading';
import { Card, CardBody, Col } from 'reactstrap';

function CreateCustomerOccasUserPage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [devices, setDevices] = useState(null)
	const [users, setUsers] = useState(null)
	const [error, setError] = useState("");

	useEffect(() => {
		sendRequest('GET', '/customers-api/shields', {}, (result) => {
			setDevices(result);
		});
		sendRequest('GET', '/customers-api/users', {}, (result) => {
			setUsers(result);
		});
	}, [])

	return (
		<div className="container">
			{users && devices && <Col xs={24} md={24} lg={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h3 className="bold-text">{t('title.create-occas-user')}</h3>
						</div>
						<CreateOccasUserForm devices={devices} users={users} />
					</CardBody>
				</Card>
			</Col>}
			{error && <p>{error}</p>}
			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</div>
	)
}

export default CreateCustomerOccasUserPage
