import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useDataApi from '../../hooks/useDataApi'
import Alert from 'react-bootstrap/Alert'
import { printDate, tableConfig, Colors, detailsTableConfig, userActions } from '../../configs/Configs';
import { useHistory } from "react-router-dom";
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { Card, CardBody, Button, ButtonGroup, ButtonToolbar, UncontrolledTooltip } from 'reactstrap';

function UserStatusListTable({ data }) {
	const { t } = useTranslation();
	const history = useHistory();
	const [requestState, sendRequest] = useDataApi();
	const [users, setUsers] = useState([]);	
	const [displayHistory, setDisplayHistory] = useState(false);
	const [dataHistory, setDataHistory] = useState([]);
	const [rowIndexHistory, setRowIndexHistory] = useState(-1);
	const [userNameHistory, setUserNameHistory] = useState("");
	const [firstCall, setFirstCall] = useState(true);

	const statusList = ["offline", "ready", "connected"];

	const getConnectionStatusColor = (status) => {
		if (status === "connected") {
			return Colors.green;
		}
		else if (status === "ready") {
			return Colors.blue;
		}
		else {
			return Colors.red;
		}
	}
	
	const computeHistoricDisplay = row => {
		if (row.index === rowIndexHistory) {
			setDisplayHistory(!displayHistory);
		} else {
			setRowIndexHistory(row.index);
			setDataHistory(row.original.stats.history);
			setUserNameHistory(row.original.fullName);
			setDisplayHistory(true);
		}
	}

	const currentStatusColumns = useMemo(
		() => [
			{
				// Make an expander cell
				Header: () => null, // No header
				id: 'expander', // It needs an ID
				Cell: ({ row }) => (row.original.stats.current.numInstances > 0 ? (
					// Use Cell to render an expander for each row.
					// We can use the getToggleRowExpandedProps prop-getter
					// to build the expander.
					<span {...row.getToggleRowExpandedProps()}>
						{row.isExpanded ? '👇' : '👉'}
					</span>
				) : null)
			},
			{
				Header: () => (t('table.user-name')),
				accessor: 'fullName',
			},
			{
				Header: () => (t('table.user-status')),
				id: 'status',
				accessor: (originalRow, rowIndex) => originalRow,
				Cell: ({ value }) => (<><p style={{ color: getConnectionStatusColor(value.stats.current.status) }}><b>{value.stats.current.status ? t(`words.status-${value.stats.current.status}`) : t('words.status-offline')}</b></p>
										{value.stats.current.since ? <small>{t('words.since') + ' ' + printDate(value.stats.current.since)}</small> : null}</>)
			},
			{
				Header: () => (t('table.num-instances')),
				id: 'numInstances',
				accessor: (originalRow, rowIndex) => originalRow,
				Cell: ({ value, row }) =>
					(<><p id={"currentNumInstances_" + value.userId}>{value.stats.current.numInstances ? value.stats.current.numInstances.toString() : "0"}</p>
						{value.stats.current.clientInstances && value.stats.current.clientInstances.length > 0 && <UncontrolledTooltip placement="bottom" target={"currentNumInstances_" + value.userId}>{value.stats.current.clientInstances.join("\n")}</UncontrolledTooltip>}</>
					)
			},
			{
				Header: () => (t('table.num-connections')),
				id: 'numConnections',
				accessor: (originalRow, rowIndex) => originalRow,
				Cell: ({ value, row }) =>
					(<><p id={"currentNumConnections_" + value.userId}>{value.stats.current.numConnections ? value.stats.current.numConnections.toString() : "0"}</p>
						{value.stats.current.devices && value.stats.current.devices.length > 0 && <UncontrolledTooltip placement="bottom" target={"currentNumConnections_" + value.userId}>{value.stats.current.devices.join("\n")}</UncontrolledTooltip>}</>
					)
			},
			{
				Header: () => (t('label.action')),
				accessor: (originalRow, rowIndex) => originalRow,
				id: 'details',
				disableSortBy: true,
				Cell: ({ value, row }) =>
					(<ButtonToolbar>
						<ButtonGroup className="btn-group--icons" dir="ltr">
							<Button id="history" outline onClick={() => {
								computeHistoricDisplay(row);setTimeout(() => {
									window.scrollTo(0, document.body.scrollHeight);
								}, 100);
							}}><span className="lnr lnr-history" /></Button>
							<Button id="edit" outline onClick={() => { history.push(userActions.create + '?id=' + value.userId) }}><span className="lnr lnr-pencil" /></Button>
							<UncontrolledTooltip placement="bottom" target="edit">{t('table.edit')}</UncontrolledTooltip>
							<UncontrolledTooltip placement="bottom" target="history">{t('label.history')}</UncontrolledTooltip>
						</ButtonGroup>
					</ButtonToolbar>)
			}
		],
		[displayHistory],
	);
	
	const historyStatusColumns = useMemo(
		() => [
			{
				// Make an expander cell
				Header: () => null, // No header
				id: 'expander', // It needs an ID
				Cell: ({ row }) => (row.original.numInstances > 0 ? (
					// Use Cell to render an expander for each row.
					// We can use the getToggleRowExpandedProps prop-getter
					// to build the expander.
					<span {...row.getToggleRowExpandedProps()}>
						{row.isExpanded ? '👇' : '👉'}
					</span>
				) : null)
			},			
			{
				Header: () => (t('table.user-status')),
				id: 'status',
				accessor: (originalRow, rowIndex) => originalRow,
				Cell: ({ value }) => (<p style={{ color: getConnectionStatusColor(value.status) }}><b>{t(`words.status-${value.status}`)}</b></p>)
			},
			{
				Header: () => (t('table.num-instances')),
				id: 'numInstances',
				accessor: (originalRow, rowIndex) => originalRow,
				Cell: ({ value, row }) =>
					(<><p id={"historicNumInstances_" + row.index.toString()}>{value.numInstances.toString()}</p>
						{value.numInstances > 0 && <UncontrolledTooltip placement="bottom" target={"historicNumInstances_" + row.index.toString()}>{value.clientInstances.join("\n")}</UncontrolledTooltip>}</>
					)
			},
			{
				Header: () => (t('table.num-connections')),
				id: 'numConnections',
				accessor: (originalRow, rowIndex) => originalRow,
				Cell: ({ value, row }) =>
					(<><p id={"historicNumConnections_" + row.index.toString()}>{value.numConnections.toString()}</p>
						{value.numConnections > 0 && <UncontrolledTooltip placement="bottom" target={"historicNumConnections_" + row.index.toString()}>{value.devices.join("\n")}</UncontrolledTooltip>}</>
					)
			},
			{
				Header: () => (t('label.device-status-from')),
				accessor: 'from',
				Cell: ({ value }) => (printDate(value))
			},
			{
				Header: () => (t('label.device-status-to')),
				accessor: 'to',
				Cell: ({ value }) => (printDate(value))
			}
		],
		[],
	);
	
	const instancesColumns = useMemo(
		() => [
			{
				// Make an expander cell
				Header: () => null, // No header
				id: 'expander', // It needs an ID
				Cell: ({ row }) => (row.original.connections.length > 0 ? (
					// Use Cell to render an expander for each row.
					// We can use the getToggleRowExpandedProps prop-getter
					// to build the expander.
					<span {...row.getToggleRowExpandedProps()}>
						{row.isExpanded ? '👇' : '👉'}
					</span>
				) : null)
			},
			{
				Header: () => (t('label.public-ip')),
				accessor: 'pubIp',
				Cell: ({ value }) => (<div dangerouslySetInnerHTML={{ __html: value }} />)
			},
			{
				Header: () => (t('label.since')),				
				accessor: 'since',
				Cell: ({ value }) => (printDate(value))
			},
			{
				Header: () => (t('table.num-connections')),
				id: 'numConnections',
				accessor: (originalRow, rowIndex) => originalRow,
				Cell: ({ value, row }) =>
					(<p>{value.connections.length.toString()}</p>)
			},
			{
				Header: () => (t('table.user-version')),
				accessor: 'version',
			},	
		],
		[],
	);

	const connectionsColumns = useMemo(
		() => [
			{
				Header: () => (t('label.name')),
				accessor: 'shieldName',
			},
			{
				Header: () => (t('label.connected-since')),
				accessor: 'connectedSince',
				Cell: ({ value }) => (printDate(value))
			},
		],
		[],
	);
	
	const renderInstancesSubComponent = (row) =>
		(
			<ReactTableBase
				columns={instancesColumns}
				data={row.original.stats.current.clients}
				tableConfig={instancesConfig}
			/>
		)
		
	const renderHistoryInstancesSubComponent = (row) =>
		(
			<ReactTableBase
				columns={instancesColumns}
				data={row.original.clients}
				tableConfig={instancesConfig}
			/>
		)
		
	const renderConnectionsSubComponent = (row) =>
		(
			<ReactTableBase
				columns={connectionsColumns}
				data={row.original.connections}
				tableConfig={detailsTableConfig}
			/>
		)

	tableConfig.placeholder = t('label.search');
	const currentStatusConfig = { ...tableConfig, renderRowSubComponent: renderInstancesSubComponent };
	const historyStatusConfig = { ...tableConfig, renderRowSubComponent: renderHistoryInstancesSubComponent };
	const instancesConfig = { ...tableConfig, renderRowSubComponent: renderConnectionsSubComponent };

	const getUsersStatus = () => {
		sendRequest('POST', `/customers-api/monitoring/user_status`, {
			userIds: data.map(user => { return user.userId }), isRefresh: !firstCall
		}, (result) => {
			console.log(result)
			let updatedUsers = [];
			data.forEach(function(user) {
				user.stats = result[user.userId];
				updatedUsers.push(user);
			})
			setUsers(updatedUsers);
		})
	}

	useEffect(() => {
		getUsersStatus();
		const intervalId = setInterval(() => {
			setFirstCall(false);
			getUsersStatus();
		}, 15000);
		return (() => {
			clearInterval(intervalId);
		});
	}, [data])

	return (
		<>
			<div>
				<div className="card__title">
					<h5 className="bold-text">{t("navbar.users-status")}</h5>
				</div>
				<ReactTableBase
					columns={currentStatusColumns}
					data={users.sort(function(user1, user2) { return user2.status - user1.status })}
					tableConfig={currentStatusConfig}
				/>
			</div>
			<br /><br />
			<Card style={{ border: '2px solid white' }}>
				{displayHistory && dataHistory.length > 0 &&
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">{t("label.user-status-history", { name: userNameHistory })}</h5>
						</div>
						<ReactTableBase
							columns={historyStatusColumns}
							data={dataHistory}
							tableConfig={historyStatusConfig}
						/>
					</CardBody>
				}
			</Card>

			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</>
	)
}

export default UserStatusListTable
