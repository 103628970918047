import React, { useEffect } from 'react'
import useDataApi from '../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import NewPasswordForm from '../forms/NewPasswordForm';
import Alert from 'react-bootstrap/Alert';
import QueryString from 'query-string';
import Loading from '../others/Loading';
import { Redirect } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

function NewPasswordPage(props) {
	console.log('props from new password page', props)
	const { t } = useTranslation();
	const mailType = props.match.params.mailType;
	const isNew = ['admin-activated', 'integrator-activated', 'customer-activated', 'user-activated'].indexOf(mailType) !== -1;
	const identityType = mailType.split('-')[0];
	const [requestState, sendRequest] = useDataApi();

	useEffect(() => {
		const token = QueryString.parse(window.location.search).token
		console.log('useeffect called', token)
		sendRequest('POST', '/auth/token', {
			token
		})
	}, [])

	return (
		<div className="container theme-dark ltr-support">
			{requestState.response && <Col xs={24} md={24} lg={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h3 className="bold-text">{isNew ? t("title.welcome-easysec") : t("title.reset-password")}</h3>
						</div>
						<NewPasswordForm mailtype={mailType} isnew={isNew} identitytype={identityType} data={requestState.response} />
					</CardBody>
				</Card>
			</Col>}

			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<div>
					{requestState.ErrorCode === 401 && <Redirect to={`/notfound?from=${props.match.url}`} />}
					<Alert variant="danger">
						<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
						<p>{t(requestState.ErrorText)}</p>
					</Alert>
				</div>
			}
		</div>
	)
}

export default NewPasswordPage
