import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDataApi from '../../hooks/useDataApi'
import { ComponentsList, BaseUrls, printDate } from '../../configs/Configs';

function ModuleVersionsTable({ data }) {
	const { t } = useTranslation();
	const [arm, setArm] = useState(null);
	const [x64, setX64] = useState(null);
	const [versionsList, setVersionsList] = useState([]);
	const [requestState, sendRequest] = useDataApi();

	const updateComponent = (version) => {
		alert(version.versionType + ' will be updated')
		sendRequest('POST', `/admins-api/versions/update`, {
			PlanUpdateRequest: {
				type: version.versionType.toLowerCase(),
				version: version.newVersionUrl,
				systemVersion: version.toUpdateSystemVersion,
				componentVersion: version.toUpdateComponentVersion,
				filter: "all",
				time: new Date().toISOString(),
				platform: version.platform
			}
		}, (result) => {
			window.location.reload();
		})
	}
	const checkComponentVersion = (component, platform) => {
		let planningData = [];
		if (data.data.Planning.versionsList.length > 0) {
			const found = data.data.Planning.versionsList.find(e => e.Version.versionType === component && e.Version.platform === platform);
			if (found)
				planningData = found.Version;
		}
		let myComponentData, tempComponentData;
		sendRequest('GET', `/admins-api/versions/latest/${component}/${platform}`, null, (result) => {
			if (result.url && planningData) {
				if (planningData.versionUrl === result.url) {
					myComponentData = {
						...planningData,
						toUpdate: false,
						versionType: planningData.versionType,
						versionLatestUpdateTime: printDate(planningData.versionUpdateTime),
						releaseNotes: `${BaseUrls.gateway}/documents-api/releaseNotes/${planningData.versionType}/${planningData.systemVersion}_${planningData.componentVersion}/${platform}`,
						newVersionUrl: null,
						actualVersion: planningData.systemVersion + '/' + planningData.componentVersion,
						newVersion: null,
						description: result.shortDescription,
						platform: platform
					}
				}
				else { // There is a new version                                
					tempComponentData = {
						toUpdate: true,
						versionType: component,
						newVersionUrl: result.url,
						toUpdateSystemVersion: result.systemVersion,
						toUpdateComponentVersion: result.componentVersion,
						newVersion: result.systemVersion + "/" + result.componentVersion,
						releaseNotes: `${BaseUrls.gateway}/documents-api/releaseNotes/${component}/${result.systemVersion}_${result.componentVersion}/${platform}`,
						description: result.shortDescription,
						platform: platform
					}
					if (Object.keys(planningData).length > 0) {
						myComponentData = {
							...planningData,
							...tempComponentData,							
							versionLatestUpdateTime: printDate(planningData.versionUpdateTime),							
							actualVersion: planningData.systemVersion + '/' + planningData.componentVersion,
						}
					} else {
						myComponentData = tempComponentData;
					}
				}
				if (platform === "armv7l") 
					setArm(myComponentData);
				else
					setX64(myComponentData);
			}
		})
	}
	useEffect(() => {
		for (var component in ComponentsList) {
			checkComponentVersion(ComponentsList[component], "armv7l");
			checkComponentVersion(ComponentsList[component], "x86_64");
		}
	}, [])

	return (
		<div>
			<table className="table">
				<thead>
					<tr>
						<th scope="col">#</th>
						<th scope="col">{t("table.version-versionType")}</th>
						<th scope="col">{t("table.version-platform")}</th>
						<th scope="col">{t("table.version-versionUpdateTime")}</th>

						<th scope="col">{t("table.version-actual-version")}</th>
						<th scope="col">{t("table.version-new-version")}</th>
						<th scope="col">{t("table.version-description")}</th>
						<th scope="col">{t("table.version-release-notes")}</th>
						<th scope="col">{t("table.version-plan-update")}</th>
					</tr>
				</thead>
				<tbody>
					{arm && x64 && <>
						<tr key={"" + Math.random()}>
							<th scope="row">1</th>
							<td><b>{arm.versionType}</b></td>
							<td><b>{arm.platform}</b></td>
							<td>{printDate(arm.versionLatestUpdateTime)}</td>
							<td>{arm.actualVersion}</td>
							<td><b>{arm.newVersion || t('label.no-new-version')}</b></td>
							<td>{arm.description}</td>
							<td>
								<a
									className="btn btn-info"
									href={arm.releaseNotes}
									target="_blank"
									rel="noopener noreferrer"
								>
									{t("table.version-release-notes-btn")}
								</a>
							</td>
							<td>
								{
									arm.toUpdate
										? <button className="btn btn-danger" onClick={(e) => { updateComponent(arm) }}>
											{t("table.update-now-btn")}
										</button>
										: t('label.nothing-to-update')
								}
							</td>
						</tr>
						<tr key={"" + Math.random()}>
							<th scope="row">2</th>
							<td><b>{x64.versionType}</b></td>
							<td><b>{x64.platform}</b></td>
							<td>{printDate(x64.versionLatestUpdateTime)}</td>
							<td>{x64.actualVersion}</td>
							<td><b>{x64.newVersion || t('label.no-new-version')}</b></td>
							<td>{x64.description}</td>
							<td>
								<a
									className="btn btn-info"
									href={x64.releaseNotes}
									target="_blank"
									rel="noopener noreferrer"
								>
									{t("table.version-release-notes-btn")}
								</a>
							</td>
							<td>
								{
									x64.toUpdate
										? <button className="btn btn-danger" onClick={(e) => { updateComponent(x64) }}>
											{t("table.update-now-btn")}
										</button>
										: t('label.nothing-to-update')
								}
							</td>
						</tr></>
					}
					{(!arm || !x64) && <p>{t('label.no-versions-yet')}</p>}
				</tbody>
			</table>
		</div>
	)
}

export default ModuleVersionsTable
