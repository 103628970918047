import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal';
import useDataApi from '../../hooks/useDataApi'
import Alert from 'react-bootstrap/Alert'
import Loading from '../others/Loading';
import QueryString from 'query-string';

function AdminShieldsListTable(props) {
    const { t } = useTranslation();
    const [requestState, sendRequest] = useDataApi();
    const [deleteModalData, setDeleteModalData] = useState({
        display : false,
        extid : null
    })
    const [approveModalData, setApproveModalData] = useState({
        display : false,
        extid : null,
        protectedip: null,
    })

    const StatusColor = {
        "N/A" : "red",
        "created" : "red",
        "approved": "green"
    }
    const approveShieldConfirmed = (e) => {
        setApproveModalData({display: false})
        sendRequest('PUT', `/admins-api/customers/${QueryString.parse(window.location.search).customerId}/shields/${approveModalData.extid}/approve`, null, (result)=> {
            window.location.reload()
        })
    }
    const deleteShieldConfirmed = (e) => {
        setDeleteModalData({display: false})
        sendRequest('DELETE', `/admins-api/customers/${QueryString.parse(window.location.search).customerId}/shields/${deleteModalData.extid}`, null, (result)=> {
            window.location.reload()
        })
    }
    return (
        <div>
        <table className="table">
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col"><b>{t("table.shield-device-name")}</b></th>
                <th scope="col">{t("table.shield-protected-ip-device")}</th>
                <th scope="col">{t("table.shield-status")}</th>
                <th scope="col">{t("table.shield-ip")}</th>
                <th scope="col">{t("table.edit")}</th>
                <th scope="col">{t("table.delete")}</th>
            </tr>
            </thead>
            <tbody>
            {props.data.data.map((shield, index) => 
            <tr key={shield.extId}>
                <th scope="row">{index + 1}</th>
                <td>{shield.name}</td>
                <td>{shield.ip}</td>
                {
                    shield.regStatus === "pending"
                    ?
                    <td>
                        <button className="btn btn-success" onClick={() => {
                            setApproveModalData({
                                display: true,
                                extid: shield.extId,
                                protectedip: shield.ip
                            })
                        }}>
                            <b>{t("table.approve-shield-btn")}</b>
                        </button>
                    </td>
                    :
                    <td style={{color: StatusColor[shield.regStatus]}}>
                        <b>{shield.regStatus === "N/A" ? "created" : shield.regStatus}</b>
                    </td>
                }
                <td style={{color: "grey"}}>{shield.shieldExtIp}</td>
                <td>
                    <button
                        className="btn btn-info"
                        extid={shield.extId}
                        onClick={(e) =>{
                            window.location.href = `/editshield?integratorId=${QueryString.parse(window.location.search).integratorId}&customerId=${QueryString.parse(window.location.search).customerId}&extId=${shield.extId}`
                        }}>
                            {t("table.edit-btn")}
                    </button>
                </td>
                <td>
                    <button
                        className="btn btn-danger"
                        extid={shield.extId}
                        onClick={(e) =>{
                            setDeleteModalData({
                                display: true,
                                extid: shield.extId
                            })
                        }}>
                            {t("table.delete-btn")}
                    </button>
                </td>
            </tr>
            )}
            </tbody>
        </table>
        <Modal show={approveModalData.display}>
            <Modal.Header>
            <h5 className="modal-title">{t("title.modal-approve-shield-title")}</h5>
            </Modal.Header>
            <Modal.Body>
                <p>{t('sentences.shield-will-be-approved', {extId: approveModalData.extid, protectedIp: approveModalData.protectedip})}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-success" onClick={approveShieldConfirmed}>{t("btn.confirm-approve-shield")}</button>
                <button type="button" className="btn btn-secondary" onClick={() => setApproveModalData({display: false})} >{t("btn.modal-close")}</button>
            </Modal.Footer>
        </Modal>
        <Modal show={deleteModalData.display}>
            <Modal.Header>
            <h5 className="modal-title">{t("title.modal-delete-shield-title")}</h5>
            </Modal.Header>
            <Modal.Body>
                <p>{t("sentences.element-will-be-deleted", {elementName: deleteModalData.extid})}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger" onClick={deleteShieldConfirmed}>{t("btn.confirm-delete-shield")}</button>
                <button type="button" className="btn btn-secondary" onClick={() => setDeleteModalData({display: false})} >{t("btn.modal-close")}</button>
            </Modal.Footer>
        </Modal>
        {requestState.isLoading && <Loading/>}
        {requestState.isError &&
            <Alert variant="danger">
            <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
            <p>{t(requestState.ErrorText)}</p>
            </Alert>
        }
      </div>
    )
}

export default AdminShieldsListTable
