import React from 'react';

import CustomerNavbar from '../../navigations/CustomerNavbar';

import CustomerDashboardPage from './CustomerDashboardPage';
import UpdateCustomerInfoPage from './UpdateCustomerInfoPage'
import UpdateCustomerPasswordPage from './UpdateCustomerPasswordPage';
import CustomerShieldsTablePage from './CustomerShieldsTablePage';
import CreateCustomerShieldPage from './CreateCustomerShieldPage';
import UpdateCustomerShieldPage from './UpdateCustomerShieldPage';
import CustomerShieldsStatusTablePage from './CustomerShieldsStatusTablePage';
import CustomerUsersTablePage from './CustomerUsersTablePage';
import CreateCustomerUserPage from './CreateCustomerUserPage';
import CreateCustomerOccasUserPage from './CreateCustomerOccasUserPage';
import UpdateCustomerUserPage from './UpdateCustomerUserPage';
import CustomerUsersStatusTablePage from './CustomerUsersStatusTablePage';
import CustomerMonitoringActivitiesPage from './CustomerMonitoringActivitiesPage';
import CustomerMonitoringAlertsPage from './CustomerMonitoringAlertsPage';
import CustomerMonitoringLogsPage from './CustomerMonitoringLogsPage';
import CreateCustomerRoleTablePage from './CreateCustomerRoleTablePage';
import CreateCustomerDeviceGroupTablePage from './CreateCustomerDeviceGroupTablePage';
import CreateCustomerPolicyTablePage from './CreateCustomerPolicyTablePage';
import CreateCustomerDeviceTypePage from './CreateCustomerDeviceTypePage'; 
import MapCustomerSubnetTablePage from './MapCustomerSubnetTablePage';
import CustomerNavigationTablePage from './CustomerNavigationTablePage';
import UpdateCustomerInfoForm from '../../forms/UpdateCustomerInfoForm';
import AutoLogoutTimer from '../../others/AutoLogoutTimer';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

export const CustomerPages = {
    CustomerDashboardPage,
    UpdateCustomerInfoPage,
    UpdateCustomerPasswordPage,
    CustomerShieldsTablePage,
    CreateCustomerShieldPage,
    UpdateCustomerShieldPage,
    CustomerShieldsStatusTablePage,
    CustomerUsersTablePage,
    CreateCustomerRoleTablePage,
    CreateCustomerDeviceGroupTablePage,
    CreateCustomerPolicyTablePage,
    CreateCustomerDeviceTypePage,
	MapCustomerSubnetTablePage,
    CustomerNavigationTablePage,
    CreateCustomerUserPage,
	CreateCustomerOccasUserPage,
    UpdateCustomerUserPage,
    CustomerUsersStatusTablePage,
    CustomerMonitoringActivitiesPage,
    CustomerMonitoringAlertsPage,
    CustomerMonitoringLogsPage,
	UpdateCustomerInfoForm
}


function CustomerLayout({component: Component, noNavBar, direction, theme, ...rest}) {
	
    return (
        <div className={`${theme.className} ${direction}-support`} dir={direction}>
			<div id="success-message"></div>
			<div className="layout">
            {(!noNavBar) && <CustomerNavbar/>}
            </div>
            <div className="container__wrap">
                <AutoLogoutTimer ComposedClass={<Component {...rest}/>}/>
            </div>
        </div>
    )
}

export default withRouter(connect(state => ({
	 direction: state.rtl.direction, theme: state.theme
}))(CustomerLayout));
