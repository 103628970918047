import React from "react";
import { Formik, Form } from "formik";
import useDataApi from '../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import validate from '../../hooks/useValidation';
import Loading from '../others/Loading';
import { MyTextField } from '../others/FormElements';
import Alert from "react-bootstrap/Alert";

function UpdateUserForm({ user }) {
  const { t } = useTranslation();
  const [requestState, sendRequest] = useDataApi();

  return (
    <Formik
      initialValues={user}
	  validateOnChange={true}
      validateOnMount={true}
      validate={validate}
      onSubmit={(data, { setSubmitting }) => {
        setSubmitting(true);
        sendRequest('PUT', `/customers-api/users/${user.userId}/info`, data, (response) => {
          setSubmitting(false);
          window.location.href = '/users'
        });
        setTimeout(() => {
          setSubmitting(false);
        }, 3000);
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, setFieldTouched, touched }) => (
        <Form>          
          <MyTextField name="fullName" />
          <MyTextField name="email" readOnly />
          <div>
            <button className="btn btn-primary" disabled={isSubmitting} type="submit">
              {t('btn.submit-changes')}
            </button>
          </div>
          {requestState.isLoading && <Loading/>}
          {requestState.isError &&
            <Alert variant="danger">
              <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
              <p>{t(requestState.ErrorText)}</p>
            </Alert>
          }
        </Form>
      )}
    </Formik>
  )
}

export default UpdateUserForm