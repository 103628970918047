import React from 'react';
import useDataApi from '../../hooks/useDataApi'
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import validate from '../../hooks/useValidation';
import Loading from '../others/Loading';
import QueryString from 'query-string';
import { MyTextField, MyCheckBox, MyArrayElements } from '../others/FormElements';
import Alert from "react-bootstrap/Alert";

function CreateAdminShieldForm() {
  const { t } = useTranslation();
  const [requestState, sendRequest] = useDataApi();
  
  return (
    <div>    
    <Formik
      initialValues={{
        extId: "",
        hashedMacAdd: "",
        name: "",
        vendorId: "",
        modelId: "",
        dhcp: false,
        ip: "",
        publicConnectionCheck: false,
        shieldPublicPort: "",
        shieldPublicIp: "",
        services: []        
      }}
	  validateOnChange={true}
      validateOnMount={true}
      validate={validate}
      onSubmit={(data, { setSubmitting }) => {
        setSubmitting(true);
        sendRequest('POST', `/admins-api/customers/${QueryString.parse(window.location.search).customerId}/shields`, data, (response) => {
          setSubmitting(false);
          window.location.href = `/shields?integratorId=${QueryString.parse(window.location.search).integratorId}&customerId=${QueryString.parse(window.location.search).customerId}`
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, setFieldTouched, touched }) => (
        <Form>
          <MyTextField name="extId" bold onChange={(e) => { e.target.value = e.target.value.toUpperCase() }} />
          <MyTextField name="hashedMacAdd" bold onChange={(e) => { e.target.value = e.target.value.toUpperCase() }} />
          <MyTextField name="name" />
          <MyTextField name="vendorId" />
          <MyTextField name="modelId" />
          <div className="card" style={{ padding: '10px', marginTop: '30px' }}>
            <MyCheckBox name="dhcp" bold />
            <MyTextField name="ip" />
          </div>
          <div className="card" style={{ padding: '10px', marginTop: '50px' }}>
            <MyCheckBox name="publicConnectionCheck" bold />
            {values.publicConnectionCheck &&
              <div>
                <MyTextField name="shieldPublicIp" />
                <MyTextField name="shieldPublicPort" inputType="number" />
                <div className="card" style={{ padding: '10px', marginTop: '50px' }}>
                  <MyArrayElements
                    value={values["services"]}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors["services"]}
                    touched={touched["services"]}
                    name={"services"}
                    inputType="number"
                    itemKeyName="publicPort"
                    itemValueName="privatePort"
                  />
                </div>
              </div>

            }
          </div>                   
          <div>
            <button className="btn btn-primary" disabled={isSubmitting} type="submit">
              {t('btn.submit')}
            </button>
          </div>
        </Form>
      )}
    </Formik>

    {requestState.isLoading && <Loading/>}
    {requestState.isError &&
      <Alert variant="danger">
        <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
        <p>{t(requestState.ErrorText)}</p>
      </Alert>
    }
    </div>
  )
}

export default CreateAdminShieldForm