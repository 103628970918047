import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useDataApi from '../../hooks/useDataApi'
import { Formik, Form } from "formik";
import Alert from 'react-bootstrap/Alert';
import Loading from '../others/Loading';
import { useHistory } from "react-router-dom";
import { getSubnetMapping } from '../../configs/Configs'
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import ReactTableCell from '../../shared/components/table/components/ReactTableCell';
import { detailsTableConfig } from '../../configs/Configs'

function MapSubnetForm({ subnets, mapping }) {

	const getInitRows = (subnets, mapping) => {
		var initData = [];
		subnets.forEach(function(item) {
			initData.push({ subnet: item.subnet, publicIp: item.publicIp, mapping: getSubnetMapping(mapping, item.subnet, item.publicIp) })
		})
		return initData;
	}

	const { t } = useTranslation();
	const history = useHistory();
	const [requestState, sendRequest] = useDataApi();
	const [rows, setData] = useState(() => getInitRows(subnets, mapping));



	const columns = useMemo(
		() => [
			{
				Header: () => (t('label.subnet')),
				accessor: 'subnet',
				Cell: ReactTableCell,
				width: 65,
			},
			{
				Header: () => (t('label.shieldPublicIp')),
				accessor: 'publicIp',
				Cell: ReactTableCell,
				width: 65,
			},
			{
				Header: () => (t('label.mapping')),
				accessor: 'mapping',
			}
		],
		[],
	);

	const updateEditableData = (rowIndex, columnId, value) => {
		setData(old => old.map((item, index) => {
			if (index === rowIndex) {
				return {
					...old[rowIndex],
					[columnId]: value,
				};
			}
			return item;
		}));
	};

	const myConfig = { ...detailsTableConfig, isEditable: true, isResizable: false };

	return (

		<Formik
			initialValues={mapping}
			onSubmit={(data, { setSubmitting }) => {
				setSubmitting(true);
				const apiRequest = "/customers-api/mapping";
				sendRequest('POST', apiRequest, rows, (response) => {
					setSubmitting(false);
					window.location.reload();
				});
				setTimeout(() => {
					setSubmitting(false);
				}, 3000);
			}}
		>
			{({ values, errors, isSubmitting }) => (
				<Form>
					<ReactTableBase
						columns={columns}
						data={rows}
						tableConfig={myConfig}
						updateEditableData={updateEditableData}
					/>
					<div>
						{requestState.isLoading && <Loading />}
						{requestState.isError &&
							<Alert variant="danger">
								<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
								<p>{t(requestState.ErrorText)}</p>
							</Alert>
						}
						<div>
							<button className="btn btn-primary" disabled={isSubmitting || Object.keys(errors).length > 0}>
								{t('btn.submit')}
							</button>
							<button type="button" className="btn btn-primary" disabled={isSubmitting} onClick={() => { history.goBack() }}>
								{t('btn.cancel')}
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default MapSubnetForm
