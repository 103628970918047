import React, { useState, useEffect } from 'react'
import { useField, FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import CheckIcon from 'mdi-react/CheckIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import validate from '../../hooks/useValidation'
import { UncontrolledTooltip } from 'reactstrap';
import ShieldIcons from './ShieldIcons';

/**
 * Usage <MyCheckBox name="allowpublic" bold/>
 */
export function MyCheckBox({ marginTop, ...props }) {
	const { t } = useTranslation();
	const [field, meta] = useField(props);
	return (
		<div className="form__form-group">
			<label className="checkbox-btn" tmlFo={`checkbox-${field.name}`}>
				<input type="checkbox" className="checkbox-btn__checkbox" id={`checkbox-${field.name}`} checked={field.value} {...field}  />
				<span className="checkbox-btn__checkbox-custom">
					<CheckIcon />
				</span>
				<span id={`checkbox-${field.name}`} className="checkbox-btn__label">
					{props.bold ? <b>{t(`label.${field.name}`)}</b> : t(`label.${field.name}`)}
				</span>
			</label>
			<UncontrolledTooltip placement="top" target={`checkbox-${field.name}`}>
			{t(`tooltip.${field.name}`)}
			</UncontrolledTooltip>
			{ meta.error && meta.touched && <small className="text-danger" >{t(meta.error)}</small> }
		</div >

	);
}

/**
 * Usage
 * <MyTextField name="firstName" />
 * Or
 * <MyTextField name="firstName" onChange={(e)=>{e.target.value = e.target.value.toUpperCase()}} />
 */
export function MyTextField({ readOnly, bold, inputType, toggleReadOnly, callback, complete, noTooltip, ...props }) {
	const { t } = useTranslation();
	const [field, meta] = useField(props);
	const [showPassword, setShowPassword] = useState(false);
	const [type, setType] = useState(inputType);
	const [inputReadOnly, setInputReadOnly] = useState(readOnly); 
	const showPasswordToggle = () => {		
		setType(showPassword ? "password" : "text");
		setShowPassword(!showPassword);
	};
	const readOnlyToggle = () => {				
		setInputReadOnly(!inputReadOnly);		
		if (callback)
			callback(field.name, !inputReadOnly);
	};

	return (
		<div>
		{type !== "hidden" && <div className="form__form-group">
			<span className="form__form-group-label">{t(`label.${field.name}`)}</span>
			<div className="form__form-group-field">
				<input
					className={`form-control ${(meta.error && meta.touched) ? "is-invalid" : ""}`}
					placeholder={t(`placeholder.${field.name}`)}
					type={type ? type : "text"}
					id={field.name}
					{...field}
					readOnly={inputReadOnly ? true : false}
					style={{ fontWeight: bold ? 'bold' : 'normal' }}
					autoComplete={complete}
					onChange={(e) => {
						if (props.onChange) {
							props.onChange(e)
						}
						field.onChange(e)
					}}
				/>
				{inputType === "password" && <button
					type="button"
					className={`form__form-group-button${showPassword ? ' active' : ''}`}
					onClick={showPasswordToggle}
				><EyeIcon />
				</button>}
				{toggleReadOnly && <button
					type="button"
					className={`form__form-group-button`}
					onClick={readOnlyToggle}
				>{inputReadOnly ? <ShieldIcons type="enforced" fill="#FFFFFF" /> : <ShieldIcons type="bypass" fill="#FFFFFF" />}
				</button>}
				<UncontrolledTooltip placement="top" target={`${field.name}`}>
					{t(`tooltip.${field.name}`)}
				</UncontrolledTooltip>
				{meta.error && meta.touched && <small className="text-danger" >{t(meta.error)}</small>}
			</div>
		</div>}
		</div>
	);
};

/*
 * Usage
 * <MyMultiSelect
	  value={values["authUserIdList"]}
	  onChange={setFieldValue}
	  onBlur={setFieldTouched}
	  error={errors["authUserIdList"]}
	  touched={touched["authUserIdList"]}
	  options={users}
	  name={"authUserIdList"}
	/>
 */
export function MyMultiSelect(props) {
	const { onChange, onBlur, name, options, value, error, touched } = props
	const { t } = useTranslation();
	return (
		<div className="form__form-group">
			<label htmlFor="color">{t(`label.${name}`)}</label>
			<Select
				name={name}
				id={name}
				isMulti={true}
				isSearchable={true}
				closeMenuOnSelect={false}
				placeholder={t(`placeholder.${name}`)}
				noOptionsMessage={() => t(`p.no-options`)}
				onChange={(value) => onChange(name, value)}
				onBlur={() => onBlur(name, true)}
				options={options}
				value={value}
			/>
			<UncontrolledTooltip placement="top" target={`${name}`}>
				{t(`tooltip.${name}`)}
			</UncontrolledTooltip>
			{!!error && touched && (<div style={{ color: 'red', marginTop: '.5rem' }} >{t(error)}</div>)}
		</div>
	)
}

/*
 * Usage
 * <MySelect
	  value={languages.find(e => e.value === values["language"])}
	  onChange={(e, value) => setFieldValue(e, value.value)}
	  onBlur={setFieldTouched}
	  error={errors["language"]}
	  touched={touched["language"]}
	  options={languages}
	  name={"language"}
  />
 */
export function MySelect(props) {
	const { onChange, onBlur, name, options, value, error, touched, isDisabled } = props;
	const { t } = useTranslation();
	return (
		<div style={{ margin: '1rem 0' }}>
			<label htmlFor="color">{t(`label.${name}`)}</label>
			<Select
				name={name}
				id={name}
				placeholder={t(`placeholder.${name}`)}
				onChange={(value) => onChange(name, value)}				
				onBlur={() => onBlur(name, true)}
				options={options}
				value={value}
				isDisabled={isDisabled}
			/>
			<UncontrolledTooltip placement="top" target={`${name}`}>
				{t(`tooltip.${name}`)}
			</UncontrolledTooltip>
			{!!error && touched && (<div style={{ color: 'red', marginTop: '.5rem' }} >{t(error)}</div>)}
		</div>
	)
}

/*
 * Usage
 * <MyCreatableSelect
	  value={languages.find(e => e.value === values["language"])}
	  onChange={(e, value) => setFieldValue(e, value.value)}
	  onInputChange={}
	  onBlur={setFieldTouched}
	  error={errors["language"]}
	  touched={touched["language"]}
	  options={languages}
	  name={"language"}
  />
 */
export function MyCreatableSelect(props) {
	const { onChange, onInputChange, onBlur, name, options, value, error, touched, isDisabled } = props;
	const { t } = useTranslation();
	return (
		<div style={{ margin: '1rem 0' }}>
			<label htmlFor="color">{t(`label.${name}`)}</label>
			<CreatableSelect
				name={name}
				id={name}
				placeholder={t(`placeholder.${name}`)}
				onChange={(value) => onChange(name, value)}
				onInputChange={onInputChange ? (value) => onInputChange(value) : () => {}}
				onBlur={() => onBlur(name, true)}
				options={options}
				value={value}
				isDisabled={isDisabled}
			/>
			<UncontrolledTooltip placement="top" target={`${name}`}>
				{t(`tooltip.${name}`)}
			</UncontrolledTooltip>
			{!!error && touched && (<div style={{ color: 'red', marginTop: '.5rem' }} >{t(error)}</div>)}
		</div>
	)
}

/**
 * Usage
 * <MyArrayElements
	value={values["services"]}
	onChange={setFieldValue}
	onBlur={setFieldTouched}
	error={errors["services"]}
	touched={touched["services"]}
	options={options}
	name={"services"}
	inputType="number"
	itemKeyName="publicPort"
	itemValueName="privatePort"
  /> 
 */
export function MyArrayElements(props) {
	const { name, value, itemKeyName, itemValueName, inputType } = props;
	const { t } = useTranslation();
	const [newItemKey, setNewItemKey] = useState("")
	const [newItemValue, setNewItemValue] = useState("")
	const [newItemKeyError, setNewItemKeyError] = useState(null)
	const [newItemValueError, setNewItemValueError] = useState(null)

	useEffect(() => {
		if (newItemKey) {
			const values = {};
			values[itemKeyName] = newItemKey;
			const errors = validate(values)
			if (errors[itemKeyName]) {
				setNewItemKeyError(errors[itemKeyName]);
			} else if (newItemKeyError) setNewItemKeyError("")
		}
	}, [newItemKey])
	useEffect(() => {
		if (newItemValue) {
			const values = {};
			values[itemValueName] = newItemValue;
			const errors = validate(values)
			if (errors[itemValueName]) {
				setNewItemValueError(errors[itemValueName])
			} else if (newItemValueError) setNewItemValueError("")
		}
	}, [newItemValue])



	const mapper = (arrayHelpers, name, value, itemKeyName, itemValueName) => {
		const list = value || [];
		return list.map((item, index) => {
			return (
				<div className="form__form-group" style={{display: 'flex'}} key={`${name}-items-values-${index}`}>				
				<div className="col-md-4">
					<input
						className="form-control"
						readOnly
						type="number"
						key={`${name}-item-key-${index}`}
						value={(item["" + itemKeyName]).toString()}
						style={{ marginRight: '10px' }}
					/>
				</div>
				<div className="col-md-4">
					<input
						key={`${name}-item-value-${index}`}
						className="form-control"
						readOnly
						type="number"
						value={(item["" + itemValueName]).toString()}
						style={{ marginRight: '10px' }}
					/>
				</div>
					<button
						type="button"
						id={`remove-btn-${index}`}
						className="btn btn-outline-danger"
						key={`${name}-btn-item-remove-${index}`}
						onClick={(e) => {
							arrayHelpers.remove(e.currentTarget.id.split('remove-btn-')[1])
						}}>
						<b>X</b>
					</button>				
				</div>
			)
		})
	}
	return (
		<FieldArray name={name}>
			{
				arrayHelpers => (
					<div className="form__form-group" key={`${name}-array-key-div`}>
						<label key={`${name}-array-key-label`}>{t(`label.${name}`)}</label>
						<div className="card" style={{ padding: '10px' }}>
							{
								mapper(arrayHelpers, name, value, itemKeyName, itemValueName, inputType)
							}
							<div className="form-row">
								<div className="col-md-4">
									<input
										className={`form-control ${newItemKeyError ? 'is-invalid' : ''}`}
										type={inputType}
										name="new-item-key"
										key={`${name}-new-item-key`}
										style={{ marginRight: '10px' }}
										placeholder={t(`placeholder.${name}-key`)}
										value={newItemKey}
										onChange={(e) => setNewItemKey(e.target.value)}
									/>
									<div className="invalid-feedback">{newItemKeyError && t(newItemKeyError)}</div>
								</div>
								<div className="col-md-4">
									<input
										className={`form-control ${newItemValueError ? 'is-invalid' : ''}`}
										type={inputType}
										name="new-item-value"
										key={`${name}-new-item-value`}
										style={{ marginRight: '10px' }}
										placeholder={t(`placeholder.${name}-value`)}
										value={newItemValue}
										onChange={(e) => setNewItemValue(e.target.value)}
									/>
									<div className="invalid-feedback">{newItemValueError && t(newItemValueError)}</div>
								</div>
								<div className="col-md-4">
									<button
										className="btn btn-outline-info"
										type="button"
										key={`${name}-new-item-add-button`}
										onClick={
											(e) => {
												if (newItemKeyError || newItemValueError) {
													return;
												}
												if (!newItemKey || !newItemValue) {
													if (!newItemKey) {
														setNewItemKeyError("required")
													}
													if (!newItemValue) {
														setNewItemValueError("required")
													}
													return;
												}


												let found = value && (value.filter(e => e[itemKeyName] === newItemKey)).length
												if (!found && newItemKey && newItemValue) {
													const newItem = {}
													newItem["" + itemKeyName] = newItemKey;
													newItem["" + itemValueName] = newItemValue;
													arrayHelpers.push(newItem);
												}
												setNewItemKey("")
												setNewItemValue("")
											}}>
										{t(`btn.add-${name}`)}
									</button>
								</div>
							</div>
						</div>
					</div>
				)
			}
		</FieldArray>

	)
}