import React, { useState, useEffect } from 'react'
import MapSubnetForm from '../../forms/MapSubnetForm'
import useDataApi from '../../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert'
import Loading from '../../others/Loading';
import { Card, CardBody, Col } from 'reactstrap';

function MapCustomerSubnetTablePage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [subnets, setSubnets] = useState(null);
	const [mapping, setMapping] = useState(null);	

	useEffect(() => {
		sendRequest('GET', '/customers-api/shields', {}, (result) => {						
			const subnetList = result.filter(shield => shield.deviceSubnet.length > 0).map((shield) => {return {subnet: shield.deviceSubnet, publicIp: shield.shieldPublicIp}});
			var dataArr = subnetList.map(item=>{
    			return [JSON.stringify(item),item]
			});
			var maparr = new Map(dataArr); // create key value pair from array of array			
			setSubnets([...maparr.values()]);			
		});
		sendRequest('GET', '/customers-api/mapping', {}, (result) => {
			setMapping(result);
		});
	}, [])

	return (
		<div className="container">

			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
			{ subnets && mapping &&
				<Col xs={24} md={24} lg={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h3 className="bold-text">{t('title.map-subnet')}</h3>
							</div>
							<MapSubnetForm subnets={subnets} mapping={mapping} />
						</CardBody>
					</Card>
				</Col>
			}
		</div>
	)
}

export default MapCustomerSubnetTablePage;

