import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi';
import CustomerMonitoringAlertsTable from '../../tables/CustomerMonitoringAlertsTable'
import { Card, CardBody, Col } from 'reactstrap';
import Alert from 'react-bootstrap/Alert';

function CustomerMonitoringActivitiesPage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [users, setUsers] = useState(null);
	const [devices, setDevices] = useState(null);
	
	useEffect(() => {				
		sendRequest('GET', '/customers-api/users', {}, (result) => {
			setUsers(result);
		})
		sendRequest('GET', '/customers-api/shields', {}, (result) => {
			setDevices(result);
		})
	}, [])

	return (
		<div className="container">			
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}

			{users !== null && devices !== null && <Col xs={24} md={24} lg={12}>
				<Card>
					<CardBody>
						<CustomerMonitoringAlertsTable users={users} devices={devices}/>
					</CardBody>
				</Card>
			</Col>}
		</div>
	)
}

export default CustomerMonitoringActivitiesPage;