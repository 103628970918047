import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useDataApi from '../../hooks/useDataApi'
import { Formik, Form } from "formik";
import Alert from 'react-bootstrap/Alert';
import validate from '../../hooks/useValidation';
import Loading from '../others/Loading';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { detailsTableConfig, DefaultColumnFilter, getSubnetMapping } from '../../configs/Configs'
import { MyTextField } from '../others/FormElements';
import { Button, Card, CardBody } from 'reactstrap';
import { useHistory } from "react-router-dom";
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import Chip from "@material-ui/core/Chip";

function CreateDeviceTypeForm({ devices, mapping }) {
	
	const getInitOrigData = () => {
		devices.forEach(function(device) {
			device.displaySubnet = getSubnetMapping(mapping, device.deviceSubnet, device.shieldPublicIp);
		})
		return devices;	
	}

	const { t } = useTranslation();
	const history = useHistory();
	const [requestState, sendRequest] = useDataApi();
	const [origData, setOrigData] = useState(() => getInitOrigData());
	const [destData, setDestData] = useState([]);
	const [vendor, setVendor] = useState("");
	const [model, setModel] = useState("");
	const [subnet, setSubnet] = useState("");

	const onClickButton = (rows) => {		
		const selectedData = rows.map(row => row.values);
		const selectedHashMac = selectedData.map(data => data.hashedMacAdd);
		setDestData(devices.filter(obj => selectedHashMac.includes(obj.hashedMacAdd)));
		
		if (rows.length > 0) {
			setVendor(rows[0].allCells[1].column.filterValue);
			setModel(rows[0].allCells[2].column.filterValue);
			setSubnet(rows[0].allCells[3].column.filterValue);
		}
	};


	const sourceColumns = useMemo(
		() => [
			{
				Header: ({ column: { filteredRows } }) => (<Button size="sm"
					onClick={() => {
						onClickButton(filteredRows)
					}}><PlusCircleOutlineIcon />
				</Button>),
				accessor: 'hashedMacAdd',
				disableSortBy: true,
				Cell: () =>
					(<></>)
			},
			{
				Header: () => (t('label.vendorId')),
				accessor: 'vendorId',
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (t('label.modelId')),
				accessor: 'modelId',
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (t('label.subnet')),
				accessor: 'displaySubnet',
				Filter: DefaultColumnFilter,
			}
		],
		[],
	);

	return (

		<Formik
			initialValues={{
				name: ""				
			}}
			validateOnChange={true}
			validateOnMount={true}
			validate={validate}
			onSubmit={(data, { setSubmitting }) => {				
				destData.forEach(function(shield) {
					let newShield = { ...shield, deviceType: data.name };
					const apiRequest = `/customers-api/shields/${shield.extId}/info`;
					sendRequest('PUT', apiRequest, newShield);
				})
				setTimeout(() => {
					window.location.reload();					
				}, 3000);
			}}
		>
			{({ values, errors, isSubmitting }) => (
				<Form>
					<div style={{ display: 'flex' }}>
						<MyTextField name="name"/>
					</div>
					<br />
					<Card style={{ border: '2px solid white' }}>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">{t('label.added-devices')}</h5>
							</div>

							{typeof vendor !== 'undefined' && vendor.length > 0 && <Chip
								id={"vendor"}
								label={t('label.vendorId') + " = " + vendor}
								color="primary"
								style={{
									padding: 16,
									margin: 16
								}}
							/>}
							
							{typeof model !== 'undefined' && model.length > 0 && <Chip
								id={"model"}
								label={t('label.modelId') + " = " + model}
								color="primary"
								style={{
									padding: 16,
									margin: 16
								}}
							/>}
							
							{typeof subnet !== 'undefined' && subnet.length > 0 && <Chip
								id={"subnet"}
								label={t('label.subnet') + " = " + subnet}
								color="primary"
								style={{
									padding: 16,
									margin: 16
								}}
							/>}

							<div className="card__title">
								<h5 className="bold-text">{t('label.avail-devices')}</h5>
							</div>
							<ReactTableBase
								columns={sourceColumns}
								data={origData}
								tableConfig={detailsTableConfig}
							/>
						</CardBody>
					</Card>
					<br />
					<div>
						{requestState.isLoading && <Loading />}
						{requestState.isError &&
							<Alert variant="danger">
								<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
								<p>{t(requestState.ErrorText)}</p>
							</Alert>
						}
						<div>
							<button className="btn btn-primary" disabled={!destData.length || !values.name.length || isSubmitting || Object.keys(errors).length > 0}>
								{t('btn.submit')}
							</button>
							<button type="button" className="btn btn-primary" disabled={isSubmitting} onClick={() => { history.goBack() }}>
								{t('btn.cancel')}
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default CreateDeviceTypeForm
