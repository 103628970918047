import React, { useContext } from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import AuthContext from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next'

function AdminNavbar() {
  const { auth } = useContext(AuthContext);
    const { t } = useTranslation();
    return (
<Navbar  collapseOnSelect expand="lg" bg="dark" variant="dark">
<Navbar.Brand href="/">
      <img
        alt=""
        src="favicon.original.ico"
        width="30"
        height="30"
        className="d-inline-block align-top"
      />{' '}
    </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="/">{t('navbar.dashboard')}</Nav.Link>
      <NavDropdown title={t('navbar.title-management')} id="collasible-nav-dropdown">
        <NavDropdown.Item href="/integrators">{t("navbar.integrators")}</NavDropdown.Item>
        <NavDropdown.Item href="/versions">{t("navbar.versions")}</NavDropdown.Item>
        <NavDropdown.Item href="/translations">{t("navbar.translations")}</NavDropdown.Item>
      </NavDropdown>
      {/* <NavDropdown title={t('navbar.title-monitoring')} id="collasible-nav-dropdown">
        <NavDropdown.Item href="/activities">{t("navbar.recent-activities")}</NavDropdown.Item>
        <NavDropdown.Item href="/alerts">{t("navbar.alerts")}</NavDropdown.Item>
      </NavDropdown> */}
    </Nav>
    <Nav>
      <NavDropdown alignRight title={auth.data.fullName} id="collasible-nav-dropdown">
        <NavDropdown.Item href="/me">{t('navbar.update-info')}</NavDropdown.Item>
        <NavDropdown.Item href="/password">{t('navbar.update-password')}</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="/logout"><b style={{color: '#d9534f'}}>{t('navbar.logout')}</b></NavDropdown.Item>
      </NavDropdown>
    </Nav>
  </Navbar.Collapse>
</Navbar>
    )
}

export default AdminNavbar
