import React, { useState, useEffect } from 'react'
import CreateRoleForm from '../../forms/CreateRoleForm'
import QueryString from 'querystring';
import useDataApi from '../../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert'
import Loading from '../../others/Loading';
import { Card, CardBody, Col } from 'reactstrap';

function CreateCustomerRoleTablePage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [users, setUsers] = useState(null);
	const [role, setRole] = useState(null);
	const [error, setError] = useState("");

	useEffect(() => {
		sendRequest('GET', '/customers-api/users', {}, (result) => {
			setUsers(result);
		});
		const parsed = QueryString.parse(window.location.search.substring(1));
		if (typeof parsed.id !== 'undefined') {
			sendRequest('GET', '/customers-api/roles', null, (response) => {
				try {
					setRole(response.filter(role => role.roleId === parsed.id)[0]);
				} catch (error) {
					setError(t('error.bad-request'))
				}
			});
		}
	}, [])

	return (
		<div className="container">

			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
			{ (window.location.search.length === 0 || role) && users &&
				<Col xs={24} md={24} lg={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h3 className="bold-text">{role ? t('title.update-role') : t('title.create-role')}</h3>
							</div>
							<CreateRoleForm users={users} role={role} />
						</CardBody>
					</Card>
				</Col>
			}
		</div>
	)
}

export default CreateCustomerRoleTablePage;

