import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import useDataApi from '../../hooks/useDataApi';
import { Formik, Form } from "formik";
import Alert from 'react-bootstrap/Alert';
import validate from '../../hooks/useValidation';
import Loading from '../others/Loading';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { tableConfig, showSuccessMessage } from '../../configs/Configs'
import { MyTextField, MyCheckBox } from '../others/FormElements';
import { Button } from 'reactstrap';
import { useHistory } from "react-router-dom";
import PlusIcon from 'mdi-react/PlusIcon';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import Chip from "@material-ui/core/Chip";

function CreateUserForm({ roles, user }) {

	const getInitOrigData = (roles, user) => {
		if (user && user.roles) {
			return roles.filter(role => !user.roles.includes(role.roleId));
		} else {
			return roles
		}
	}

	const getInitDestData = (roles, user) => {
		if (user && user.roles) {
			return roles.filter(role => user.roles.includes(role.roleId));
		} else {
			return [];
		}
	}


	const { t } = useTranslation();
	const history = useHistory();
	const [requestState, sendRequest] = useDataApi();
	const [origData, setOrigData] = useState(() => getInitOrigData(roles, user));
	const [destData, setDestData] = useState(() => getInitDestData(roles, user));

	const onClickButton = (direction, roleId, rows) => {
		let copyDataSource = (direction === 0 ? [...origData] : [...destData]);
		let copyDataDest = (direction === 0 ? [...destData] : [...origData]);
		var selectedData;

		if (roleId !== "all") {
			selectedData = copyDataSource.filter(obj => obj.roleId === roleId);
			copyDataSource = copyDataSource.filter(obj => obj.roleId !== roleId);
		}
		else {
			selectedData = rows.map(row => row.values);
			var selectedRoles = selectedData.map(data => data.roleId);
			copyDataSource = copyDataSource.filter(obj => !selectedRoles.includes(obj.roleId));
		}
		copyDataDest.push.apply(copyDataDest, selectedData);

		if (direction === 0) {
			setOrigData(copyDataSource);
			setDestData(copyDataDest);
		}
		else {
			setDestData(copyDataSource);
			setOrigData(copyDataDest);
		}
	};

	const sourceColumns = useMemo(
		() => [
			{
				Header: ({ rows }) => (<Button size="sm"
					onClick={() => {
						onClickButton(0, "all", rows)
					}}><PlusCircleOutlineIcon />
				</Button>),
				accessor: 'roleId',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<Button size="sm"
						onClick={() => {
							onClickButton(0, value)
						}}><PlusIcon />
					</Button>)
			},
			{
				Header: () => (t('label.name')),
				accessor: 'name',
			},
			{
				Header: () => (t('label.description')),
				accessor: 'description',
			},
		],
		[origData, destData],
	);

	return (
		<Formik
			initialValues={user ?? { fullName: "", email: "" }}
			validateOnChange={true}
			validateOnMount={true}
			validate={validate}
			onSubmit={(data, { setSubmitting, resetForm }) => {
				setSubmitting(true);
				data.email = data.email.toLowerCase();
				let newUser = { ...data, roles: destData.map(row => row.roleId), rolesNames: destData.map(row => row.name), oldName: (user ? user.fullName : data.fullName) };
				const apiRequest = (user ? `/customers-api/users/${user.userId}/info` : `/customers-api/users/${window.location.hostname}`);
				sendRequest((user ? 'PUT' : 'POST'), apiRequest, newUser, (response) => {
					setSubmitting(false);
					if (user) {
						history.goBack()
					} else {
						showSuccessMessage(t('label.user-created'));
						resetForm();
						setOrigData(roles);
						setDestData([]);
					}
				});
				setTimeout(() => {
					setSubmitting(false);
				}, 3000);
			}}
		>
			{({ values, errors, isSubmitting }) => (
				<Form>
					<div style={{ display: 'flex' }}>
						<div className="form__half">
							<MyTextField name="fullName" />
						</div>
						<div className="form__half">
							<MyTextField name="email" readOnly={user ? true : false} />
							{!user && <MyCheckBox name="isolated" bold />}
							{values.isolated &&
								<div style={{ marginLeft: '30px' }}>
									<MyTextField name="password" inputType="password" />
								</div>}
						</div>
					</div>
					<br /><br />
					<Card style={{ border: '2px solid white' }}>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">{t('label.added-roles')}</h5>
							</div>

							{destData.map((item, index) => (
								<>
								<Chip									
									id={"role"+index}
									label={item.name}
									clickable
									color="primary"
									onClick={() => null}
									onDelete={() => onClickButton(1, item.roleId)}
									style={{
										padding: 16,
										margin: 16
									}}
								/>
								<UncontrolledTooltip placement="bottom" target={"role"+index}>{item.description}</UncontrolledTooltip>
								</>
							))}


							<br /><br />

							<div className="card__title">
								<h5 className="bold-text">{t('label.avail-roles')}</h5>
							</div>
							<ReactTableBase
								columns={sourceColumns}
								data={origData}
								tableConfig={tableConfig}
							/>
						</CardBody>
					</Card>
					<br />
					<div>
						{requestState.isLoading && <Loading />}
						{requestState.isError &&
							<Alert variant="danger">
								<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
								<p>{t(requestState.ErrorText)}</p>
							</Alert>
						}
						<p>{errors[0]}</p>
						<button className="btn btn-primary" disabled={isSubmitting || Object.keys(errors).length > 0}>
							{t('btn.submit')}
						</button>
						<button type="button" className="btn btn-primary" disabled={isSubmitting} onClick={() => { history.goBack() }}>
							{t('btn.cancel')}
						</button>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default CreateUserForm