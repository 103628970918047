import React from 'react'

import IntegratorNavbar from '../../navigations/IntegratorNavbar';

import IntegratorDashboardPage from './IntegratorDashboardPage';
import UpdateIntegratorInfoPage from './UpdateIntegratorInfoPage'
import UpdateIntegratorPasswordPage from './UpdateIntegratorPasswordPage';
import IntegratorCustomersTablePage from './IntegratorCustomersTablePage';
import CreateIntegratorCustomerPage from './CreateIntegratorCustomerPage';
import UpdateIntegratorCustomerPage from './UpdateIntegratorCustomerPage';
import AutoLogoutTimer from '../../others/AutoLogoutTimer';

export const IntegratorPages = {
    IntegratorDashboardPage,
    UpdateIntegratorInfoPage,
    UpdateIntegratorPasswordPage,
    IntegratorCustomersTablePage,
    CreateIntegratorCustomerPage,
    UpdateIntegratorCustomerPage,
}

function IntegratorLayout({component: Component, noNavBar, ...rest}) {
    return (
        <div>
            {(!noNavBar) && <IntegratorNavbar/>}
            <div className="container theme-dark ltr-support" style={{marginTop: '50px'}}>
                <AutoLogoutTimer ComposedClass={<Component {...rest}/>}/>
            </div>
        </div>
    )
}

export default IntegratorLayout
