import React from 'react'
import UpdateIntegratorCustomerForm from '../../forms/UpdateIntegratorCustomerForm';
import { useTranslation } from "react-i18next";

function UpdateIntegratorCustomerPage(props) {
    const { t } = useTranslation();    
    return (
        <div>
            <div className="container">
            <div className="card col-lg-12" style={{marginTop: '50px', paddingTop: '20px'}}>
                <article className="card-body">
                    <h4 className="card-title mb-4 mt-1">{t("title.update-info")}</h4>
                        <UpdateIntegratorCustomerForm {...props}/>
                    </article>
                </div>
            </div>
        </div>
    )
}

export default UpdateIntegratorCustomerPage
