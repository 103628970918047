import React, { useContext } from "react";
import AuthContext from '../../contexts/AuthContext';
import useDataApi from '../../hooks/useDataApi';
import { Formik, Form } from "formik";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from '../others/Loading';
import { MyTextField } from '../others/FormElements';
import {
	Card, CardBody
} from 'reactstrap';

const RegularLoginForm = () => {
	const { t } = useTranslation();
	const { AuthLogin, auth } = useContext(AuthContext);
	const [requestState, sendRequest] = useDataApi();
	const history = useHistory();

	return (
		<div>
			{auth.isLogged && <Redirect to="/" />}
			<Formik
				initialValues={{
					username: "",
					password: "",
					identifier: ""
				}}
				validateOnChange={true}
				validateOnMount={true}
				validate={()=>{}}
				onSubmit={(data, { setSubmitting }) => {
					setSubmitting(true);
					sendRequest('POST', '/auth/login', data, (result) => {
						if (!result.data.twoFA) {
							AuthLogin(result)
							setSubmitting(false);
						} else {
							history.push("/twofa")
						}
					})
					setTimeout(() => {
						setSubmitting(false);
					}, 3000);
				}}
			>
				{({ isSubmitting }) => (
					<Form>
						<MyTextField noTooltip={true} complete="on" name="identifier" />
						<MyTextField noTooltip={true} complete="on" name="username" />
						<MyTextField noTooltip={true} inputType={"password"} complete="off" name="password" />
						<div>
							<button className="btn btn-primary" disabled={isSubmitting} type="submit" color="primary">
								{t('btn.login')}
							</button>
						</div>
					</Form>
				)}
			</Formik>
			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Card style={{ border: '2px solid white' }}>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">{t("label.error-occured")}</h5>
							<h5 className="bold-text">{t(requestState.ErrorText)}</h5>
						</div>
					</CardBody>
				</Card>
			}
		</div>
	)
}

export default RegularLoginForm
