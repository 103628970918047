import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert';
import { Card, CardBody, Col } from 'reactstrap';
import ShieldsListTable from '../../tables/ShieldsListTable'

import Loading from '../../others/Loading';

function CustomerShieldsTablePage(props) {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();

	useEffect(() => {
		sendRequest('GET', '/customers-api/shields')
	}, [])
	return (
		<div className="container">
			<h4>{t("title.shield-table")}</h4>
			<div style={{ height: "70px" }}>
				<a href="/addshield" className="btn btn-primary float-right">{t("btn.add-shield")}</a>
			</div>

			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
			{ requestState.response && (
				requestState.response.length
					?
					<Col xs={24} md={24} lg={12}>
						<Card>
							<CardBody>
								<ShieldsListTable data={{ data: requestState.response }} filter='all' />
							</CardBody>
						</Card>
					</Col>
					: <p>{t('label.no-shields-yet')}</p>
			)}
		</div>
	)
}

export default CustomerShieldsTablePage;