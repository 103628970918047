import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useDataApi from '../../hooks/useDataApi'
import AuthContext from '../../contexts/AuthContext';
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { DefaultColumnFilter, tableConfig, printDate } from '../../configs/Configs'
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import QueryString from 'querystring';

const CustomerMonitoringLogsTable = (adminEmail) => {
	const [requestState, sendRequest] = useDataApi();
	const [logData, setLogData] = useState([]);

	const { auth } = useContext(AuthContext);
	const [filterTimeModalData, setFilterTimeModalData] = useState({
		display: false,
	})
	const [filterTime, setFilterTime] = useState({ startDate: addDays(new Date(), -7), endDate: new Date() })
	const [customFilterTime, setCustomFilterTime] = useState({ startDate: addDays(new Date(), -7), endDate: new Date(), key: 'selection' })
	const [untilNow, setUntilNow] = useState(true);
	const [firstCall, setFirstCall] = useState(true);

	const applyFilterTime = () => {
		setFilterTimeModalData({ display: false });
		setFilterTime({ startDate: customFilterTime.startDate, endDate: customFilterTime.endDate });
		if (customFilterTime.endDate < new Date()) {
			setUntilNow(false);
		}
	}

	const getMappingUser = () => {
		var mappingUser = {};
		mappingUser[auth.data.adminEmail] = auth.data.fullName
		if (auth.data.superAdmin && auth.data.listAdmins.length > 0) {
			var otherAdmins = JSON.parse(auth.data.listAdmins);
			otherAdmins.forEach(function(admin) {
				mappingUser[admin.adminEmail] = admin.fullName
			})
		}
		return mappingUser;
	}
	
	const getOriginatorParam = () => {
		const parsed = QueryString.parse(window.location.search.substring(1));
		if (typeof parsed.id !== 'undefined') {
			return parsed.id
		}
		return null;
	}

	const [mapping, setMapping] = useState(() => getMappingUser());
	const [originator, setOriginator] = useState(() => getOriginatorParam());
	const { t } = useTranslation();
	const columns = useMemo(
		() => [
			{
				Header: () => (t('label.originator')),
				accessor: 'originator',
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (t('label.operation-type')),
				accessor: 'opType',
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (t('label.artifact-type')),
				accessor: 'senderType',
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (t('label.artifact-name')),
				accessor: 'sender',
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (t('label.time')),
				accessor: 'time',
				Filter: DefaultColumnFilter,
			},
			{
				Header: () => (t('label.message')),
				accessor: 'message',
				Filter: DefaultColumnFilter,
			}
		],
		[],
	);


	const processResults = (result) => {
		console.log(result);
		if (!result || !result.hits || !result.hits.total.value) setLogData([]);
		let FilteredlogsList = [];
		for (var i in result.hits.hits) {
			var _source = result.hits.hits[i]._source;
			let log = {};

			log.originator = mapping[_source["senderId"]];
			log.opType = _source["opType"];
			log.senderType = _source["senderType"];
			log.sender = _source["sender"];
			log.time = moment(_source["@timestamp"]).utc(true).format('DD MMM YYYY HH:mm:ss');
			log.message = _source["message"];
			FilteredlogsList.push(log);
		}
		setLogData(FilteredlogsList);
	}

	const getLogs = () => {
		tableConfig.placeholder = t('label.search');
		var filters = {
			timeStart: filterTime.startDate.getTime(),
			timeEnd: filterTime.endDate.getTime(),
			logTypes: ['Info'],
		}
		if (!auth.data.superAdmin) {
			filters.senderIds = [auth.data.adminEmail];
		}
		else if (originator) {
			filters.senderIds = [originator];
		}
		// Make request to get the logs		
		sendRequest('POST', '/customers-api/monitoring/logs', {
			filters: filters, isRefresh: !firstCall
		}, processResults)

	}

	useEffect(() => {
		getLogs();
		const intervalId = setInterval(() => {
			setFirstCall(false);
			if (untilNow) {
				let copyFilterTime = { ...filterTime };
				let copyCustomFilterTime = { ...customFilterTime };
				copyFilterTime.endDate = new Date();
				copyCustomFilterTime.endDate = copyFilterTime.endDate;
				setFilterTime(copyFilterTime);
				setCustomFilterTime(copyCustomFilterTime);
			}

		}, 15000);
		return (() => {
			clearInterval(intervalId);
		});
	}, [filterTime, firstCall])


	return (
		<div>
			<div className="card__title">
				<h5 className="bold-text">{t("navbar.logs")}</h5>
			</div>
			<div style={{ left: "50%", position: "relative" }}>
				<button className="btn btn-success" onClick={(e) => setFilterTimeModalData({ display: true })}>{t("label.time-filter")}</button>)
				<p>{printDate(customFilterTime.startDate)}-{printDate(customFilterTime.endDate)}</p>
			</div>
			<ReactTableBase
				columns={columns}
				data={logData}
				tableConfig={tableConfig}
			/>
			<Modal show={filterTimeModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("label.set-time")} </h5>
				</Modal.Header>
				<Modal.Body>
					<DateRangePicker
						showSelectionPreview={false}
						ranges={[customFilterTime]}
						onChange={item => setCustomFilterTime(item.selection)}
						direction="horizontal"
						calendarFocus="backwards"
						months={1}
						maxDate={new Date()}
						locale={t.locale}
					/>
				</Modal.Body>
				<Modal.Footer>
					<button type="submit" className="btn btn-warning" onClick={() => applyFilterTime()}>{t("label.set-time")}</button>
					<button type="button" className="btn btn-secondary" onClick={() => setFilterTimeModalData({ display: false })} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</div>
	)
}


export default CustomerMonitoringLogsTable
