import React, { useContext, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import AuthContext from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next'
import Sidebar from './Sidebar'
import TopbarSidebarButton from './TopbarSidebarButton';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import { defaultActions } from '../../configs/Configs';
import { changeDirectionToRTL, changeDirectionToLTR } from '../../redux/actions/rtlActions';
import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';

function CustomerNavbar({ dispatch, sidebar, actions, theme}) {
	const { auth } = useContext(AuthContext);
	const { t } = useTranslation();	
	const sidebarVisibility = () => {
		dispatch(changeSidebarVisibility());
	};
	const isRTL = (auth.data.language === "HE" || auth.data.language === "AR");	
	const isLight = auth.data.theme === "light";
	
	useEffect(() => {
		dispatch(isRTL ? changeDirectionToRTL() : changeDirectionToLTR())
		dispatch(isLight ? dispatch(changeThemeToLight()) : dispatch(changeThemeToDark()))
	}, [])
	
	return (
		<>
			<Navbar collapseOnSelect expand="lg" bg={auth.data.theme} variant={auth.data.theme}>			
				<div className="topbar__left">
					<TopbarSidebarButton
						changeSidebarVisibility={sidebarVisibility}
					/>
					<Link className="topbar__logo" to="/"/>				
			</div>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">					
					<Nav className="ml-auto">						
						{!isRTL && <NavDropdown alignRight renderMenuOnMount={true} title={`${auth.data.customerName}  ${auth.data.fullName}`} id="logout-dropdown">
							<NavDropdown.Item href="/me">{t('navbar.administrators')}</NavDropdown.Item>
							<NavDropdown.Item href="/password">{t('navbar.update-password')}</NavDropdown.Item>							
							<NavDropdown.Divider />
							<NavDropdown.Item href="/logout"><b style={{ color: '#d9534f' }}>{t('navbar.logout')}</b></NavDropdown.Item>
						</NavDropdown>}
						{isRTL && <NavDropdown alignLeft renderMenuOnMount={true} title={`${auth.data.customerName}  ${auth.data.fullName}`} id="logout-dropdown">
							<NavDropdown.Item href="/me">{t('navbar.administrators')}</NavDropdown.Item>
							<NavDropdown.Item href="/password">{t('navbar.update-password')}</NavDropdown.Item>							
							<NavDropdown.Divider />
							<NavDropdown.Item href="/logout"><b style={{ color: '#d9534f' }}>{t('navbar.logout')}</b></NavDropdown.Item>
						</NavDropdown>}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
			<Sidebar sidebar={sidebar} theme={theme.className}/>
		</>
	)
}

export default withRouter(connect(state => ({
	sidebar: state.sidebar, actions: state.createActions.actions, rtl: state.rtl, theme: state.theme
}))(CustomerNavbar));
