import React, { useState, useMemo, useRef} from 'react'
import { useTranslation } from 'react-i18next'
import useDataApi from '../../hooks/useDataApi'
import { Formik, Form } from "formik";
import Alert from 'react-bootstrap/Alert';
import validate from '../../hooks/useValidation';
import Loading from '../others/Loading';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { tableConfig, getSubnetMapping, showSuccessMessage} from '../../configs/Configs'
import { MyTextField, MyCheckBox, MyArrayElements } from '../others/FormElements';
import { Button } from 'reactstrap';
import { useHistory } from "react-router-dom";
import PlusIcon from 'mdi-react/PlusIcon';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import Chip from "@material-ui/core/Chip";

function CreateShieldForm({ deviceGroups, shield, mapping }) {
	
	const getInitOrigData = (deviceGroups, shield) => {
		if (shield && shield.deviceGroups) {
			return deviceGroups.filter(dg => !shield.deviceGroups.includes(dg.deviceGroupId));
		} else {
			return deviceGroups
		}
	}

	const getInitDestData = (deviceGroups, shield) => {
		if (shield && shield.deviceGroups) {
			return deviceGroups.filter(dg => shield.deviceGroups.includes(dg.deviceGroupId));
		} else {
			return [];
		}
	}
	
	const updateInputState = (attrName, value) => {
		var copyInputState = {...inputState};
		copyInputState[attrName] = value;
		setInputState(copyInputState);
		if (attrName === "ip") {			
			formRef.current.setFieldValue("isAutoIP", value);			
		}
	}
	
	const computeExcludeAttrs = (excludeAttrs) => {
		if (!excludeAttrs) 
			excludeAttrs = [];
		for (const [key, value] of Object.entries(inputState)) {
			const index = excludeAttrs.indexOf(key);
			if (value) {			
				if (index > -1)
					excludeAttrs.splice(index, 1); 
			} else {
				if (index == -1)
					excludeAttrs.push(key);
			}
		}
		return excludeAttrs;
	}

	const { t } = useTranslation();
	const history = useHistory();
	const [requestState, sendRequest] = useDataApi();
	const [origData, setOrigData] = useState(() => getInitOrigData(deviceGroups, shield));
	const [destData, setDestData] = useState(() => getInitDestData(deviceGroups, shield));
	const [inputState, setInputState] = useState({});

	const onClickButton = (direction, deviceGroupId, rows) => {
		let copyDataSource = (direction === 0 ? [...origData] : [...destData]);
		let copyDataDest = (direction === 0 ? [...destData] : [...origData]);
		var selectedData;

		if (deviceGroupId !== "all") {
			selectedData = copyDataSource.filter(obj => obj.deviceGroupId === deviceGroupId);
			copyDataSource = copyDataSource.filter(obj => obj.deviceGroupId !== deviceGroupId);
		}
		else {
			selectedData = rows.map(row => row.values);
			var selectedDgs = selectedData.map(data => data.deviceGroupId);
			copyDataSource = copyDataSource.filter(obj => !selectedDgs.includes(obj.deviceGroupId));
		}
		copyDataDest.push.apply(copyDataDest, selectedData);

		if (direction === 0) {
			setOrigData(copyDataSource);
			setDestData(copyDataDest);			
		}
		else {
			setDestData(copyDataSource);
			setOrigData(copyDataDest);			
		}
	};

	const sourceColumns = useMemo(
		() => [
			{
				Header: ({ rows }) => (<Button size="sm"
					onClick={() => {
						onClickButton(0, "all", rows)
					}}><PlusCircleOutlineIcon />
				</Button>),
				accessor: 'deviceGroupId',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<Button size="sm"
						onClick={() => {
							onClickButton(0, value)
						}}><PlusIcon />
					</Button>)
			},
			{
				Header: () => (t('label.name')),
				accessor: 'name',
			},
			{
				Header: () => (t('label.description')),
				accessor: 'description',
			},
		],
		[origData, destData],
	);
	
	const formRef = useRef();
	const finalShield = (shield ? {...shield, mapping: getSubnetMapping(mapping, shield.deviceSubnet, shield.shieldPublicIp), isAutoIP: !shield.excludeAutoDiscoverAttrs.includes("ip")} : null);

	return (
		<div>
			<Formik
				initialValues={finalShield ?? {
					extId: "",
					macDevice: "",
					name: "",
					deviceType: "",
					deviceMacAddress: "",
					shieldExtIp: "",
					vendorId: "",
					modelId: "",
					dhcp: false,
					ip: "",
					publicConnectionCheck: false,
					shieldPublicPort: "",
					shieldPublicIp: "",
					services: [],
					sshPort: 22,
					isAutoIP: 1
				}}
				innerRef={formRef}
				validateOnChange={true}		
				validateOnMount={true}
				validate={validate}
				onSubmit={(data, { setSubmitting, resetForm }) => {
					setSubmitting(true);
					let newShield = { ...data, deviceGroups: destData.map(row => row.deviceGroupId), deviceGroupsNames: destData.map(row => row.name), 
									oldName: (shield ? shield.name : data.name), excludeAutoDiscoverAttrs: computeExcludeAttrs((finalShield ? finalShield.excludeAutoDiscoverAttrs : []))};
					const apiRequest = (shield ? `/customers-api/shields/${shield.extId}/info` : '/customers-api/shields');
					sendRequest((shield ? 'PUT' : 'POST'), apiRequest, newShield, (response) => {
						setSubmitting(false);
						if (shield) {
							history.goBack()
						} else {
							showSuccessMessage(t('label.shield-created'));
							resetForm();
							setOrigData(deviceGroups);
							setDestData([]);
						}
					});
				}}
			>
				{({ values, errors, isSubmitting, setFieldValue, setFieldTouched, touched }) => (
					<Form>
						<Card style={{ border: '2px solid white' }}>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">{t('title.shield-attributes')}</h5>
								</div>
								<div style={{ display: 'flex' }}>
									<div className="form__half">
										<MyTextField name="extId" readOnly={shield ? true : false} bold onChange={(e) => { e.target.value = e.target.value.toUpperCase() }} />
									</div>
									<div className="form__half">
										<MyTextField name="macDevice" readOnly={shield ? true : false} bold onChange={(e) => { e.target.value = e.target.value.toUpperCase() }} />
										<MyTextField name="shieldExtIp" readOnly={true} />
									</div>
								</div>
							</CardBody>
						</Card>
						<br /><br />
						<Card style={{ border: '2px solid white' }}>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">{t('title.device-protected-attributes')}</h5>
								</div>
								<div style={{ display: 'flex' }}>
									<div className="form__half">
										<MyTextField name="name" />
										<MyTextField name="vendorId" readOnly={(!shield || !shield.excludeAutoDiscoverAttrs.includes("vendorId"))} toggleReadOnly="true" callback={updateInputState}/>
										<MyTextField name="modelId" readOnly={(!shield || !shield.excludeAutoDiscoverAttrs.includes("modelId"))} toggleReadOnly="true" callback={updateInputState}/>
										<MyTextField name="deviceType" />										
									</div>
									<div className="form__half">
										
										<MyTextField name="deviceMacAddress" readOnly={(!shield || !shield.excludeAutoDiscoverAttrs.includes("deviceMacAddress"))} toggleReadOnly="true" callback={updateInputState}/>
										<MyTextField name="deviceSubnet" readOnly={(!shield || !shield.excludeAutoDiscoverAttrs.includes("deviceSubnet"))} toggleReadOnly="true" callback={updateInputState}/>
										<MyTextField name="mapping" readOnly={true}/>
										<MyCheckBox name="dhcp" bold />
										<MyTextField name="ip" readOnly={(!shield || !shield.excludeAutoDiscoverAttrs.includes("ip"))} toggleReadOnly="true" callback={updateInputState}/>
										<MyTextField name="isAutoIP" inputType="hidden"/>
										<MyCheckBox name="ssh" bold />
										{values.ssh &&
											<div style={{ marginLeft: '30px' }}>
												<MyTextField name="sshUser" />
												<MyTextField name="sshPort" inputType="number" />
											</div>
										}
										<MyCheckBox name="publicConnection" bold />
										{values.publicConnection &&
											<div style={{ marginLeft: '30px' }}>
												<MyTextField name="shieldPublicIp" readOnly={(!shield || !shield.excludeAutoDiscoverAttrs.includes("shieldPublicIp"))} toggleReadOnly="true" callback={updateInputState}/>
												<MyTextField name="shieldPublicPort" inputType="number" />
												<MyArrayElements
													value={values["services"]}
													onChange={setFieldValue}
													onBlur={setFieldTouched}
													error={errors["services"]}
													touched={touched["services"]}
													name={"services"}
													inputType="number"
													itemKeyName="publicPort"
													itemValueName="privatePort"
												/>
											</div>
										}
									</div>
								</div>
							</CardBody>
						</Card>
						<br /><br />
						<Card style={{ border: '2px solid white' }}>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">{t('label.added-dgs')}</h5>
								</div>
								
								{destData.map((item, index) => (
								<>
								<Chip									
									id={"deviceGroup"+index}
									label={item.name}
									clickable
									color="primary"
									onClick={() => null}
									onDelete={() => onClickButton(1, item.deviceGroupId)}
									style={{
										padding: 16,
										margin: 16
									}}
								/>
								<UncontrolledTooltip placement="bottom" target={"deviceGroup"+index}>{item.description}</UncontrolledTooltip>
								</>
							))}

								<br /><br />

								<div className="card__title">
									<h5 className="bold-text">{t('label.avail-dgs')}</h5>
								</div>
								<ReactTableBase
									columns={sourceColumns}
									data={origData}
									tableConfig={tableConfig}
								/>
							</CardBody>
						</Card>
						<br />
						<div>
							<button className="btn btn-primary" disabled={isSubmitting || Object.keys(errors).length > 0}>
								{t('btn.submit')}
							</button>
							<button type="button" className="btn btn-primary" disabled={isSubmitting} onClick={() => { history.goBack() }}>
								{t('btn.cancel')}
							</button>
						</div>
					</Form>
				)}
			</Formik>

			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</div>
	)
}

export default CreateShieldForm