import React from 'react'
import { useTranslation } from "react-i18next";
import UpdateAdminInfoForm from '../../forms/UpdateAdminInfoForm';

function UpdateAdminInfoPage() {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className="card col-lg-12" style={{marginTop: '50px', paddingTop: '20px'}}>
                <article className="card-body">
                    <h4 className="card-title mb-4 mt-1">{t("title.update-info")}</h4>
                        <UpdateAdminInfoForm/>
                </article>
            </div>
        </div>
    )
}

export default UpdateAdminInfoPage
