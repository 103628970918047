import React from 'react';
import PropTypes from 'prop-types';

const icon = `assets/burger.svg`;

const TopbarSidebarButton = ({ changeSidebarVisibility }) => (
  <div>
    <button className="topbar__button topbar__button--desktop" type="button" onClick={changeSidebarVisibility}>
      <img src={icon} alt="" className="topbar__button-icon" />
    </button>    
  </div>
);

TopbarSidebarButton.propTypes = {
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default TopbarSidebarButton;
