import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
import { Colors } from '../../../configs/Configs'

const getState = (response) => ({
	labels: [
		'Created',
		'Pending',
		'Approved',
		'Active',

	],
	datasets: [{
		data: [
			response.filter(shield => shield.regStatus == 'N/A').length,
			response.filter(shield => shield.regStatus == 'pending').length,
			response.filter(shield => shield.regStatus == 'approved').length,
			response.filter(shield => shield.regStatus == 'active').length
		],
		backgroundColor: [
			Colors.blue,
			Colors.orange,
			Colors.yellow,
			Colors.green
		],
		hoverBackgroundColor: [
			Colors.blue,
			Colors.orange,
			Colors.yellow,
			Colors.green
		],
		borderColor: 'rgba(255,255,255,0.54)',
	}],
});

const CustomerShieldsStatusChart = (props) => {
	const { t } = useTranslation();
	const progress = props.progress(props.data);

	return (
		<Col xs={12} md={12} lg={4}>
			{ progress < 100 &&
				<Card>
					<CardBody>
						<div className="card__title">
							<h3 className="bold-text">{t('title.shield-status-table')}</h3>
						</div>
						<Doughnut data={getState(props.data)}/>
					</CardBody>
				</Card>
			}
		</Col>
	);
};

export default CustomerShieldsStatusChart;
