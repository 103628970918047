import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert'
import UsersListTable from '../../tables/UsersListTable'
import { Card, CardBody, Col } from 'reactstrap';
import Loading from '../../others/Loading';

function CustomerUsersTablePage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	useEffect(() => {
		sendRequest('GET', '/customers-api/users')
	}, [])
	return (
		<div className="container">
			<h4>{t("title.user-table")}</h4>
			<div style={{ height: "70px" }}>
				<a href="/adduser" className="btn btn-primary float-right">{t("btn.add-user")}</a>
			</div>


			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
			{ requestState.response && (
				requestState.response.length > 0
					?
					<Col xs={24} md={24} lg={12}>
						<Card>
							<CardBody>
								<UsersListTable data={{ data: requestState.response }} />
							</CardBody>
						</Card>
					</Col>
					: <p>{t('label.no-users-yet')}</p>
			)}
		</div>
	)
}

export default CustomerUsersTablePage;

