import React, { useMemo } from 'react';
import AuthContext, { useAuthContext } from './contexts/AuthContext';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from "react-redux";
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form';
import { themeReducer, sidebarReducer, createActionsReducer, rtlReducer,
 } from './redux/reducers/index';

import useRenders from './renders/Renders';

import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/app.scss';
import './scss/icon-font.min.css';



function App() {
	const { auth, AuthLogin, AuthLogout, AuthDataChanged } = useAuthContext()
	const providerValue = useMemo(() => ({ auth, AuthLogin, AuthLogout, AuthDataChanged }), [auth, AuthLogin, AuthLogout, AuthDataChanged])

	const Renders = useRenders(auth, AuthLogout)
	const reducers = combineReducers({ form: formReducer, theme: themeReducer, sidebar: sidebarReducer, createActions: createActionsReducer, rtl: rtlReducer
	 });
	const store = createStore(reducers);

	return (
		<Provider store={store}>
			<AuthContext.Provider value={providerValue}>
				<BrowserRouter>
					<Switch>						
							{/* User routes */}

							{/* Customer routes */}
							<Route path="/shields" exact render={(props) => Renders.CustomerNavigationTablePage(props)} />

							<Route path="/addshield" exact render={Renders.CreateCustomerShieldPage} />
							<Route path="/editshield" exact render={Renders.UpdateCustomerShieldPage} />
							<Route path="/shieldsstatus" exact render={Renders.CustomerShieldsStatusTablePage} />
							<Route path="/usersstatus" exact render={Renders.CustomerUsersStatusTablePage} />
							<Route path="/users" exact render={(props) => Renders.CustomerNavigationTablePage(props)} />
							<Route path="/adduser" exact render={Renders.CreateCustomerUserPage} />
							<Route path="/addoccasuser" exact render={Renders.CreateCustomerOccasUserPage} />
							<Route path="/edituser" exact render={Renders.UpdateCustomerUserPage} />
							<Route path="/roles" exact render={(props) => Renders.CustomerNavigationTablePage(props)} />
							<Route path="/devicegroups" exact render={(props) => Renders.CustomerNavigationTablePage(props)} />
							<Route path="/policies" exact render={(props) => Renders.CustomerNavigationTablePage(props)} />							
							<Route path="/activities" exact render={Renders.CustomerMonitoringActivitiesPage} />
							<Route path="/alerts" exact render={Renders.CustomerMonitoringAlertsPage} />
							<Route path="/logs" exact render={Renders.CustomerMonitoringLogsPage} />
							<Route path="/addrole" exact render={Renders.CreateCustomerRoleTablePage} />
							<Route path="/adddevicegroup" exact render={Renders.CreateCustomerDeviceGroupTablePage} />
							<Route path="/addpolicy" exact render={Renders.CreateCustomerPolicyTablePage} />
							<Route path="/mapsubnet" exact render={Renders.MapCustomerSubnetTablePage} />
							<Route path="/adddevicetype" exact render={Renders.CreateCustomerDeviceTypePage} />
							<Route path="/updatecustomer" exact render={Renders.UpdateCustomerPage} />

							{/* Integrator routes */}
							<Route path="/customers" exact render={Renders.IntegratorCustomersTablePage} />
							<Route path="/addcustomer" exact render={Renders.CreateIntegratorCustomerPage} />
							<Route path="/editcustomer" exact render={Renders.UpdateIntegratorCustomerPage} />

							{/* Admin routes */}
							<Route path="/integrators" exact render={Renders.IntegratorsTablePage} />
							<Route path="/addintegrator" exact render={Renders.CreateIntegratorPage} />
							<Route path="/editintegrator" exact render={Renders.UpdateIntegratorPage} />
							<Route path="/versions" exact render={Renders.ModuleVersionsPage} />
							<Route path="/translations" exact render={Renders.TranslationsPage} />

							{/* Mails routes */}
							<Route path="/frommail/:mailType" render={Renders.FromMailPage} />

							{/* Everyone routes */}
							<Route path="/" exact render={Renders.DashboardPage} />
							<Route path="/me" exact render={Renders.MyInfoPage} />
							<Route path="/password" exact render={Renders.ResetPasswordPage} />
							<Route path="/login" exact render={Renders.LoginPage} />
							<Route path="/twofa" exact render={Renders.TwoFAPage} />
							<Route path="/logout" exact render={Renders.Logout} />
							<Route path="/notfound" render={Renders.NotFoundPage} />
							<Route path='*' render={Renders.UndefinedLink} />						
					</Switch>
				</BrowserRouter>
			</AuthContext.Provider>
		</Provider>
	);
}

export default App;
