import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert';
import ShieldStatusListTable from '../../tables/ShieldStatusListTable'
import { Card, CardBody, Col } from 'reactstrap';
import QueryString from 'querystring';
import { dashboardFilters } from '../../../configs/Configs';

function CustomerShieldsStatusTablePage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [users, setUsers] = useState(null);
	const [devices, setDevices] = useState(null);
	const [firstCall, setFirstCall] = useState(true);
	const parsed = QueryString.parse(window.location.search.substring(1));
		
	const getShieldsAndUsers = () => {	
		sendRequest('GET', '/customers-api/users', {isRefresh: !firstCall}, (result) => {
			setUsers(result);
		})		
		sendRequest('GET', '/customers-api/shields', {isRefresh: !firstCall}, (result) => {
			if (typeof parsed.filter !== 'undefined') {
				setDevices(dashboardFilters["shields"][parsed.filter](result));
			} else {
				setDevices(result);
			}
		})			
	}
	
	useEffect(() => {
		getShieldsAndUsers();			
		const intervalId = setInterval(() => {
			setFirstCall(false);					
			getShieldsAndUsers();		
		}, 15000);
		return (() => {
			clearInterval(intervalId);
		})
	}, [firstCall]);
	
	return (
		<div className="container">
						
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
			{users !== null && devices !== null && devices.length > 0 &&					
					<Col xs={24} md={24} lg={12}>
						<Card>
							<CardBody>
								<ShieldStatusListTable devices={devices} users={users} />
							</CardBody>
						</Card>
					</Col>}
			{devices !== null && devices.length == 0 && <p>{t('label.no-shields-yet')}</p>}		
		</div>
	)
}

export default CustomerShieldsStatusTablePage;