import React, { useState, useEffect } from 'react'
import UpdateShieldForm from '../../forms/UpdateShieldForm';
import QueryString from 'query-string';
import useDataApi from '../../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import Loading from '../../others/Loading';

function UpdateCustomerShieldPage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [shield, setShield] = useState(null)


	useEffect(() => {
		const parsed = QueryString.parse(window.location.search);
		sendRequest('GET', `/customers-api/shields/${parsed.extId}/info`, null, (response) => {
			setShield(response);
		});
	}, [])



	return (
		<div>
			{shield &&
				<div className="container">
					<div className="card col-lg-10">
						<article className="card-body">
							<UpdateShieldForm shield={shield} />
						</article></div>/</div>}
			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</div>
	)
}

export default UpdateCustomerShieldPage
