import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert'
import ModuleVersionsTable from '../../tables/ModuleVersionsTable'
import Loading from '../../others/Loading';

function ModuleVersionsPage() {
    const { t } = useTranslation();
    const [requestState, sendRequest] = useDataApi();
    
    useEffect(()=> {
        sendRequest('GET', '/admins-api/versions/planning')
    }, [])
    return (
        <div className="container">
            <h4>{t("title.versions-table")}</h4>
            
            {requestState.isLoading && <Loading/>}
            {requestState.isError &&
                <Alert variant="danger">
                <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
                <p>{t(requestState.ErrorText)}</p>
                </Alert>
            }
            { requestState.response 
                ? <ModuleVersionsTable data={{data: requestState.response}}/>
                : <p>{t('label.no-versions-yet')}</p>
            }
        </div>
    )  
}

export default ModuleVersionsPage;

