import React from 'react'
import { useTranslation } from 'react-i18next'
import UpdateIntegratorPasswordForm from '../../forms/UpdateIntegratorPasswordForm'

function UpdateIntegratorPasswordPage() {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className="card col-lg-12" style={{marginTop: '50px', paddingTop: '20px'}}>
                <article className="card-body">
                    <h4 className="card-title mb-4 mt-1">{t("title.update-password")}</h4>
                        <UpdateIntegratorPasswordForm/>
                </article>
            </div>
        </div>
    )
}

export default UpdateIntegratorPasswordPage
