import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useDataApi from '../../hooks/useDataApi'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { Colors, getFromCookie, tableConfig, detailsTableConfig, printDate } from '../../configs/Configs'
import { addDays } from 'date-fns';
import { Button, ButtonGroup, ButtonToolbar, UncontrolledTooltip } from 'reactstrap';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import AuthContext from '../../contexts/AuthContext';

const CustomerMonitoringAlertsTable = ({ type, params, users, devices }) => {
	const [requestState, sendRequest] = useDataApi();
	const [logData, setLogData] = useState([]);
	const [logDetailsData, setLogDetailsData] = useState([]);
	const [logDetailsModalData, setLogDetailsModalData] = useState({
		display: false,
	})
	const [filterTimeModalData, setFilterTimeModalData] = useState({
		display: false,
	})
	const [filterTime, setFilterTime] = useState({ startDate: (params && params.startDate ? new Date(parseInt(params.startDate)) : addDays(new Date(), -7)), endDate: (params && params.endDate ? new Date(parseInt(params.endDate)) : new Date()) })
	const [customFilterTime, setCustomFilterTime] = useState({ startDate: (params && params.startDate ? new Date(parseInt(params.startDate)) : addDays(new Date(), -7)), endDate: (params && params.endDate ? new Date(parseInt(params.endDate)) : new Date()), key: 'selection' })
	const [untilNow, setUntilNow] = useState(true);
	const { auth } = useContext(AuthContext);
	const [firstCall, setFirstCall] = useState(true);

	const applyFilterTime = () => {
		setFilterTimeModalData({ display: false });
		setFilterTime({ startDate: customFilterTime.startDate, endDate: customFilterTime.endDate });
		if (customFilterTime.endDate < new Date()) {
			setUntilNow(false);
		}
	}

	const displayDetails = (value) => {
		prepareDetails(value);
		setLogDetailsModalData({
			display: true,
		})
	}
	const PDF = '\u202C';

	const ValueFormatter = ({ value }) => {
		let stringValue = String(value);
		if (stringValue.endsWith(PDF))
			return (<span style={{ color: Colors.red }}>{stringValue.replaceAll(PDF, '')}</span>)
		else {
			return stringValue.replaceAll(PDF, '')
		}
	}

	const formatNameAndIP = (senderId, sender, ip) => {
		if (sender === "agent") {
			var theUser = users.filter(user => user.userId === senderId)[0];
			if (typeof theUser !== 'undefined') {
				return theUser.fullName + (typeof ip !== "undefined" && ip !== "null" ? "/" + ip : "");
			}
		}
		else if (sender === "shield") {
			var theShield = devices.filter(device => device.extId === senderId)[0];
			if (typeof theShield !== 'undefined') {
				return theShield.name + (typeof ip !== "undefined" && ip !== "null" ? "/" + ip : "");
			}
		}
		return "";
	}
	
	const displaySourceAddr = (alarm) => {
		if (alarm.country_name)
			return alarm.sAddr + " (" + alarm.country_name + ")";
		else 
			return alarm.sAddr;
	}

	const prepareDetails = (value) => {
		let theLog = JSON.parse(value);
		let logDetails = [];
		const names = new Set();
		const skipFields = ["@version", "headers", "host", "senderId", "customerId", "shieldId", "customerName"];
		for (var i in theLog) {
			let detail = {};
			if (skipFields.includes(i)) {
				continue;
			}
			if (i === "additionalInformation") {
				for (var j in theLog.additionalInformation) {
					if (skipFields.includes(j)) {
						continue;
					}
					if (!names.has(j)) {
						let detail2 = {};
						detail2.name = j;
						if (Array.isArray(theLog.additionalInformation[j])) {
							detail2.value = JSON.stringify(theLog.additionalInformation[j]);
						} else {
							detail2.value = theLog.additionalInformation[j];
						}
						names.add(j);
						logDetails.push(detail2);
					}
				}
				continue;
			}
			else if (i === "ports") {
				detail.value = theLog.ports.join(',');
				detail.name = i;
			}
			else {
				if (i === "@timestamp") {
					detail.name = t('log.time');
				} else {
					detail.name = i;
				}
				detail.value = theLog[i];
			}
			if (!names.has(detail.name)) {
				names.add(detail.name);
				logDetails.push(detail);
			}
		}
		setLogDetailsData(logDetails);
	}

	const { t } = useTranslation();
	const columns = useMemo(
		() => [
			{
				Header: () => (t('label.log-type')),
				accessor: 'type',
				Cell: ValueFormatter,
			},
			{
				Header: () => (t('label.sender')),
				accessor: 'sender',
				Cell: ValueFormatter,
			},
			{
				Header: () => (t('label.senderId')),
				accessor: 'senderId',
				Cell: ValueFormatter,
			},
			{
				Header: () => (t('label.time')),
				accessor: 'time',
				Cell: ValueFormatter,
			},
			{
				Header: () => (t('label.message')),
				accessor: 'message',
				Cell: ValueFormatter,
			},
			{
				Header: () => (t('label.action')),
				accessor: (originalRow, rowIndex) => originalRow,
				id: 'alert-actions',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<ButtonToolbar>
						<ButtonGroup className="btn-group--icons" dir="ltr">
							<Button id="details" outline onClick={() => displayDetails(value.details)}><span className="lnr lnr-list" /></Button>
							{type === "alerts" && <Button id="dismiss" outline onClick={() => dismissAlert(value)}><span className="lnr lnr-thumbs-up" /></Button>}
							<UncontrolledTooltip placement="bottom" target="details">{t("label.details")}</UncontrolledTooltip>
							{type === "alerts" && <UncontrolledTooltip placement="bottom" target="dismiss">{t("label.dismiss")}</UncontrolledTooltip>}
						</ButtonGroup>
					</ButtonToolbar>)
			}
		],
		[logData],
	);

	const detailsColumns = useMemo(
		() => [
			{
				Header: () => (t('label.name')),
				accessor: 'name',
			},
			{
				Header: () => (t('label.value')),
				accessor: 'value',
				maxWidth: 200
			},
		],
		[],
	);

	const processResults = (result) => {
		console.log(result);
		if (!result || !result.hits || !result.hits.total.value) setLogData([]);
		let FilteredlogsList = [];
		for (var i in result.hits.hits) {
			var _source = result.hits.hits[i]._source;
			if (type === "alerts" && _source.isDismissed) {
				continue;
			}
			let log = {};
			log.id = result.hits.hits[i]["_id"];
			log.index = result.hits.hits[i]["_index"];
			log.type = _source["logType"];
			log.sender = _source["sender"];
			log.senderId = formatNameAndIP(_source["senderId"], _source["sender"], _source["senderIp"]);
			var now = new Date();
			now.setTime(now.getTime() - (parseInt(getFromCookie("agent_polling_interval")) * 1100));
			var time = moment(_source["@timestamp"]).utc(true).format('DD MMM YYYY HH:mm:ss');

			if (_source["statusStart"] && _source["statusEnd"]) {
				var end = new Date(_source["statusEnd"]);
				var start_tostring = moment(_source["statusStart"]).utc(true).format('DD MMM YYYY HH:mm:ss');
				var end_tostring = moment(_source["statusEnd"]).utc(true).format('DD MMM YYYY HH:mm:ss');

				if (!_source["closed"] && now.getTime() < end.getTime()) {
					log.time = `${t('words.since')} ${start_tostring}`;
				} else {
					log.time = `${start_tostring} - ${end_tostring}`;
				}
			} else {
				log.time = `${time}`;
			}
			if (!_source["ports"] || _source["ports"].length === 0) {
				log.message = _source["message"];
			} else {
				let last = _source["ports"].length - 1;
				log.message = last + 1 + " illegal connection attempts from " + displaySourceAddr(_source);
				if (_source.dAddr === _source["senderIp"]) {
					log.message += " to the protected device"
				}
				_source["message"] = log.message;
			}
			log.details = JSON.stringify(_source);
			if (_source["EasySecLogCode"] === 'security_alert' && type !== 'alerts') {
				log.type += PDF;
				log.sender += PDF;
				log.senderId += PDF;
				log.time += PDF;
				log.message += PDF;
			}
			FilteredlogsList.push(log);
		}
		setLogData(FilteredlogsList);
	}

	const getLogs = () => {
		tableConfig.placeholder = t('label.search');
		// Make request to get the logs
		if (type === 'alerts') {
			console.log(filterTime.startDate);
			sendRequest('POST', '/customers-api/monitoring/security_alerts', {
				filters: {
					logTypes: ['alert'],
					timeStart: filterTime.startDate.getTime(),
					timeEnd: filterTime.endDate.getTime(),
				},
				isRefresh: !firstCall
			}, processResults)
		}
		else {
			sendRequest('POST', '/customers-api/monitoring/activities', {
				filters: {
					timeStart: filterTime.startDate.getTime(),
					timeEnd: filterTime.endDate.getTime(),
				},
				isRefresh: !firstCall
			}, processResults)
		}
	}

	const dismissAlert = (alert) => {
		sendRequest('POST', '/customers-api/monitoring/dismiss_alert', {
			filters: {
				id: alert.id,
				index: alert.index,				
			},
			senderId: auth.data.adminEmail,
			message: alert.message
		});
		setLogData(logData.filter(log => log.id != alert.id || log.index != alert.index));
	}


	useEffect(() => {
		getLogs();
		const intervalId = setInterval(() => {
			setFirstCall(false);
			if (untilNow && (!params || !params.endDate)) {
				let copyFilterTime = { ...filterTime };
				let copyCustomFilterTime = { ...customFilterTime };
				copyFilterTime.endDate = new Date();
				copyCustomFilterTime.endDate = copyFilterTime.endDate;
				setFilterTime(copyFilterTime);
				setCustomFilterTime(copyCustomFilterTime);
			}

		}, 15000);
		return (() => {
			clearInterval(intervalId);
		});
	}, [filterTime, firstCall])


	return (
		<div>
			<div className="card__title">
				{type === 'alerts' ? <h5 className="bold-text">{t("navbar.alerts")}</h5> :
					<h5 className="bold-text">{t("navbar.recent-activities")}</h5>}
			</div>
			<div style={{ left: "50%", position: "relative" }}>
				<button className="btn btn-success" onClick={(e) => setFilterTimeModalData({ display: true })}>{t("label.time-filter")}</button>)
				<p>{printDate(customFilterTime.startDate)}-{printDate(customFilterTime.endDate)}</p>
			</div>
			<ReactTableBase
				columns={columns}
				data={logData}
				tableConfig={tableConfig}
			/>
			<Modal show={logDetailsModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("title.modal-log-details-title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<ReactTableBase
						columns={detailsColumns}
						data={logDetailsData}
						tableConfig={detailsTableConfig}
					/>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-secondary" onClick={() => setLogDetailsModalData({ display: false })} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			<Modal show={filterTimeModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("label.set-time")} </h5>
				</Modal.Header>
				<Modal.Body>
					<DateRangePicker
						showSelectionPreview={false}
						ranges={[customFilterTime]}
						onChange={item => setCustomFilterTime(item.selection)}
						direction="horizontal"
						calendarFocus="backwards"
						months={1}
						maxDate={new Date()}
						locale={t.locale}
					/>
				</Modal.Body>
				<Modal.Footer>
					<button type="submit" className="btn btn-warning" onClick={() => applyFilterTime()}>{t("label.set-time")}</button>
					<button type="button" className="btn btn-secondary" onClick={() => setFilterTimeModalData({ display: false })} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</div>
	)
}


export default CustomerMonitoringAlertsTable
