import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal';
import useDataApi from '../../hooks/useDataApi'
import Alert from 'react-bootstrap/Alert';
import { CustomerStatusOptions } from '../../configs/Configs';
import Select from 'react-select';
import QueryString from 'query-string';
import Loading from '../others/Loading';

function AdminCustomersListTable(props) {
    const { t } = useTranslation();
    const [requestState, sendRequest] = useDataApi();
    const [deleteModalData, setDeleteModalData] = useState({
        display : false,
        customerId : null,
        fullName: null
    })
    const [resetModalData, setResetModalData] = useState({
        display : false,
        adminEmail : null,
        fullName: null,
        customerId: null
    })
    const [successModalData, setSuccessModalData] = useState({
        display : false,
        fullName: null
    })
    const [changeStatusModalData, setChangeStatusModalData] = useState({
        display: false,
        customerId: null,
        oldStatus: null,
        newStatusValue: null,
        newStatusLabel: null
    })

    const changeCustomerStatusConfirmed = (customerId, newStatusValue) => {
        setChangeStatusModalData({display: false})
        sendRequest('PUT', `/admins-api/integrators/${QueryString.parse(window.location.search).integratorId}/customers/${customerId}/status`, {status: newStatusValue, portalUrl: window.location.hostname}, (result)=> {
            window.location.reload();
        })
    }

    const deleteCustomerConfirmed = (e) => {
        setDeleteModalData({display: false})
        sendRequest('DELETE', `/admins-api/integrators/${QueryString.parse(window.location.search).integratorId}/customers/${deleteModalData.customerId}`, null, (result)=> {
            window.location.reload()
        })
    }
    const resetPasswordCustomerConfirmed = (e, adminEmail, fullName) => {
        setDeleteModalData({display: false})
        sendRequest('PUT', `/admins-api/integrators/${QueryString.parse(window.location.search).integratorId}/customers/${resetModalData.customerId}/password/reset`, {portalUrl: window.location.hostname}, (result)=> {
            setSuccessModalData({
                display: true,
                adminEmail: adminEmail,
                fullName:fullName
            })
        })
    }
    return (
        <div>
        <table className="table">
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">{t("table.customer-customerId")}</th>
                <th scope="col">{t("table.customer-fullName")}</th>
                <th scope="col">{t("table.customer-status")}</th>
                <th scope="col">{t("table.customer-shields")}</th>
                <th scope="col">{t("table.customer-reset-password")}</th>
                <th scope="col">{t("table.edit")}</th>
                <th scope="col">{t("table.delete")}</th>
            </tr>
            </thead>
            <tbody>
            {props.data.data.map((customer, index) => 
            <tr key={customer.customerId}>
                <th scope="row">{index + 1}</th>
                <td><b>{customer.customerId}</b></td>
                <td>{customer.fullName}</td>
                <td>
                <Select
                    onChange={(value) => {
                        if(value.value !== customer.status){
                            setChangeStatusModalData(
                                {
                                    display: true,
                                    customerId: customer.customerId,
                                    oldStatus: CustomerStatusOptions.find(e => e.value === customer.status).label,
                                    newStatusValue: value.value,
                                    newStatusLabel: value.label
                            })
                        }
                    }}
                    options={CustomerStatusOptions}
                    value={CustomerStatusOptions.find(e => e.value === customer.status)}
                />
                </td>
                <td>
                    <button
                        className="btn btn-success"
                        onClick={(e) =>{
                            window.location.href = `/shields?integratorId=${QueryString.parse(window.location.search).integratorId}&customerId=${customer.customerId}`
                        }}>
                            {t("table.shields-btn")}
                    </button>
                </td>
                <td>
                    <button className="btn btn-warning" onClick={(e) =>{
                            setResetModalData({
                                display: true,
                                adminEmail: customer.adminEmail,
                                fullName: customer.fullName,
                                customerId: customer.customerId
                            })
                        }}>
                        <b>{t("table.reset-password-btn")}</b>
                    </button>
                </td>
                <td>
                    <button
                        className="btn btn-info"
                        onClick={(e) =>{
                            window.location.href = `/editcustomer?integratorId=${QueryString.parse(window.location.search).integratorId}&customerId=${customer.customerId}`
                        }}>
                            {t("table.edit-btn")}
                    </button>
                </td>
                <td>
                    <button
                        className="btn btn-danger"
                        onClick={(e) =>{
                            setDeleteModalData({
                                display: true,
                                customerId: customer.customerId,
                                fullName: customer.fullName,
                            })
                        }}>
                            {t("table.delete-btn")}
                    </button>
                </td>
            </tr>
            )}
            </tbody>
        </table>
        <Modal show={resetModalData.display}>
            <Modal.Header>
            <h5 className="modal-title">{t("title.modal-reset-customer-title")}</h5>
            </Modal.Header>
            <Modal.Body>
                <p>{t("sentences.password-will-be-reset", {fullName: resetModalData.fullName, email: resetModalData.adminEmail})}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-warning" onClick={(e) => {resetPasswordCustomerConfirmed(e, resetModalData.adminEmail, resetModalData.fullName)}}>{t("btn.confirm-reset-password")}</button>
                <button type="button" className="btn btn-secondary" onClick={() => setResetModalData({display: false})} >{t("btn.modal-close")}</button>
            </Modal.Footer>
        </Modal>

        <Modal show={deleteModalData.display}>
            <Modal.Header>
            <h5 className="modal-title">{t("title.modal-delete-customer-title")}</h5>
            </Modal.Header>
            <Modal.Body>
                <p>{t("sentences.element-will-be-deleted", {elementName: deleteModalData.fullName})}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger" onClick={deleteCustomerConfirmed}>{t("btn.confirm-delete-customer")}</button>
                <button type="button" className="btn btn-secondary" onClick={() => setDeleteModalData({display: false})} >{t("btn.modal-close")}</button>
            </Modal.Footer>
        </Modal>
        <Modal show={successModalData.display}>
            <Modal.Header>
            <h5 className="modal-title">{t("title.modal-success-reset-password-customer-title")}</h5>
            </Modal.Header>
            <Modal.Body>
                    <p>{t("sentences.password-successfuly-reset", {fullName: successModalData.fullName, email: successModalData.adminEmail})}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => window.location.reload()} >{t("btn.modal-close")}</button>
            </Modal.Footer>
        </Modal>

        <Modal show={changeStatusModalData.display}>
            <Modal.Header>
            <h5 className="modal-title">{t("title.modal-change-status-customer-title")}</h5>
            </Modal.Header>
            <Modal.Body>
                <p>{t("sentences.status-will-change", {fullName: changeStatusModalData.customerId, oldStatus: changeStatusModalData.oldStatus, newStatus: changeStatusModalData.newStatusLabel})}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger" onClick={(e) => changeCustomerStatusConfirmed(changeStatusModalData.customerId, changeStatusModalData.newStatusValue)}>{t("btn.confirm-change-status-customer")}</button>
                <button type="button" className="btn btn-secondary" onClick={() => setChangeStatusModalData({display: false})} >{t("btn.modal-close")}</button>
            </Modal.Footer>
        </Modal>
        {requestState.isLoading && <Loading/>}
        {requestState.isError &&
            <Alert variant="danger">
            <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
            <p>{t(requestState.ErrorText)}</p>
            </Alert>
        }
      </div>
    )
}

export default AdminCustomersListTable
