import React, { useContext } from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import AuthContext from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next'

function UserNavbar() {
  const { auth } = useContext(AuthContext);
    const { t } = useTranslation();
    return (
<Navbar  collapseOnSelect expand="lg" bg="dark" variant="dark">
<Navbar.Brand href="/">
      <img
        alt=""
        src="favicon.original.ico"
        width="30"
        height="30"
        className="d-inline-block align-top"
      />{' '}
    </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="/">{t('navbar.dashboard')}</Nav.Link>
    </Nav>
    <Nav>
      <NavDropdown alignRight title={auth.data.fullName} id="collasible-nav-dropdown">
        <NavDropdown.Item href="/me">{t('navbar.update-info')}</NavDropdown.Item>
        <NavDropdown.Item href="/password">{t('navbar.update-password')}</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="/logout"><b style={{color: '#d9534f'}}>{t('navbar.logout')}</b></NavDropdown.Item>
      </NavDropdown>
    </Nav>
  </Navbar.Collapse>
</Navbar>
    )
}

export default UserNavbar
