import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert'
import AdminCustomersListTable from '../../tables/AdminCustomersListTable'
import QueryString from 'query-string';
import Loading from '../../others/Loading';

function AdminCustomersTablePage(props) {
    const { t } = useTranslation();
    const [requestState, sendRequest] = useDataApi();
    const [tableData, setTableData] = useState(null);
    useEffect(()=> {
        sendRequest('GET', `/admins-api/integrators/${QueryString.parse(window.location.search).integratorId}/customers`, null, (result)=>{
            setTableData(result);
        })
    }, [])
    return (
        <div className="container">
            <h4>{t("title.customers-table")}</h4>
            <div style={{height: "70px"}}>
                <a href={`/addcustomer?integratorId=${QueryString.parse(window.location.search).integratorId}`} className="btn btn-primary float-right">{t("btn.add-customer")}</a>
            </div>
            
            
            {requestState.isLoading && <Loading/>}
            {requestState.isError &&
                <Alert variant="danger">
                <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
                <p>{t(requestState.ErrorText)}</p>
                </Alert>
            }
            { tableData && (
                tableData.length
                ? <AdminCustomersListTable data={{data: tableData}}/>
                : <p>{t('label.no-customers-yet')}</p>
            )}
        </div>
    )  
}

export default AdminCustomersTablePage;

