import React, { useContext, useEffect } from "react";
import { Container, Col, Card, CardBody } from 'reactstrap';
import AuthContext from '../../contexts/AuthContext';
import useDataApi from '../../hooks/useDataApi';
import { Formik, Form } from "formik";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTimer } from 'react-timer-hook';
import Loading from '../others/Loading';
import { MyTextField } from '../others/FormElements';

function TwoFAPage({expiryTimestamp}) {
	const { t } = useTranslation();
	const { AuthLogin, auth } = useContext(AuthContext);
	const [requestState, sendRequest] = useDataApi();
	const {
    seconds,
    minutes,
	start
  } = useTimer({expiryTimestamp, autoStart: true, onExpire: () => window.location.replace("/login") });
	
	useEffect(() => {
		start();
	}, [])
	
	
	return (
		<Container>
			<Col xs={24} md={24} lg={12}>
				<Card>
					<CardBody>
						<div className="card col-sm-6" style={{ marginTop: '50px', paddingTop: '20px', marginLeft: '350px', backgroundColor: 'white' }}>
							<a href="/" style={{ textAlign: 'center' }}><img alt="EasySec Solutions" src="/assets/logo.jpg" style={{ width: '75%', margin: 'auto' }} /></a>
							<article className="card-body">
								<h4 className="card-title mb-4 mt-1">{t("title.twoFA")}</h4>
								<div>
									{auth.isLogged && <Redirect to="/" />}
									<Formik
										initialValues={{
											digits: ""											
										}}
										validateOnChange={true}
										validateOnMount={true}
										validate={() => { }}
										onSubmit={(data, { setSubmitting }) => {
											setSubmitting(true);
											sendRequest('POST', '/auth/twofa', data, (result) => {
												AuthLogin(result)
												setSubmitting(false);
											})
											setTimeout(() => {
												setSubmitting(false);
											}, 3000);
										}}
									>
										{({ isSubmitting }) => (
											<Form>
												<div style={{fontSize: '20px'}}>
													{t('label.time-remaining')}<span>{minutes}</span>:<span>{seconds}</span>
												</div>
												<br/><br/>
												<MyTextField name="digits" inputType="number" />
												<div>
													<button className="btn btn-primary" disabled={isSubmitting} type="submit" color="primary">
														{t('btn.login')}
													</button>
												</div>
											</Form>
										)}
									</Formik>
									{requestState.isLoading && <Loading />}
									{requestState.isError &&
										<Card style={{ border: '2px solid white' }}>
											<CardBody>
												<div className="card__title">
													<h5 className="bold-text">{t("label.error-occured")}</h5>
													<h5 className="bold-text">{t(requestState.ErrorText)}</h5>
												</div>
											</CardBody>
										</Card>
									}
								</div>
							</article>
						</div>
					</CardBody>
				</Card>
			</Col>
		</Container>
	)
}

export default TwoFAPage
