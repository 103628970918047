
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import useDataApi from '../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import validate from '../../hooks/useValidation';
import { MyTextField, MyCheckBox, MyArrayElements, MyMultiSelect } from '../others/FormElements';
import Alert from "react-bootstrap/Alert";
import QueryString from 'query-string';
import Loading from '../others/Loading';

function UpdateAdminShieldForm({ shield }) {
    const { t } = useTranslation();
    const [users, setUsers] = useState(null);
    const [requestState, sendRequest] = useDataApi();
    useEffect(() => {
        sendRequest('GET', `/admins-api/customers/${QueryString.parse(window.location.search).customerId}/users`, null, (response) => {
            let usersList = []
            response.forEach((user) => {
                usersList.push({
                    value: user.userId,
                    label: user.fullName
                })
            })
            setUsers(usersList)
        })
    }, [])

    return (
        <div>
        {(users !== null) &&
        <Formik
            initialValues={shield}
			validateOnChange={true}
            validateOnMount={true}
            validate={validate}
            onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                sendRequest('PUT', `/admins-api/customers/${QueryString.parse(window.location.search).customerId}/shields/${shield.extId}/info`, data, (response) => {
                    setSubmitting(false);
                    window.location.href = `/shields?integratorId=${QueryString.parse(window.location.search).integratorId}&customerId=${QueryString.parse(window.location.search).customerId}`
                });
            }}
        >
            {({ values, errors, isSubmitting, setFieldValue, setFieldTouched, touched }) => (
                <Form>
                    <MyTextField name="extId" bold readOnly={true} onChange={(e) => { e.target.value = e.target.value.toUpperCase() }} />
                    <MyTextField name="location" />
                    <MyTextField name="vendorId" />
                    <MyTextField name="modelId" />
                    <div className="card" style={{ padding: '10px', marginTop: '30px' }}>
                        <MyCheckBox name="dhcp" bold />
                        <MyTextField name="ip" />
                    </div>
                    <div className="card" style={{ padding: '10px', marginTop: '50px' }}>
                        <MyCheckBox name="publicConnectionAllowed" bold />
                        {values.publicConnectionAllowed &&
                            <div>
                                <MyTextField name="shieldPublicIp" />
                                <MyTextField name="shieldPublicPort" inputType="number" />
                                <div className="card" style={{ padding: '10px', marginTop: '50px' }}>
                                    <MyArrayElements
                                        value={values["services"]}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        error={errors["services"]}
                                        touched={touched["services"]}
                                        name={"services"}
                                        inputType="number"
                                        itemKeyName="publicPort"
                                        itemValueName="privatePort"
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <MyMultiSelect
                        value={values["authUserIdList"]}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors["authUserIdList"]}
                        touched={touched["authUserIdList"]}
                        options={users}
                        name={"authUserIdList"}
                    />
                    <div>
                        <button className="btn btn-primary" disabled={isSubmitting} type="submit">
                            {t('btn.submit')}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>}
        {requestState.isLoading && <Loading/>}
        {requestState.isError &&
            <Alert variant="danger">
            <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
                <p>{t(requestState.ErrorText)}</p>
            </Alert>
        }
        </div>
    )
}

export default UpdateAdminShieldForm