import React, { useState, useEffect } from 'react'
import CreateDeviceTypeForm from '../../forms/CreateDeviceTypeForm'
import useDataApi from '../../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert'
import Loading from '../../others/Loading';
import { Card, CardBody, Col } from 'reactstrap';

function CreateCustomerDeviceTypePage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [devices, setDevices] = useState(null);
	const [mapping, setMapping] = useState(null);	

	useEffect(() => {
		sendRequest('GET', '/customers-api/shields', {}, (result) => {
			setDevices(result);
		});
		sendRequest('GET', '/customers-api/mapping', {}, (result) => {
			setMapping(result);
		});
	}, [])

	return (
		<div className="container">

			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
			{ devices && mapping &&
				<Col xs={24} md={24} lg={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h3 className="bold-text">{t('title.create-device-type')}</h3>
							</div>
							<CreateDeviceTypeForm devices={devices} mapping={mapping} />
						</CardBody>
					</Card>
				</Col>
			}
		</div>
	)
}

export default CreateCustomerDeviceTypePage;

