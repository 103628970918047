import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal';
import useDataApi from '../../hooks/useDataApi'
import Alert from 'react-bootstrap/Alert';
import Loading from '../others/Loading';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { tableConfig } from '../../configs/Configs'

function UsersListTable(props) {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [userData, setUserData] = useState([]);
	const [deleteModalData, setDeleteModalData] = useState({
		display: false,
		userId: null,
		fullName: null
	})
	const [resetModalData, setResetModalData] = useState({
		display: false,
		email: null,
		fullName: null,
		userId: null
	})
	const [successModalData, setSuccessModalData] = useState({
		display: false,
		fullName: null
	})

	const deleteUserConfirmed = (e) => {
		setDeleteModalData({ display: false })
		sendRequest('DELETE', `/customers-api/users/${deleteModalData.userId}`, null, (result) => {
			window.location.reload()
		})
	}
	const resetPasswordUserConfirmed = (e, email, fullName) => {
		setDeleteModalData({ display: false })
		sendRequest('PUT', `/customers-api/users/${resetModalData.userId}/password/reset`, null, (result) => {
			setSuccessModalData({
				display: true,
				email: email,
				fullName: fullName
			})
		})
	}
	const columns = useMemo(
		() => [
			{
				Header: '#',
				accessor: 'id',
			},
			{
				Header: () => (t('table.user-full-name')),
				accessor: 'fullName',
			},
			{
				Header: () => (t('table.user-email')),
				accessor: 'email',
			},
			{
				Header: () => (t('table.user-reset-password')),
				accessor: 'user',
				id: 'reset',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<button className="btn btn-warning" onClick={(e) => {
						setResetModalData({
							display: true,
							email: value.email,
							fullName: value.fullName,
							userId: value.userId
						})
					}}>
						<b>{t("table.reset-password-btn")}</b>
					</button>)
			},
			{
				Header: () => (t('table.edit')),
				accessor: 'user',
				id: 'edit',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<button
						className="btn btn-info"
						onClick={(e) => {
							window.location.href = `/edituser?userId=${value.userId}`
						}}>
						{t("table.edit-btn")}
					</button>),
			},
			{
				Header: () => (t('table.delete')),
				accessor: 'user',
				id: 'delete',
				disableSortBy: true,
				Cell: ({ value }) =>
					(<button
						className="btn btn-danger"
						onClick={(e) => {
							setDeleteModalData({
								display: true,
								userId: value.userId,
								fullName: value.fullName,
							})
						}}>
						{t("table.delete-btn")}
					</button>)
			}
		],
		[],
	);
	
	useEffect(() => {
		tableConfig.placeholder = t('label.search');
		let userDetails = [];
		for (var i in props.data.data) {
			let detail = {};
			detail.id = (parseInt(i) + 1).toString();
			detail.userId = props.data.data[i].userId;
			detail.fullName = props.data.data[i].fullName;
			detail.email = props.data.data[i].email;
			detail.user = props.data.data[i];
			userDetails.push(detail);
		}
		setUserData(userDetails);
	}, [])

	return (
		<div>
			<ReactTableBase
				columns={columns}
				data={userData}
				tableConfig={tableConfig}
			/>
			<Modal show={resetModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("title.modal-reset-user-title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<p>{t("sentences.password-will-be-reset", { fullName: resetModalData.fullName, email: resetModalData.email })}</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-warning" onClick={(e) => { resetPasswordUserConfirmed(e, resetModalData.email, resetModalData.fullName) }}>{t("btn.confirm-reset-password")}</button>
					<button type="button" className="btn btn-secondary" onClick={() => setResetModalData({ display: false })} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>

			<Modal show={deleteModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("title.modal-delete-user-title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<p>{t("sentences.element-will-be-deleted", { elementName: deleteModalData.fullName })}</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-danger" onClick={deleteUserConfirmed}>{t("btn.confirm-delete-user")}</button>
					<button type="button" className="btn btn-secondary" onClick={() => setDeleteModalData({ display: false })} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			<Modal show={successModalData.display}>
				<Modal.Header>
					<h5 className="modal-title">{t("title.modal-success-reset-password-user-title")}</h5>
				</Modal.Header>
				<Modal.Body>
					<p>{t("sentences.password-successfuly-reset", { fullName: successModalData.fullName, email: successModalData.adminEmail })}</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-secondary" onClick={() => window.location.reload()} >{t("btn.modal-close")}</button>
				</Modal.Footer>
			</Modal>
			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
		</div>
	)
}

export default UsersListTable
