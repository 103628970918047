import React, { useContext, useState } from "react";
import PDFFile from '../others/PDFFile';
import Loading from '../others/Loading';
import Alert from 'react-bootstrap/Alert';
import { Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import useDataApi from '../../hooks/useDataApi';
import AuthContext from '../../contexts/AuthContext';
import { useTranslation } from "react-i18next";
import validate from '../../hooks/useValidation';
import { MyTextField, MyCheckBox } from '../others/FormElements';
import { BaseUrls } from '../../configs/Configs'

function NewPasswordForm(props) {
	const initialValues = props.isnew ? {
		password: "",
		confirmPassword: "",
		acceptConditions: false,
		email: props.data.data.adminEmail || props.data.data.email
	} : {
			password: "",
			confirmPassword: "",
			email: props.data.data.adminEmail || props.data.data.email
		}
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const { AuthLogin, auth } = useContext(AuthContext);
	const [showDownload, setShowDownload] = useState(false);

	return (
		<div>
			{showDownload && <Redirect to="/" />}
			<Formik
				initialValues={initialValues}
				validateOnChange={true}
				validateOnMount={true}
				validate={validate}
				onSubmit={(data, { setSubmitting }) => {
					setSubmitting(true);
					sendRequest('PUT', '/tokens-api/newpassword', data, (response) => {
						setSubmitting(false);
						if (props.mailtype === "user-activated") {
							AuthLogin(props.data);
							setShowDownload(true);
						} else {
							window.location.href = '/logout';
						}
					});
					setTimeout(() => {
						setSubmitting(false);
					}, 3000);
				}}
			>
				{({ values, errors, isSubmitting }) => (
					<Form>
						<MyTextField name="password" inputType="password" />
						<MyTextField name="confirmPassword" inputType="password" />
						{props.isnew &&
							<div>
								<div>
									<PDFFile fileUrl={BaseUrls.gateway + "/documents-api/End User Licence Agreements.pdf"} />
								</div>
								<div>
									<MyCheckBox name="acceptConditions" bold />
								</div>
							</div>
						}

						<div>
							{requestState.isLoading && <Loading />}
							{requestState.isError &&
								<Alert variant="danger">
									<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
									<p>{t(requestState.ErrorText)}</p>
								</Alert>
							}
							<div>
								<button className="btn btn-primary" disabled={isSubmitting || Object.keys(errors).length > 0} type="submit">
									{t('btn.submit')}
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	)
}

export default NewPasswordForm