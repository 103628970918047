import React, { useState, useEffect } from 'react'
import QueryString from 'querystring';
import useDataApi from '../../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert'
import Loading from '../../others/Loading';
import { Card, CardBody, Col } from 'reactstrap';
import CreateDeviceGroupForm from '../../forms/CreateDeviceGroupForm'

function CreateCustomerDeviceGroupTablePage() {
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [devices, setDevices] = useState(null);
	const [deviceGroup, setDeviceGroup] = useState(null);
	const [mapping, setMapping] = useState(null);
	const [error, setError] = useState("");

	useEffect(() => {
		sendRequest('GET', '/customers-api/shields', {}, (result) => {
			setDevices(result);
		});
		sendRequest('GET', '/customers-api/mapping', {}, (result) => {
			setMapping(result);
		});
		const parsed = QueryString.parse(window.location.search.substring(1));
		if (typeof parsed.id !== 'undefined') {
			sendRequest('GET', '/customers-api/devicegroups', null, (response) => {
				try {
					setDeviceGroup(response.filter(dg => dg.deviceGroupId === parsed.id)[0]);
				} catch (error) {
					setError(t('error.bad-request'))
				}
			});
		}
	}, [])

	return (
		<div className="container">

			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
			{ (window.location.search.length === 0 || deviceGroup) && devices && mapping &&
				<Col xs={24} md={24} lg={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h3 className="bold-text">{deviceGroup ? t('title.update-devicegroup') : t('title.create-devicegroup')}</h3>
							</div>
							<CreateDeviceGroupForm devices={devices} deviceGroup={deviceGroup} mapping={mapping} />
						</CardBody>
					</Card>
				</Col>
			}
		</div>
	)
}

export default CreateCustomerDeviceGroupTablePage;

