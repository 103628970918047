import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi';
import CustomerMonitoringAlertsTable from '../../tables/CustomerMonitoringAlertsTable'
import { Card, CardBody, Col } from 'reactstrap';
import QueryString from 'querystring';
import Alert from 'react-bootstrap/Alert';

function CustomerMonitoringAlertsPage() {
	const { t } = useTranslation();
	const [params, setParams] = useState(null);
	const [requestState, sendRequest] = useDataApi();
	const [users, setUsers] = useState(null);
	const [devices, setDevices] = useState(null);
	
	useEffect(() => {		
		setParams(QueryString.parse(window.location.search.substring(1)));
		sendRequest('GET', '/customers-api/users', {}, (result) => {
			setUsers(result);
		})
		sendRequest('GET', '/customers-api/shields', {}, (result) => {
			setDevices(result);
		})
	}, [])
	return (
		<div className="container">			
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}

			{users !== null && devices !== null && (window.location.search.length === 0 || params) && <Col xs={24} md={24} lg={12}>
				<Card>
					<CardBody>
						<CustomerMonitoringAlertsTable type='alerts' params={params} users={users} devices={devices}/>
					</CardBody>
				</Card>
			</Col>}
		</div>
	)
}

export default CustomerMonitoringAlertsPage;