import React from 'react'
export const languages = [
	{
		value: "EN",
		label: "English"
	},
	{
		value: "FR",
		label: "Francais"
	},
	{
		value: "HE",
		label: "עברית"
	}
]

// in seconds
export const CookieMaxAge = (userType) => {
	switch (userType) {
		case 'USER':
			return 60 * 60;
		case 'CUSTOMER':
			return 60 * 60 * 24 * 7;
		case 'INTEGRATOR':
			return 60 * 60 * 24 * 7;
		case 'ADMIN':
			return 60 * 60 * 24 * 7;
		default:
			return 60 * 30;
	}
}

export const ComponentsList = [
	"shield"
]
export const MaxShieldStatusAge = 10 * 60 * 1000;

export const BaseUrls = {
	gateway: "https://" + window.location.hostname + "/api"
}

export const CustomerStatusOptions = [
	{
		value: "active",
		label: "Active"
	},
	{
		value: "created",
		label: "Created"
	},
	{
		value: "inactive",
		label: "Inactive"
	}
]
export const IntegratorStatusOptions = [
	{
		value: "active",
		label: "Active"
	},
	{
		value: "created",
		label: "Created"
	},
	{
		value: "inactive",
		label: "Inactive"
	}
]

export const Colors = {
	blue: '#4285F4',
	orange: '#FF5A1E',
	yellow: '#FFE004',
	green: '#34A853',
	red: '#EA4335'
}

export const printDate = (dateString) => {
	var since = new Date(dateString);
	if (isNaN(since.getTime())) {  // d.valueOf() could also work
		// date is not valid
		return dateString;
	} else {
		// date is valid
		return since.toLocaleString(window.navigator.language);
	}
}

export const getFromCookie = (name) => {
	name += "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	var returnVal;
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			returnVal = c.substring(name.length, c.length);
		}
	}
	if (returnVal === "\"\"" || returnVal === "") {
		return null;
	}
	else {
		return returnVal;
	}
}

export const tableConfig = {
	isEditable: false,
	isSortable: true,
	isResizable: true,
	withPagination: true,
	withSearchEngine: true,
	manualPageSize: [10, 20, 30, 40],
};

export const detailsTableConfig = {
	isEditable: false,
	isSortable: true,
	isResizable: true,
	withPagination: true,
	withSearchEngine: false,
};

export const policyActions = { create: "/addpolicy", browse: "/policies", delete: "policies", label: "title.create-policy" };
export const roleActions = { create: "/addrole", browse: "/roles", delete: "roles", label: "title.create-role" };
export const deviceGroupActions = { create: "/adddevicegroup", browse: "/devicegroups", delete: "devicegroups", label: "title.create-devicegroup" };
export const userActions = { create: "/adduser", browse: "/users", delete: "users", label: "title.create-user" };
export const deviceActions = { create: "/addshield", browse: "/shields", delete: "shields", label: "title.create-shield" };
export const defaultActions = [userActions, deviceActions, roleActions, deviceGroupActions, policyActions];

export const DefaultColumnFilter = ({
	column: { filterValue, preFilteredRows, setFilter }
}) => {
	const count = preFilteredRows.length

	return (
		<input
			value={filterValue || ''}
			onChange={e => {
				setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
			}}
			placeholder={`Search ${count} records...`}
		/>
	)
}

export const getSubnetMapping = (mapping, subnet, publicIp) => {

	const myEntry = mapping.filter(item => item.subnet === subnet && item.publicIp === publicIp)[0];
	if (typeof myEntry !== 'undefined' && myEntry.mapping.trim().length > 0) {
		return myEntry.mapping;
	}
	else {
		return subnet;
	}
}

export const computeDateLimits = () => {
	var now = new Date();
	return { min: new Date(), max: now.setMonth(now.getMonth() + 3) };
}

export const filterPassedTime = (time) => {
	const currentDate = new Date();
	const selectedDate = new Date(time);

	return currentDate.getTime() < selectedDate.getTime();
};

export const showSuccessMessage = (message) => {
	let successMessage = document.getElementById("success-message");
	successMessage.innerHTML = message;
	successMessage.style.display = "block";
	setTimeout(function() {
		successMessage.style.opacity = "1";
	}, 10);
	setTimeout(function() {
		successMessage.style.opacity = "0";
	}, 3000);
	setTimeout(function() {
		successMessage.style.display = "none";
	}, 5000);
};

export const month = 1000 * 3600 * 24 * 30;
export const dashboardFilters = {
	"policies": {
		"notused": function(policies) { const now = new Date(); return policies.filter(policy => (now - new Date(policy.lastUpdated) > month)) },
		"active": function(policies) { const now = new Date(); return policies.filter(policy => (policy.numUsages > 0 && now - new Date(policy.lastUpdated) < MaxShieldStatusAge)) }
	},
	"roles": {
		"notempty": function(roles) { return roles.filter(role => (role.users.length > 0)) },
		"affected": function(roles) { return roles.filter(role => (role.policies.length > 0)) }
	},
	"devicegroups": {
		"notempty": function(deviceGroups) { return deviceGroups.filter(dg => (dg.devices.length > 0)) },
		"affected": function(deviceGroups) { return deviceGroups.filter(dg => (dg.policies.length > 0)) }
	},
	"users": {
		"affected": function(users) { return users.filter(user => (user.roles.length > 0)) },
		"notconnected": function(users) { const now = new Date(); return users.filter(user => (now - new Date(user.lastUpdated) > month)) },
		"online": function(users) { return users.filter(user => (user.status === 2 || user.status === 1)) },
		"connected": function(users) { return users.filter(user => (user.status === 2)) }
	},
	"shields": {
		"affected": function(devices) { return devices.filter(device => (device.deviceGroups.length > 0)) },
		"notconnected": function(devices) { const now = new Date(); return devices.filter(device => (now - new Date(device.lastUpdated) > month)) },
		"online": function(devices) { return devices.filter(device => (device.status === 2 || device.status === 1)) },
		"connected": function(devices) { return devices.filter(device => (device.status === 2)) }
	},
};

