import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import XHR from 'i18next-xhr-backend'; // fallback xhr load
import LanguageDetector from 'i18next-browser-languagedetector';
import { BaseUrls } from './configs/Configs';

const languageDetector = new LanguageDetector(null, {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  
  // cache user language on
  caches: ['localStorage', 'cookie'],
});

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(languageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      backends: [
        LocalStorageBackend,  // primary
        XHR,                  // fallback retreive from translation api
      ],
      backendOptions: [
        {
          // prefix for stored languages
          prefix: 'i18next_res_',
          // expiration (default one week)
          // expirationTime: 7*24*60*60*1000,
          // Version applied to all languages, can be overriden using the option `versions`
          // defaultVersion: '',
          // language versions
          // versions: {},
          // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
          store: window.sessionStorage
        },
        {
          // path where resources get loaded from
          loadPath: BaseUrls.gateway + '/translator-api/locales/get/{{lng}}?ns={{ns}}',
          allowMultiLoading: false,

          // path to post missing resources
          addPath: BaseUrls.gateway + '/translator-api/locales/add/{{lng}}?ns={{ns}}',

          // allow cross domain requests
          crossDomain: true,
          // allow credentials on cross domain requests
          withCredentials: true,
          // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
          // queryStringParams: { v: '1.3.5' }
        }
      ]
    },

    useLocalStorage: true,
    localStorageExpirationTime: 86400000, // in ms, default 1 week
    // default language
    fallbackLng: 'EN',

    // send not translated keys to endpoint
    saveMissing: true,

    // Logs info level to console output. Helps finding issues with loading not working.
    // debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: { 
      useSuspense: false //   <---- this will do the magic
    }
  });


export default i18n;
