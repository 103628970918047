import React from 'react'
import { useTranslation } from 'react-i18next'

function AdminDashboardPage() {
    const { t } = useTranslation()
    return (
        <div>
            <h1>{t('label.welcome-to-new-portal')}</h1>
            <br/>
        </div>
    )
}

export default AdminDashboardPage
