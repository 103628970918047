import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi';
import Alert from 'react-bootstrap/Alert'
import QueryString from 'querystring';
import CreatePolicyForm from '../../forms/CreatePolicyForm'
import { Card, CardBody, Col } from 'reactstrap';
import Loading from '../../others/Loading';

function CreateCustomerPolicyTablePage() {
	const [roles, setRoles] = useState([]);
	const [deviceGroups, setDeviceGroups] = useState([]);
	const [policies, setPolicies] = useState(null);
	const [policy, setPolicy] = useState(null);
	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [error, setError] = useState("");

	useEffect(() => {
		sendRequest('GET', '/customers-api/roles', {}, (result) => { setRoles(result) })
	}, [])
	useEffect(() => {
		sendRequest('GET', '/customers-api/devicegroups', {}, (result) => { setDeviceGroups(result) })
	}, [])
	useEffect(() => {
		sendRequest('GET', '/customers-api/policies', {}, (result) => { setPolicies(result) })
	}, [])
	useEffect(() => {
		const parsed = QueryString.parse(window.location.search.substring(1));
		if (typeof parsed.id !== 'undefined') {
			sendRequest('GET', '/customers-api/policies', null, (response) => {
				try {
					setPolicy(response.filter(policy => policy.policyId === parsed.id)[0]);

				} catch (error) {
					setError(t('error.bad-request'))
				}
			});
		}
	}, [])

	return (
		<div className="container">

			{requestState.isLoading && <Loading />}
			{requestState.isError &&
				<Alert variant="danger">
					<Alert.Heading>{t("label.error-occured")}</Alert.Heading>
					<p>{t(requestState.ErrorText)}</p>
				</Alert>
			}
			{ (window.location.search.length === 0 || policy) && roles.length && deviceGroups.length && policies !== null
				&&
				<Col xs={24} md={24} lg={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h3 className="bold-text">{policy ? t('title.update-policy') : t('title.create-policy')}</h3>
							</div>
							<CreatePolicyForm roles={roles} devicegroups={deviceGroups} policies={policies} policy={policy} />
						</CardBody>
					</Card>
				</Col>
			}
			{
				(roles.length === 0 || deviceGroups.length === 0) && <p>{t('label.no-roles-dg-yet')}</p>
			}
		</div>
	)
}

export default CreateCustomerPolicyTablePage;

