import React from "react";
import Alert from 'react-bootstrap/Alert';
import { Formik, Form } from "formik";
import useDataApi from '../../hooks/useDataApi';
import { useTranslation } from "react-i18next";
import validate from '../../hooks/useValidation';
import { languages } from '../../configs/Configs';
import { MyTextField, MySelect } from '../others/FormElements';
import Loading from '../others/Loading';

function CreateIntegratorCustomerForm() {
  const { t } = useTranslation();
  const [requestState, sendRequest] = useDataApi();

  return (
    <Formik
      initialValues={{        
        fullName: "",
        adminEmail: "",
        phone: "",
        address: "",
        language: "EN",
      }}
	  validateOnChange={true}
      validateOnMount={true}
      validate={validate}
      onSubmit={(data, { setSubmitting }) => {	    
        setSubmitting(true);
		data.adminEmail = data.adminEmail.toLowerCase();
        sendRequest('POST', '/integrators-api/customers', data, (response) => {
          setSubmitting(false);
          window.location.href = '/customers'
        });
        setTimeout(() => {
          setSubmitting(false);
        }, 3000);
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, setFieldTouched, touched }) => (
        <Form>   
		  <MyTextField name="customerName" />
		  <MyTextField name="adminEmail" />
		  <MyTextField name="fullName" />          	 
          <MyTextField name="phone" />
          <MyTextField name="address" />
          <MySelect
            value={languages.find(e => e.value === values["language"])}
            onChange={(e, value) => setFieldValue(e, value.value)}
            onBlur={setFieldTouched}
            error={errors["language"]}
            touched={touched["language"]}
            options={languages}
            name={"language"}
        />



          <div>
          {requestState.isLoading && <Loading/>}
          {requestState.isError &&
            <Alert variant="danger">
              <Alert.Heading>{t("label.error-occured")}</Alert.Heading>
              <p>{t(requestState.ErrorText)}</p>
            </Alert>
          }
            <button className="btn btn-primary" disabled={isSubmitting} type="submit">
              {t('btn.submit')}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default CreateIntegratorCustomerForm