import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import useDataApi from '../../../hooks/useDataApi'
import { Card, CardBody, Col } from 'reactstrap';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { addDays, addHours } from 'date-fns';

const CustomerMonitoringAlertsChart = () => {

	const { t } = useTranslation();
	const [requestState, sendRequest] = useDataApi();
	const [data, setData] = useState([]);
	const [numDays, setNumDays] = useState(1);
	const [numAlertsToday, setNumAlertsToday] = useState(0);
	const [filterLabel, setFilterLabel] = useState(t('label.last-day'));


	const getState = (mappedData) => ({
		labels: mappedData.labels,
		datasets: [
			{
				label: t('label.alerts-numbers'),
				backgroundColor: '#FF6384',
				borderColor: '#FF6384',
				borderWidth: 1,
				hoverBackgroundColor: '#FF6384',
				hoverBorderColor: '#FF6384',
				data: mappedData.data,
			},
		],
	});

	const options = {
		legend: {
			position: 'bottom',
		},
		tooltips: {
			mode: 'index',
			callbacks: {
				label: function label(tooltipItem) {
					return +Number(tooltipItem.yLabel) + " alerts";
				}
			}
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						color: 'rgb(204, 204, 204)',
					},
					ticks: {
						fontColor: 'rgb(204, 204, 204)',
						autoSkip: true
					},
				},
			],
			yAxes: [
				{
					gridLines: {
						color: 'rgb(204, 204, 204)',
					},
					ticks: {
						beginAtZero: true, //for ignoring negative values
						callback: function callback(value, index, values) {
							//for ignoring non absolute numbers
							if (Math.floor(value) === value) {
								return value;
							}
						},
						fontColor: 'rgb(204, 204, 204)',
					}
				},
			],
		},
		onClick: function(evt, element) {
			if (element.length > 0) {
				let startDate, endDate, now = new Date();				
				if (numDays == 1) {
					now.setMinutes(0,0,0);
					startDate = addHours(now, -23 + element[0]._index);
					endDate = addHours(now, -22 + element[0]._index);										
				}
				else if (numDays == 7) {
					now.setHours(0,0,0,0);
					startDate = addDays(now, -6 + element[0]._index);
					endDate = addDays(now, -5 + element[0]._index);	
				}
				else if (numDays == 30) {
					now.setHours(0,0,0,0);
					startDate = addDays(now, -29 + element[0]._index);
					endDate = addDays(now, -28 + element[0]._index);	
				}
				window.location.href = "/alerts" + '?startDate=' + startDate.getTime() + '&endDate=' + endDate.getTime();
			}
		},
		onHover: (event, element) => {
			event.target.style.cursor = (element.length > 0 ? 'pointer' : 'default');
		}
	}

	const processResults = (alertsResponse) => {
		var ret = {
			labels: [],
			data: []
		};
		var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

		var tn = new Date();
		if (numDays == 1) {
			setNumAlertsToday(0);
			for (var i = 0; i < 24; i++) {
				var h = new Date(tn);
				h.setHours(tn.getHours() - i);
				var bucket = (h.getHours() < 10 ? '0' + h.getHours() + ":00" : h.getHours() + ":00");
				ret.labels.unshift(bucket);
				if (!(alertsResponse.aggregations.alerts.buckets[bucket])) {
					ret.data.unshift('0');
				} else {
					ret.data.unshift(alertsResponse.aggregations.alerts.buckets[bucket].doc_count);
					if (tn.getDate() == h.getDate()) { setNumAlertsToday(numAlertsToday + alertsResponse.aggregations.alerts.buckets[bucket].doc_count) }
				}
			}
		} else {

			for (var i = 0; i < numDays; i++) {
				var d = new Date(tn);
				d.setDate(tn.getDate() - i);
				var month = monthNames[d.getMonth()];
				const day = (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
				bucket = day + ' ' + month;
				ret.labels.unshift(bucket);

				if (!(alertsResponse.aggregations.alerts.buckets[bucket])) {
					ret.data.unshift('0');
					if (i == 0) { setNumAlertsToday(0); }
				} else {
					ret.data.unshift(alertsResponse.aggregations.alerts.buckets[bucket].doc_count);
					if (i == 0) { setNumAlertsToday(alertsResponse.aggregations.alerts.buckets[bucket].doc_count); }
				}
			}
		}
		setData(getState(ret));
	}

	useEffect(() => {
		var tn = new Date();
		var startTime = new Date(tn);
		var offset = tn.getTimezoneOffset();

		var offsetString = "";
		if (offset < Math.abs(offset)) {
			offsetString = '+';
			offset *= -1;
		} else {
			offsetString = '-';
		}
		var m = offset % 60;
		var h = Math.abs(offset - m) / 60;
		offsetString += (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();

		if (numDays == 1) {
			startTime.setDate(startTime.getDate() - 1);
		}
		else {
			startTime.setDate(startTime.getDate() - numDays - 1);
		}

		sendRequest('POST', '/customers-api/monitoring/histogram', {
			filters: {
				logTypes: ['alert'],
				timeStart: startTime.getTime(),
				timeEnd: tn.getTime(),
			},
			calendar_interval: (numDays == 1 ? '1h' : '1d'),
			time_zone: offsetString,
			format: (numDays == 1 ? 'HH:mm' : 'dd MMM')
		}, processResults)
	}, [numDays])


	return (
		<Col xs={12} md={12} lg={4}>
			<Card>
				<CardBody>
					<div className="card__title">
						<h3 className="bold-text">{t('title.alerts-chart')}</h3>
					</div>
					<div>
						<DropdownButton alignRight id="range-filter" title={filterLabel}>
							<Dropdown.Item onClick={() => { setNumDays(1); setFilterLabel(t('label.last-day')) }}>{t('label.last-day')}</Dropdown.Item>
							<Dropdown.Item onClick={() => { setNumDays(7); setFilterLabel(t('label.last-seven-days')) }}>{t('label.last-seven-days')}</Dropdown.Item>
							<Dropdown.Item onClick={() => { setNumDays(30); setFilterLabel(t('label.last-thirty-days')) }}>{t('label.last-thirty-days')}</Dropdown.Item>
						</DropdownButton>
					</div>
					<Bar data={data} options={options} />
				</CardBody>
			</Card>
		</Col>
	);
};

export default CustomerMonitoringAlertsChart;
