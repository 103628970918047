import React from 'react'

function AdminMonitoringAlertsTable({data}) {
    return (
        <div>
            TODO TABLE
            <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
    )
}

export default AdminMonitoringAlertsTable
